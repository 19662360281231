import React, { useEffect } from "react";
import { AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { useSpring } from "react-spring";
import ReactGa from "react-ga";

import { useMainStore } from "../store";
import PersonalCode from "../default/pages/mainpages/PersonalCode";
import NoCodeForm from "../default/pages/mainpages/NoCodeForm";
import CoinsParallax from "../themes/2023/components/molecules/CoinsParallax";
import EditionPage from "../default/pages/mainpages/EditionPage";
import { renderScreen2023 } from "../themes/2023/components/pages/mainpages/renderscreens";
import SelectLesson from "../default/pages/mainpages/SelectLesson";
import { useHistory } from "react-router-dom";

const MainContainer = () => {
  const { phase, showCoins, edition, typeHost, typeQuiz } = useMainStore();
  const history = useHistory();

  useEffect(() => {
    if (!typeHost || !typeQuiz) history.push("/");
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  const calc = (x, y) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ];

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));
  const renderDefaultScreens = () => {
    switch (phase) {
      case "personalCode":
        return <PersonalCode key="personalCode" />;
      case "noCodeForm":
        return <NoCodeForm key="noCodeForm" />;
      case "selectLesson":
        return <SelectLesson key="selectLesson" />;
      case "selectEdition":
        return <EditionPage key="selectEdition" />;
    }
  };

  const renderThemeScreens = () => {
    switch (edition.title) {
      case "2023":
        return renderScreen2023(phase);
    }
  };

  return (
    <div onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence exitBeforeEnter>
          {renderDefaultScreens()}
          {renderThemeScreens()}
        </AnimatePresence>
      </AnimateSharedLayout>
    </div>
  );
};

export default MainContainer;
