import React, { useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import dashing from "../../../../../static/emojis/dashing.png";
import light from "../../../../../static/emojis/light.png";
import trophy from "../../../../../static/emojis/trophy.png";
import open from "../../../../../static/emojis/open.png";

import { transitionSpring } from "./SelectLesson";
import { useSocketStore } from "../../../../../store/socketStore.js";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import { useGAEventsTracker } from "../../../../../hooks/useGAEventsTracker.js";

const QuestionIntro = ({ socket }) => {
  const {
    setPhase,
    questions,
    round,
    decision,
    roomPin,
    resetPlayerAnswering,
    setAnswerPhase,
    theme,
  } = useSocketStore();
  const { small, height } = useWindowDimensions();
  const GAEventsTracker = useGAEventsTracker("cashquiz-plus");

  useEffect(() => {
    GAEventsTracker(`ronde ${round + 1} begonnen`);
    setAnswerPhase(false);
    socket.emit("next question", roomPin);
    resetPlayerAnswering();
    setTimeout(() => {
      if (!decision) {
        if (!questions[round].video) setPhase("question");
        else setPhase("questionVideo");
      } else {
        setPhase("questionDecision");
      }
    }, 3000);
  }, [
    setPhase,
    questions,
    round,
    decision,
    resetPlayerAnswering,
    roomPin,
    socket,
    setAnswerPhase,
  ]);

  const style = {
    width: "100%",
    height: "100%",
  };

  const renderIcon = () => {
    switch (questions[round].soort) {
      case "Verdiepingsvraag":
        return <img style={style} src={light} />;
      case "Snelle vraag":
        return <img style={style} src={dashing} />;
      case "Beslissingsvraag":
        return <img style={style} src={trophy} />;
      case "Score":
        return <img style={style} src={trophy} />;
      case "Open vraag":
        return <img style={style} src={open} />;
      default:
        break;
    }
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        layoutId={questions[round].video && "bar"}
        style={{
          zIndex: -1,
          height: height,
          width: "100vw",
          position: "absolute",
          backgroundColor: theme.colors.purple,
        }}
        transition={{ duration: 0.8 }}
      />
      <Grid
        style={{
          height: height,
          width: "100vw",
          position: "absolute",
        }}
        component={motion.div}
        justify="center"
        alignItems="center"
        container
      >
        <motion.div
          transition={{ ...transitionSpring }}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          style={{
            position: "absolute",
            width: small ? "120px" : "80px",
            height: small ? "120px" : "80px",
          }}
        >
          {renderIcon()}
        </motion.div>
      </Grid>
      <Grid
        style={{
          height: height,
          flexDirection: small ? "row" : "row",
        }}
        container
      >
        <Grid item xs={12} sm={6}>
          <Grid
            container
            style={{
              height: small ? height / 2 : height,
              backgroundColor: theme.colors.purpleShadow,
            }}
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "stretch",
              }}
            >
              <motion.h2
                style={{
                  color: "#fff",
                  fontSize: "2.4em",
                }}
                transition={{
                  ...transitionSpring,
                  color: { delay: 0 },
                }}
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
              >
                {decision ? "Beslissingsvraag" : `Vraag ${round + 1}`}
              </motion.h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          {questions && (
            <Grid
              container
              style={{
                height: small ? height / 2 : height,
                backgroundColor: theme.colors.purple,
              }}
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <motion.h2
                  layoutId="type"
                  style={{
                    color: "#fff",
                    fontSize: "2.4em",
                  }}
                  transition={{ ...transitionSpring }}
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  {decision ? "Schattingsvraag" : questions[round].soort}
                </motion.h2>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionIntro;
