import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { AnimatePresence, motion } from "framer-motion";

import { numberToLetter } from "../atoms/LetterBox";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";
import { AnswerBox } from "./AnswerModal";
import { AnswerBoxHw } from "./AnswerModalHw";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { useMainStore } from "../../../../store";

const AnswerNormal = ({
  selectedPlayer,
  players,
  questions,
  round,
  setPlayerAnswer,
  setConfirm,
  setSelectedPlayer,
  homework,
  hidetitle,
}) => {
  const { theme } = useMainStore();

  const { small } = useWindowDimensions();
  const setAnswer = (answer) => {
    setPlayerAnswer(answer);

    setTimeout(() => {
      if (players.every((player) => player.answer !== undefined)) {
        setConfirm(true);
      } else {
        players.length !== selectedPlayer + 1
          ? setSelectedPlayer((selectedPlayer += 1))
          : players.findIndex((player) => player.answer === undefined) ===
            players.length - 1
          ? setConfirm(true)
          : setSelectedPlayer(
              players.findIndex((player) => player.answer === undefined)
            );
      }
    }, 500);
  };
  return (
    <>
      <motion.h1
        transition={{ ...transitionSpring, delay: 0.5 }}
        style={{
          color: homework ? theme.colors.white : theme.colors.purple,
          fontSize: homework ? "1rem" : "",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {!hidetitle && (
          <>
            <span style={{ fontFamily: "proxima-nova", fontWeight: 400 }}>
              {homework ? "Jouw antwoord" : "Antwoord"}
            </span>{" "}
            {!homework && (
              <AnimatePresence exitBeforeEnter>
                <motion.span
                  transition={{ ...transitionSpring }}
                  style={{ display: "inline-block" }}
                  key={selectedPlayer}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -20, opacity: 0 }}
                >
                  team {selectedPlayer !== undefined ? selectedPlayer + 1 : 4}:
                </motion.span>
              </AnimatePresence>
            )}
          </>
        )}
      </motion.h1>
      <Grid
        style={{ marginTop: "1em" }}
        container
        spacing={small ? 1 : 3}
        justify="center"
      >
        {JSON.parse(questions[round].answers).map(
          (answer, index) =>
            answer && (
              <Grid item>
                {homework ? (
                  <AnswerBoxHw
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: { delay: index * 0.1 + 1 },
                    }}
                    disable={players[selectedPlayer].answer === index}
                    transition={{ ...transitionSpring }}
                    key={index}
                    selected={players[selectedPlayer].answer === index}
                    onClick={() => setAnswer(index)}
                  >
                    <motion.span>{numberToLetter(index)}</motion.span>
                  </AnswerBoxHw>
                ) : (
                  <AnswerBox
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: { delay: index * 0.1 + 1 },
                    }}
                    disable={players[selectedPlayer].answer === index}
                    transition={{ ...transitionSpring }}
                    key={index}
                    selected={players[selectedPlayer].answer === index}
                    onClick={() => setAnswer(index)}
                  >
                    <motion.span>{numberToLetter(index)}</motion.span>
                  </AnswerBox>
                )}
              </Grid>
            )
        )}
      </Grid>
    </>
  );
};

export default AnswerNormal;
