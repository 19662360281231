import React, { useEffect, useState } from "react";
import { AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { useSpring } from "react-spring";
import ReactGa from "react-ga";

import { useMainStore } from "../store";
import CoinsParallax from "../themes/2023/components/molecules/CoinsParallax";
import ChooseTypeQuiz from "../default/pages/startpages/ChooseTypeQuiz";
import DisabledQuiz from "../default/pages/startpages/disabledQuiz";
import ChooseTypeHost from "../default/pages/startpages/ChooseTypeHost";
import { useHistory, useLocation } from "react-router-dom";
import { useSocketStore } from "../store/socketStore";

const StartContainer = () => {
  const {
    phase,
    showCoins,
    setPhase,
    setTypeHost,
    setTypeQuiz,
    setLesson,
    setCode,
    setTestSession,
  } = useMainStore();
  const { setPhase: setSocketPhase } = useSocketStore();
  const socketStore = useSocketStore();
  const { search } = useLocation();
  const history = useHistory();

  const getTestLesson = () => {
    fetch(`${process.env.REACT_APP_SERVER}lectures/mbooefensessie`)
      .then((res) => res.json())
      .then(
        (result) => {
          setCode("863c19aa");
          socketStore.setCode("863c19aa");
          setLesson(result[0].lecture[0]);
          socketStore.setLesson(result[0].lecture[0]);
          setPhase("lobby");
          setSocketPhase("lobby");
          setTypeHost("gastdocent");
          setTypeQuiz("cashquiz-plus");
          setTestSession(true);
          history.push("/cashquiz-plus");
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    if (search === "?oefensessie") {
      getTestLesson();
    }
    // } else {
    // setPhase("chooseTypeQuiz");
    ReactGa.pageview(window.location.pathname + window.location.search);
    // }
  }, [search]);

  useEffect(() => {
    if (search.includes("code")) {
      const after = search.substring(search.indexOf("=") + 1);
      setCode(after);
      socketStore.setCode(after);
    }
  }, [search]);

  const calc = (x, y) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ];

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  const renderDefaultScreens = () => {
    switch (phase) {
      case "disabledQuiz":
        return <DisabledQuiz key="disabledQuiz" />;
      case "chooseTypeQuiz":
        return <ChooseTypeQuiz key="chooseTypeQuiz" />;
      case "chooseTypeHost":
        return <ChooseTypeHost key="chooseTypeHost" />;
    }
  };
  return (
    <div onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence exitBeforeEnter>
          {renderDefaultScreens()}
        </AnimatePresence>
      </AnimateSharedLayout>
    </div>
  );
};

export default StartContainer;
