import styled, { css } from "styled-components";
import { animated } from "react-spring";
import { motion } from "framer-motion";

import { useMainStore } from "../../../../store/index.js";

import dashing from "../../../../static/emojis/dashing.png";
import light from "../../../../static/emojis/light.png";
import trophy from "../../../../static/emojis/trophy.png";
import open from "../../../../static/emojis/open.png";

export const QuestionIconStyled = styled(motion.div)`
  width: 65px;
  height: 65px;
  z-index: 5;
  position: relative;
`;

export const Icon = styled(animated.div)`
  height: 100%;
  width: 100%;

  img {
    filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.6));
    height: 100%;
    width: 100%;
  }

  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

export const QuestionIcon = ({ initial, questionSoort }) => {
  const { theme } = useMainStore();

  const renderIcon = () => {
    switch (questionSoort) {
      case "Verdiepingsvraag":
        return <img src={light} />;
      case "Snelle vraag":
        return <img src={dashing} />;
      case "Beslissingsvraag":
        return <img src={trophy} />;
      case "Score":
        return <img src={trophy} />;
      case "Open vraag":
        return <img src={open} />;
      default:
        break;
    }
  };
  return (
    <QuestionIconStyled>
      <motion.div
        style={{
          position: "relative",
          padding: "20px 10px 10px",
          paddingTop: "20px",
          height: "45px",
          width: "45px",
          borderTop: 0,
          background: theme.colors.purple,
          borderRadius: "0rem 0rem 0.4rem 0.4rem",
        }}
      >
        <Icon width="100%">{renderIcon()}</Icon>
      </motion.div>
    </QuestionIconStyled>
  );
};
