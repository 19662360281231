import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";

import CashQuizLogo from "../../atoms/CashQuizLogo";
import { SquareTile } from "../../atoms/SquareTile";
import { backgrounds } from "../../../../../data/backgrounds";
import { Background } from "../../atoms/Backgrounds";
import { AnimatePresence, motion } from "framer-motion";
import { TextLink } from "../../atoms/TextLink";
import { transitionSpring } from "../../../../../default/pages/mainpages/SelectLesson";
import { useSocketStore } from "../../../../../store/socketStore";
import { ExplanationButton } from "../../atoms/Buttons";
import LevelExplanation from "../../molecules/LevelExplanation";
import {useMainStore} from "../../../../../store";

const SelectLevel = ({ socket }) => {
  const { setPhase, setLevel, personalCode, lesson, gameid, theme } =
    useSocketStore();
  const { typeHost, typeQuiz } = useMainStore();
  const [levels, setLevels] = useState([1, 2, 3, 4]);
  const [showLevelExplanation, setShowLevelExplanation] = useState(false);

  const getColor = (color) => {
    switch (color) {
      case "Geel":
        return theme.colors.yellow;
      case "Groen":
        return "#0A8F01";
      case "Blauw":
        return theme.colors.blue;
      case "Rood":
        return theme.colors.red;
      default:
        return "#fff";
    }
  };

  const createSocketGame = (level) => {
    setLevel(level);
    if (personalCode) {
      //game is not set yet
      socket.emit("create room", { level, code: personalCode, lesson, host: typeHost, quiztype: typeQuiz });
    } else {
      socket.emit("update room", { level, gameid });
      setPhase("lobby");
    }
  };

  useEffect(() => {
    fetch(
      process.env.REACT_APP_SERVER +
        "levels/af091a0655214d6de1188850b6ff4512/24"
    )
      .then((res) => res.json())
      .then(
        (result) => setLevels(result),
        (error) => console.log("Er is iets misgegaan.")
      );
  }, []);

  return (
    <>
      <CashQuizLogo />
      <Grid
        onClick={() => showLevelExplanation && setShowLevelExplanation(false)}
        style={{ height: "100vh", textAlign: "center" }}
        container
        component={motion.div}
        animate={{ filter: showLevelExplanation ? "blur(5px)" : "blur(0px)" }}
        alignItems="center"
        transition={{ duration: 0.5 }}
        justify="center"
      >
        <Grid item xs={8} lg={6}>
          <Grid item xs={12}>
            <motion.h1
              transition={{
                opacity: { delay: 0.2 },
                ...transitionSpring,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{ fontSize: "36px", marginBottom: "1em" }}
            >
              Kies een niveau
            </motion.h1>
          </Grid>
          <Grid key="levels" item xs={12}>
            <Grid container justify="center" spacing={4}>
              {levels.map((level, index) => (
                <Grid key={index} item xs={3}>
                  <SquareTile
                    index={index}
                    backgroundColor={getColor(level.title)}
                    color={theme.colors.white}
                    exit={{ opacity: 0 }}
                    onClick={() => {
                      createSocketGame(level);
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "36px",
                      }}
                    >
                      {level?.title}
                    </div>
                  </SquareTile>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          display: "flex",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextLink
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          whileHover={{
            scale: 1.1,
            cursor: "pointer",
          }}
          transition={{
            scale: { delay: 0 },
            default: 0.5,
          }}
          onClick={() => setPhase(personalCode ? "selectLesson" : "noCodeForm")}
          navigationPrev
          bottom={"50px"}
          left={"50px"}
          style={{
            position: "absolute",
          }}
        >
          terug
        </TextLink>
        <ExplanationButton
          onClick={() => setShowLevelExplanation(!showLevelExplanation)}
          style={{ bottom: 50 }}
        >
          Toelichting
        </ExplanationButton>
      </div>
      <Background initialBig background={"cloudBackgroundBig"} />
      <AnimatePresence>
        {showLevelExplanation && <LevelExplanation />}
      </AnimatePresence>
    </>
  );
};

export default SelectLevel;
