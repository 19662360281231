import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import { useMainStore } from "../../../store";
import { SquareTile } from "../../../themes/2023/components/atoms/SquareTile";
import { Background } from "../../../themes/2023/components/atoms/Backgrounds";
import { useGAEventsTracker } from "../../../hooks/useGAEventsTracker";
import { usePlayerStore } from "../../../store/playerStore";
import { useSocketStore } from "../../../store/socketStore";
import { transitionSpring } from "./SelectLesson";
import { themes } from "../../../data/themes";

const EditionPage = () => {
  const { setPhase, setEdition, theme, setTheme } = useMainStore();
  const storePlayer = usePlayerStore();
  const storeSocket = useSocketStore();

  const [editions, setEditions] = useState([
    { title: "2017" },
    { title: "2018" },
    { title: "2019" },
    { title: "2021" },
  ]);
  const GAEventsTracker = useGAEventsTracker("cashquiz");

  useEffect(() => {
    fetch(process.env.REACT_APP_SERVER + "editions")
      .then((res) => res.json())
      .then(
        (result) => setEditions(result),
        (error) => console.log("Er is iets misgegaan.")
      );
  }, []);

  return (
    <>
      <Grid
        style={{ height: "100vh", textAlign: "center" }}
        container
        component={motion.div}
        alignItems="center"
        transition={{ duration: 0.5 }}
        justify="center"
      >
        <Grid item xs={8} lg={6}>
          <Grid item xs={12}>
            <motion.h1
              transition={{
                opacity: { delay: 0.2 },
                ...transitionSpring,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{ fontSize: "36px", marginBottom: "1em" }}
            >
              Kies een Editie
            </motion.h1>
          </Grid>
          {editions.length !== 0 && (
            <Grid key="editions" item xs={12}>
              <Grid container justify="center" spacing={4}>
                {editions.map((edition, index) => (
                  <Grid key={index} item xs={3}>
                    <SquareTile
                      index={index}
                      backgroundColor="hotpink"
                      color={theme.colors.white}
                      exit={{ opacity: 0 }}
                      onClick={() => {
                        GAEventsTracker("editie geselecteerd");
                        setTheme(themes[edition.title]);
                        storePlayer.setTheme(themes[edition.title]);
                        storeSocket.setTheme(themes[edition.title]);
                        setEdition(edition);
                        setPhase("selectLevel");
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "36px",
                        }}
                      >
                        {edition?.title}
                      </div>
                    </SquareTile>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Background initialBig background={"cloudBackgroundBig"} />
    </>
  );
};

export default EditionPage;
