import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import CashQuizLogo from "../../../themes/2023/components/atoms/CashQuizLogo";
import { Background } from "../../../themes/2023/components/atoms/Backgrounds";
import { useMainStore } from "../../../store";
import { useGAEventsTracker } from "../../../hooks/useGAEventsTracker";
import { Button } from "../../../themes/2023/components/atoms/Buttons";
import { useHistory } from "react-router-dom";
import { useSocketStore } from "../../../store/socketStore";
import logoText1 from "../../../static/2023/logotext3.png";
import logoText2 from "../../../static/2023/logotext4.png";
import BvdkFooter from "../../../themes/2023/components/molecules/BvdkFooter";
import { TextLink } from "../../../themes/2023/components/atoms/TextLink";

const ChooseTypeHost = () => {
  const { typeQuiz, setTypeHost, setPhase } = useMainStore();
  const socketStore = useSocketStore();
  const GAEventsTracker = useGAEventsTracker("cashquiz");
  const history = useHistory();
  return (
    <>
      <CashQuizLogo />
      <BvdkFooter noTestlesson />
      <motion.div exit={{ opacity: 0 }}>
        <Grid
          style={{
            height: "100vh",
            textAlign: "center",
            gap: 32,
          }}
          container
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{ transform: "translateY(-120px)" }}
          >
            <motion.img
              exit={{ opacity: 0, transition: { delay: 0 } }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              style={{ x: -60, y: 15 }}
              width={450}
              src={logoText1}
            />
            <motion.img
              exit={{ opacity: 0, transition: { delay: 0 } }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              style={{ y: -15, x: 110 }}
              width={500}
              src={logoText2}
            />
          </Grid>
          <Grid
            exit={{ opacity: 0, transition: { delay: 0 } }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            component={motion.div}
            style={{ gap: 32, marginTop: -80 }}
            alignItems="center"
            justify="center"
            container
            direction="row"
          >
            <Button
              style={{
                fontFamily: "Brevia",
                textAlign: "center",
                width: 280,
                fontWeight: 500,
                fontSize: "1.3rem",
                padding: "10px 10px",
              }}
              onClick={() => {
                setTypeHost("gastdocent");
                typeQuiz === "cashquiz"
                  ? setPhase("personalCode")
                  : socketStore.setPhase("personalCode");
                history.push(
                  typeQuiz === "cashquiz" ? "/cashquiz" : "/cashquiz-plus"
                );
              }}
              primary
            >
              Ik ben een <br />
              <span style={{ fontSize: "2.3rem", fontWeight: 800 }}>
                Gastdocent
              </span>
            </Button>
            <Button
              style={{
                fontFamily: "Brevia",
                textAlign: "center",
                width: 280,
                fontWeight: 500,
                fontSize: "1.3rem",
                padding: "10px 10px",
              }}
              onClick={() => {
                setTypeHost("leerkracht");
                typeQuiz === "cashquiz"
                  ? setPhase("personalCode")
                  : socketStore.setPhase("personalCode");
                history.push(
                  typeQuiz === "cashquiz" ? "/cashquiz" : "/cashquiz-plus"
                );
              }}
              primary
            >
              Ik ben een <br />{" "}
              <span style={{ fontSize: "2.3rem", fontWeight: 800 }}>
                Leerkracht
              </span>
            </Button>
          </Grid>
        </Grid>
      </motion.div>
      <TextLink
        navigationPrevPurple
        whileHover={{
          borderBottom: "1px solid #fff",
          cursor: "pointer",
          opacity: 1,
        }}
        onClick={() => setPhase("chooseTypeQuiz")}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        bottom={"35px"}
        left={"140px"}
        style={{
          position: "absolute",
        }}
      >
        terug
      </TextLink>
      <Background background={"cloudBackground"} />
    </>
  );
};

export default ChooseTypeHost;
