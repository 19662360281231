import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import read from "../../../../static/media/read.png";
import send from "../../../../static/media/send.png";
import { useMainStore } from "../../../../store";
import { transitionSpring } from "../pages/hostpages/SelectLesson";

const TeamReplyStyled = styled(motion.div)`
  width: fit-content;
  padding: 12px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 0px #f4f4f4;
  border-radius: 8px;
  left: 0px;
  position: relative;
  align-self: flex-end;
  font-weight: 600;
  max-width: 70%;
  margin-bottom: 12px;
  text-align: end;
  line-height: 1.2;
  font-size: 1.1rem;

  ${(props) =>
    props.$odd &&
    `
    text-align: start;
    align-self: flex-start;
    background: #DCF7C5;
    color:#2d4b94;
    `};

  svg {
    transform: rotateY(-180deg);

    position: absolute;
    bottom: 0px;
    right: -20px;
    width: 40px;
    height: 40px;
    z-index: 1;
    //flip y odd
    ${(props) =>
      props.$odd &&
      `
      transform: rotateY(0deg);
      right: auto;
      left: -20px;
      `};
  }

  .time {
    position: absolute;
    bottom: 0px;
    right: 12px;
    font-weight: 400;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.25);
  }
`;
const Box = styled(motion.div)`
  width: 100%;
  min-width: 320px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const Player = styled(motion.div)`
  padding: 0.5em 1em;
  background: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid #b2a1c7;
  color: #c4c4c4;
  font-size: 1em;
  font-weight: 900;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    right: 0px;
    width: 18px;
  }
`;

const PlayerBox = ({ teams }) => {
  return (
    <Box layout>
      <AnimatePresence>
        {teams.map((player) => {
          return (
            <TeamReply
              key={player.group}
              team={player}
              odd={player.group % 2}
            />
          );
        })}
      </AnimatePresence>
    </Box>
  );
};

const TeamReply = ({ team, odd }) => {
  //hh:mm
  const currentTime = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  return (
    <TeamReplyStyled
      layout
      initial={{ opacity: 0, x: odd ? 20 : -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: odd ? 20 : -20 }}
      transition={{ ...transitionSpring, delay: 0.1 }}
      $odd={odd}
    >
      {team.sentence}
      {/* <span className="time">{currentTime}</span> */}
      {odd ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="37"
          fill="none"
        >
          <g filter="url(#a)">
            <path
              fill="#DCF7C5"
              fill-rule="evenodd"
              d="M20.6 0c0 11.33-5.77 19.24-17.31 23.76l-.78.3A2.323 2.323 0 0 0 2 28.15c4.08 2.79 9.51 4.41 18.59 4.85V0h.01Z"
              clip-rule="evenodd"
            />
          </g>
          <defs>
            <filter
              id="a"
              width="19.604"
              height="35"
              x=".996"
              y="0"
              color-interpolation-filters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix values="0 0 0 0 0.956863 0 0 0 0 0.956863 0 0 0 0 0.956863 0 0 0 1 0" />
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_67_221"
              />
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_67_221"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="35"
          fill="none"
        >
          <g filter="url(#a)">
            <path
              fill="#fff"
              fill-rule="evenodd"
              d="M20.6 0c0 11.33-5.77 19.24-17.31 23.76l-.78.3A2.323 2.323 0 0 0 2 28.15c4.08 2.79 9.51 4.41 18.59 4.85V0h.01Z"
              clip-rule="evenodd"
            />
          </g>
          <defs>
            <filter
              id="a"
              width="19.604"
              height="35"
              x=".996"
              y="0"
              color-interpolation-filters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix values="0 0 0 0 0.956863 0 0 0 0 0.956863 0 0 0 0 0.956863 0 0 0 1 0" />
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_67_219"
              />
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_67_219"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )}
    </TeamReplyStyled>
  );
};

export default PlayerBox;
