import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

import CashQuizLogo from "../../../themes/2023/components/atoms/CashQuizLogo";
import BvdkFooter from "../../../themes/2023/components/molecules/BvdkFooter";
import { TextLink } from "../../../themes/2023/components/atoms/TextLink";
import { Background } from "../../../themes/2023/components/atoms/Backgrounds";
import { Button } from "../../../themes/2023/components/atoms/Buttons";
import { useMainStore } from "../../../store";
import { Input } from "../../../themes/2023/components/atoms/Input";
import { backgrounds } from "../../../data/backgrounds";
import Modal from "../../../themes/2023/components/atoms/Modal";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useGAEventsTracker } from "../../../hooks/useGAEventsTracker";
import AskCodeModal from "../../components/AskCodeModal";
import { CodeInput } from "../../components/CodeInput";
import { useHistory } from "react-router-dom";

const PersonalCode = () => {
  const { theme, setCode, setLessons, setPhase, typeHost, personalCode } =
    useMainStore();
  const GAEventsTracker = useGAEventsTracker("cashquiz");
  const [askCode, setAskCode] = useState(false);
  const history = useHistory();
  const { xs } = useWindowDimensions();
  const { register, handleSubmit, watch } = useForm({
    defaultValues: { code: personalCode },
  });

  const onSubmit = () => {
    GAEventsTracker("code invoeren");
    if (!watch("code")) {
      toast("Geen geldige code ingevuld.");
    } else {
      // c4262fe5
      let personalCode = watch("code");
      // personalCode = "9816b378";
      setCode(personalCode);

      fetch(
        `${process.env.REACT_APP_SERVER}lectures/${personalCode}/${typeHost}/mbo`
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.msg === "invalid") {
              toast("Geen geldige code ingevuld.");
            } else {
              if (result.length === 0) {
                toast("Alle lessen voor deze code zijn al gespeeld.");
              } else {
                setLessons(result);
                setPhase("selectLesson");
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  return (
    <>
      <motion.div exit={{ opacity: 0 }}>
        <Grid
          style={{
            height: "100vh",
            transform: "translateY(-20px)",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
          component={motion.div}
          transition={{ delay: 0.5 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <Grid item xs={10} lg={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                spacing={3}
              >
                <Grid item xs={7}>
                  <motion.h1
                    transition={{ delay: 0.2 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    style={{
                      margin: 0,
                      fontFamily: "Brevia",
                      fontSize: "1.8rem",
                    }}
                  >
                    Je gaat spelen als {typeHost}
                  </motion.h1>
                </Grid>
                <Grid item xs={7}>
                  <motion.h1
                    transition={{ delay: 0.2 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    style={{
                      margin: 0,
                      fontFamily: "Brevia",
                      fontSize: "1.8rem",
                    }}
                  >
                    Vul jouw Cash Quiz startcode in
                  </motion.h1>
                </Grid>
                <Grid item xs={10} md={7}>
                  <Input
                    style={{
                      height: 80,
                      fontWeight: 500,
                      fontFamily: "Brevia",
                      fontSize: "4rem",
                      borderRadius: "0.8rem",
                      boxShadow: `0px 4px ${theme.colors.background}`,
                    }}
                    transition={{ delay: 0.5 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    name="code"
                    placeholder="AA123456"
                    ref={register}
                  />
                </Grid>
                <Grid item xs={10} md={7}>
                  <Button
                    style={{
                      height: 87,
                      width: "100%",
                      fontFamily: "Brevia",
                      fontWeight: 800,
                      fontSize: "2.5rem",
                    }}
                    whileHover={{
                      scale: 1.1,
                      delay: 0,
                    }}
                    transition={{
                      scale: { delay: 0 },
                      default: { delay: 0.5 },
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    primary
                    type="submit"
                  >
                    START DE QUIZ!
                  </Button>
                </Grid>
                <Grid item xs={7}>
                  <h3
                    style={{
                      color: "#fff",
                      margin: 0,
                      fontFamily: "Brevia",
                      fontSize: "1.8rem",
                    }}
                  >
                    Geen startcode?
                  </h3>
                </Grid>

                <Grid item xs={7}>
                  <TextLink
                    onClick={() => setAskCode(true)}
                    target={"_blank"}
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      color: "#fff",

                      fontFamily: "Brevia",
                      borderBottom: "none",

                      fontSize: "1.2rem",
                    }}
                    transition={{
                      color: { delay: 0 },
                      opacity: { delay: 0.5 },
                    }}
                    exit={{ transition: { delay: 0 } }}
                    whileHover={{
                      transition: { delay: 0 },
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                        textUnderlineOffset: 5,
                      }}
                    >
                      Ontvang code{" "}
                    </span>
                    <span
                      style={{
                        fontWeight: 700,
                        textDecoration: "underline",
                        textUnderlineOffset: 5,
                      }}
                    >
                      direct in je e-mail
                    </span>
                  </TextLink>
                </Grid>
                <Grid item xs={7}>
                  <TextLink
                    onClick={() => setPhase("noCodeForm")}
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      color: "#fff",
                      borderBottom: "none",
                      fontFamily: "Brevia",
                      fontSize: "1.2rem",
                    }}
                    transition={{
                      color: { delay: 0 },
                      opacity: { delay: 0.5 },
                    }}
                    exit={{ transition: { delay: 0 } }}
                    whileHover={{
                      transition: { delay: 0 },
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                        textUnderlineOffset: 5,
                      }}
                    >
                      {" "}
                      Speel zonder code
                    </span>{" "}
                    <span style={{ opacity: 0.7, textDecoration: "none" }}>
                      (let op: winnaar doet niet mee aan landelijke prijs)
                    </span>
                  </TextLink>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <BvdkFooter noTestlesson />
      </motion.div>
      <TextLink
        navigationPrevPurple
        whileHover={{
          borderBottom: "1px solid #fff",
          cursor: "pointer",
          opacity: 1,
        }}
        onClick={() => {
          setPhase("chooseTypeHost");
          history.push("/");
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        bottom={"35px"}
        left={"140px"}
        style={{
          position: "absolute",
        }}
      >
        terug
      </TextLink>
      {askCode && <AskCodeModal setAskCode={setAskCode} askCode={askCode} />}
      <Background background={"cloudBackground"} />
    </>
  );
};

export default PersonalCode;
