import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const HeaderTwo = styled(motion.p)`
  font-size: ${(props) => props.fontSize || "auto"};

  color: ${(props) => props.color || props.theme.colors.purple};
  margin: ${(props) => props.margin || "0em 0 0.67em 0"};

  ${(props) =>
    props.center &&
    css`
      text-align: center;
      display: table-cell;
      vertical-align: middle;
    `}

  ${(props) =>
    props.primary &&
    css`
      color: ${props.theme.colors.primary};
    `}

  ${(props) =>
    props.secondary &&
    css`
      color: ${props.theme.colors.secondary};
    `}

    ${(props) =>
    props.light &&
    css`
      color: ${props.theme.colors.light};
    `}
`;
