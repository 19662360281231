import { motion } from "framer-motion";
import React from "react";
import { useMainStore } from "../../store";

const IconBeslissingsvraag = ({ color, initial }) => {
  const { theme } = useMainStore();

  return (
    <motion.svg
      initial={initial && { fill: theme.colors.purple }}
      animate={initial && { fill: color }}
      style={!initial && { fill: color }}
      id="bold"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1080 1080"
      enable-background="new 0 0 1080 1080"
    >
      <path
        class="st0"
        d="M966.5,0h-853C50.8,0,0,50.8,0,113.5v852.9c0,62.7,50.8,113.5,113.5,113.5h852.9
		c62.7,0,113.5-50.8,113.5-113.5V113.5C1080,50.8,1029.2,0,966.5,0z M178.3,749.1c0-7.5,6.4-13.9,13.9-13.9h330.9
		c7.5,0,13.9,6.4,13.9,13.9l-1.1,28.8H178.3V749.1z M594.6,909.3c0,7.5-6.4,13.9-13.9,13.9H135.6c-7.5,0-13.9-6.4-13.9-13.9V807.9
		c0-6.4,4.3-12.8,10.7-13.9h451.5c6.4,2.1,10.7,7.5,10.7,13.9V909.3z M976.7,736.3c-11.7,12.8-32,12.8-44.8,0
		c-13.9,9.6-31,14.9-48,14.9s-34.2-5.3-49.1-16c-6.4-6.4-12.8-12.8-19.2-19.2C760,659.5,701.3,607.2,638.4,559.1
		c-17.1,9.6-38.4,9.6-54.4-2.1c-17.1-12.8-24.6-34.2-19.2-54.4c-18.1-13.9-36.3-26.7-55.5-40.6L491,488.7
		c20.3,21.3,22.4,54.4,5.3,77.9c-19.2,27.8-58.7,34.2-86.5,14.9l-159-112.1c-17.1-11.7-26.7-31-26.7-51.2c0-35.2,27.8-63,61.9-63
		c8.5,1.1,16,2.1,23.5,5.3l97.1-139.8c-19.2-21.3-21.3-53.4-4.3-77.9c20.3-27.8,58.7-34.2,86.5-14.9L648,240
		c17.1,11.7,26.7,31,26.7,51.2c0,35.2-27.8,63-61.9,63c-8.5-1.1-16-2.1-23.5-5.3l-18.1,26.7c19.2,12.8,38.4,25.6,56.6,38.4
		c10.7-8.5,24.6-11.7,38.4-8.5c26.7,5.3,43.8,32,38.4,58.7c66.2,42.7,135.6,81.1,207.1,114.2c8.5,3.2,17.1,7.5,24.6,11.7
		c28.8,20.3,42.7,56.6,34.2,91.8c3.2,2.1,6.4,4.3,6.4,9.6C989.5,703.2,989.5,723.5,976.7,736.3z"
      />
      <path
        class="st0"
        d="M668.2,435.3c-3.2-3.2-8.5-4.3-12.8-4.3c-7.5,0-14.9,3.2-19.2,9.6l-41.6,59.8c-7.5,10.7-5.3,24.6,5.3,32
		s24.6,5.3,32-5.3l41.6-59.8C681,457.7,677.8,443.9,668.2,435.3z"
      />
    </motion.svg>
  );
};

export default IconBeslissingsvraag;
