import create from "zustand";
import { themes } from "../data/themes";
import { testQuestions } from "./testQuestions";

export const useMainStore = create((set) => ({
  theme: themes["2023"],
  phase: "chooseTypeQuiz",
  lessons: [],
  personalCode: undefined,
  lesson: undefined,
  testSession: false,
  level: {
    access: 1,
    alias: "geel",
    asset_id: 57,
    checked_out: 0,
    checked_out_time: "0000-00-00 00:00:00",
    created_time: "2016-08-22T08:05:40.000Z",
    created_user_id: 686,
    extension: "com_quiz",
    hits: 0,
    id: 9,
    language: "*",
    level: 1,
    lft: 11,
    parent_id: 1,
    path: "geel",
    published: 1,
    rgt: 16,
    title: "Geel",
    version: 1,
    params:
      '{"category_layout":"","image":"","image_alt":"","videourl":"","videojpg":"","outrovideourl":"cuJNlyN-sP8","outrovideojpg":"","groups":"8","points":"10","final_question":"Hoeveel procent van de middelbare-schoolleerlingen koopt weleens iets via internet?","final_answer":"78","final_answer_unit":"%"}',
  },
  teams: [1],
  gameid: undefined,
  round: 0,
  image: undefined,
  questions: testQuestions,
  teamsAnswers: undefined,
  showCoins: true,
  showMoney: true,
  decision: undefined,
  scores: [],
  duplicates: [],
  edition: { title: "2023" },
  typeQuiz: undefined,
  typeHost: undefined,
  typeSchool: "mbo",
  setTheme: (data) => set({ theme: data }),
  setTypeQuiz: (data) => set({ typeQuiz: data }),
  setTypeHost: (data) => set({ typeHost: data }),
  setTestSession: (data) => set({ testSession: data }),
  setDuplicates: (data) => set({ duplicates: data }),
  setQuestions: (data) => set({ questions: data }),
  setShowCoins: (data) => set({ showCoins: data }),
  setShowMoney: (data) => set({ showMoney: data }),
  setDecision: (data) => set({ decision: data }),
  setPhase: (data) => set({ phase: data }),
  setGameID: (data, homework) => {
    set({ gameid: data });
    if (homework) {
      localStorage.setItem("gameidhw", data);
    } else {
      localStorage.setItem("gameid", data);
    }
  },
  setRound: (data) => set({ round: data }),
  setLessons: (data) => set({ lessons: data }),
  setCode: (data) => set({ personalCode: data }),
  setLesson: (data) => set({ lesson: data }),
  setLevel: (data) => set({ level: data }),
  setEdition: (data) => set({ edition: data }),
  setTeams: (data) => set({ teams: data }),
  setTeamsAnswers: (data) => set({ teamsAnswers: data }),
  updateScores: (data) => set({ scores: data }),
  setWinner: (data) => {
    fetch(process.env.REACT_APP_SERVER + "questions/set-winner/mbo", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((data) => console.log(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  setHwData: (data) => {
    fetch(process.env.REACT_APP_SERVER + "questions/set-data-hw", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((data) => console.log(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  },
}));
