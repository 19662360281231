import React, { useState } from "react";
import { useSocketStore } from "../../../../../store/socketStore";
import EstimationInput from "./EstimationInput";

const AnswerEstimation = ({
  question,
  setChosenAnswer,
  chosenAnswer,
  confetti,
  disable,
  small,
}) => {
  const [input, setInput] = useState();
  const { decision, level } = useSocketStore();
  return (
    <div>
      <EstimationInput
        disable={disable}
        confetti={confetti}
        setChosenAnswer={setChosenAnswer}
        prepend={question && question.unit_prepend}
        unit={
          !decision ? question.unit : JSON.parse(level.params).final_answer_unit
        }
        input={input}
        setInput={setInput}
        answer={chosenAnswer}
        small={small}
      />
    </div>
  );
};

export default AnswerEstimation;
