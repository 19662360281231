import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion, useAnimation } from "framer-motion";

import cards from "../../../../../static/emojis/bank.png";
import money from "../../../../../static/emojis/banknote.png";
import { Button } from "../../atoms/Buttons";
import { transitionSpring } from "./SelectLesson";
import Topbar from "../../molecules/Topbar";
import { useSocketStore } from "../../../../../store/socketStore";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import ThesisAnswerBox from "../../molecules/ThesisAnswerBox";
import TextReply from "../../molecules/TextReply";

const backgroundVariants = {
  peak: {
    x: -100,
    transition: { duration: 1, type: "spring" },
  },
  small: {
    transition: { duration: 1, type: "spring" },
  },
  exit: {
    x: "-100%",
    transition: { ...transitionSpring, delay: 0.5 },
  },
};

const ThesisAnswersPage = ({ socket }) => {
  const {
    setPhase,
    setRound,
    round,
    theme,
    roomPin,
    answerPhase,
    thesisAnswers,
    level,
    questions,
  } = useSocketStore();
  const CloudBackgroundDescriptionAnimation = useAnimation();
  const { height } = useWindowDimensions();

  return (
    <>
      <motion.div
        style={{
          zIndex: 0,
          position: "absolute",
          width: "100vw",
          height: height,
          backgroundColor: theme.colors.background,
        }}
        animate={CloudBackgroundDescriptionAnimation}
        variants={backgroundVariants}
        exit="exit"
      />
      <Topbar
        roomPin={roomPin}
        question={round + 1}
        noVideo={!questions[round].video}
        questionValue={questions[round].points}
        questionSoort={questions[round].soort}
      />
      <Grid
        container
        style={{ height: "calc(100vh - 65px)" }}
        alignItems="center"
        justify="center"
      >
        <Grid
          item
          xs={11}
          lg={8}
          style={{ position: answerPhase ? "relative" : "inherit" }}
        >
          <TextReply>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  "<span class='stelling'>Stelling:</span> <br />" +
                  questions[round].stelling,
              }}
            />
          </TextReply>
          <Grid container alignItems="center" justify="center">
            {JSON.parse(questions[round].stelling_answers).map(
              (answer, index) => (
                <Grid style={{ position: "relative" }} item xs={3}>
                  <ThesisAnswerBox
                    left={index === 0}
                    answer={answer}
                    index={index}
                    isHighest={
                      // how many answers are given
                      thesisAnswers.filter(
                        (answerGiven) => answerGiven.answer === answer
                      ).length ===
                      // highest number of answers given
                      Math.max(
                        ...JSON.parse(questions[round].stelling_answers).map(
                          (answer) =>
                            thesisAnswers.filter(
                              (answerGiven) => answerGiven.answer === answer
                            ).length
                        )
                      )
                    }
                    number={
                      thesisAnswers.filter(
                        (answerGiven) => answerGiven.answer === answer
                      ).length
                    }
                  />
                  <motion.img
                    initial={{ opacity: 0, scale: 0.4 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{
                      opacity: 0,
                      transition: { ...transitionSpring, delay: 0 },
                    }}
                    transition={{
                      ...transitionSpring,
                      delay: 0.4 + index * 0.1,
                    }}
                    style={{
                      rotate: index === 0 ? -10 : 5,
                      position: "absolute",
                      bottom: -30,
                      left: index === 0 ? -20 : "auto",
                      right: index === 1 ? -30 : "auto",
                    }}
                    width={60}
                    src={index === 0 ? cards : money}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        style={{
          position: "absolute",
          bottom: "30px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
        container
        direction="row"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <motion.div
            transition={{ transitionSpring, delay: 5 }}
            initial={{ y: 300 }}
            animate={{ y: 0 }}
            exit={{ y: 300, transition: { ...transitionSpring, delay: 0 } }}
          >
            <Button
              onAnimationComplete={(definition) => {
                if (definition?.opacity === 0 && round !== 11)
                  setRound(round + 1);
              }}
              whileHover={{
                scale: 1.1,
                delay: 0,
              }}
              primary
              onClick={() => {
                if (round === 11) {
                  if (!!JSON.parse(level.params).outrovideourl)
                    setPhase("outro");
                  else setPhase("end");
                } else {
                  setPhase("quizStart");
                }
              }}
              exit={{ opacity: 0 }}
            >
              Volgende vraag
            </Button>
          </motion.div>
        </Grid>
      </Grid>
    </>
  );
};

export default ThesisAnswersPage;
