import Question from "./Question";
import QuestionDecision from "./QuestionDecision";
import QuestionIntro from "./QuestionIntro";
import RevealAnswer from "./RevealAnswer";
import SelectLesson from "./SelectLesson";
import SelectLevel from "./SelectLevel";
import QuestionDecisionExplanation from "./QuestionDecisionExplanation";
import Scores from "./Scores";
import Explanation from "./Explanation";
import QuestionVideo from "./QuestionVideo";
import EndPage from "./EndPage";
import Outro from "./Outro";
import QuestionDecisionReveal from "./QuestionDecisionReveal";
import Tips from "./Tips";
import FinalPage from "./FinalPage";
import ThesisPage from "./ThesisPage";
import Lobby from "./Lobby";
import ThesisAnswersPage from "./ThesisAnswersPage";

export const renderScreen2023 = (phase, socket) => {
  switch (phase) {
    case "selectLesson":
      return <SelectLesson key="selectlesson" socket={socket} />;
    case "selectLevel":
      return <SelectLevel key="selectlevel" socket={socket} />;
    case "lobby":
      return <Lobby key="lobby" socket={socket} />;
    case "tips":
      return <Tips key="tips" socket={socket} />;
    case "explanation":
      return <Explanation key="explanation" socket={socket} />;
    case "scoresBegin":
      return <Scores key="scoresbegin" begin socket={socket} />;
    case "quizStart":
      return <QuestionIntro key="intro" socket={socket} />;
    case "questionVideo":
      return <QuestionVideo key="video" socket={socket} />;
    case "question":
      return <Question key="question" socket={socket} />;
    case "revealAnswer":
      return <RevealAnswer key="revealAnswer" socket={socket} />;
    case "scores":
      return <Scores key="scores" socket={socket} />;
    case "end":
      return <EndPage key="end" socket={socket} />;
    case "outro":
      return <Outro key="outro" socket={socket} />;
    case "final":
      return <FinalPage key="final" socket={socket} />;
    case "questionDecision":
      return <QuestionDecision key="questionDecision" socket={socket} />;
    case "questionDecisionReveal":
      return (
        <QuestionDecisionReveal key="questionDecisionReveal" socket={socket} />
      );
    case "QuestionDecisionExplanation":
      return (
        <QuestionDecisionExplanation
          key="QuestionDecisionExplanation"
          socket={socket}
        />
      );
    case "thesis":
      return <ThesisPage key="thesis" socket={socket} />;
    case "thesisAnswers":
      return <ThesisAnswersPage key="thesisAnswers" socket={socket} />;
    default:
      return null;
  }
};
