import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import React from "react";
import styled, { css } from "styled-components";
import { transitionSlowSpring } from "./ScoreBar";
import { TextLink } from "./TextLink";

const Score = styled(motion.div)`
  width: 100%;
  height: auto;
  padding: 1em 3em;
  background: ${(props) =>
    !props.background ? props.theme.colors.white : props.theme.colors.white};
  border-radius: 8px;
  border: 1px solid #b2a1c7;
  margin-bottom: 10px;
  ${(props) =>
    props.first &&
    css`
      padding: 2em 3em;
      background-color: ${props.theme.colors.lightBlue};

      border-radius: 8px;
    `}
`;

const Header = styled(motion.h1)`
  font-size: 1.7em;
  margin: 0;

  ${(props) =>
    props.first &&
    css`
      font-size: 1.7em;
      color: #fff;
    `}
`;

const Text = styled(motion.p)`
  font-size: 1.5em;
  color: ${(props) => props.theme.colors.purple};
  margin: 0;
  font-weight: 400;

  ${(props) =>
    props.first &&
    css`
      font-size: 2em;
      color: #fff;
    `}
`;

const Ranking = ({ scores, setRanking }) => {
  return (
    <Grid container alignItems="center" direction="column">
      {scores.map((score, index) => (
        <Score
          key={score.group}
          initial={{ y: 60, opacity: 0 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ...transitionSlowSpring, delay: index * 0.1 + 0.5 }}
          exit={{ y: -60, opacity: 0, transition: { delay: index * 0.1 } }}
          background={index % 2 === 0}
          first={index === 0}
        >
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Header style={{ x: index === 0 && -9 }} first={index === 0}>
                <span
                  style={{
                    marginRight: index === 0 ? 95 : 100,
                    fontSize: index === 0 && 30,
                  }}
                >
                  {" "}
                  {index + 1}
                </span>
                Team {score.group}
              </Header>
            </Grid>
            <Grid item>
              <Text first={index === 0}>{score.total}</Text>
            </Grid>
          </Grid>
        </Score>
      ))}
      <TextLink
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        whileHover={{
          scale: 1.1,
          cursor: "pointer",
        }}
        transition={{
          scale: { delay: 0 },
          default: 0.5,
        }}
        onClick={() => setRanking(false)}
        navigationPrevPurple
        bottom={"50px"}
        left={"50px"}
        style={{
          position: "absolute",
        }}
      >
        terug
      </TextLink>
    </Grid>
  );
};

export default Ranking;
