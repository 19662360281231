import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import like from "../../../../static/media/like.png";

const CustomConfetti = ({
  image = like,
  isActive,
  xRange = 300,
  yRange = [-200, -400],
  top,
}) => {
  const [confetti, setConfetti] = useState([]);

  useEffect(() => {
    if (isActive) {
      const newConfetti = [];

      // Generate a random number of confetti pieces between 10 and 20
      const numConfetti = Math.floor(Math.random() * 11) + 10;

      for (let i = 0; i < numConfetti; i++) {
        // Generate random x and y positions for each confetti piece
        const x = Math.random() * xRange - xRange / 2;
        const y = Math.random() * (yRange[1] - yRange[0]) + yRange[0];

        newConfetti.push({
          x,
          y,
          rotation: Math.random() * 360,
          opacity: 1,
          scale: Math.random() * 0.5 + 0.5,
          id: Math.random().toString(36).substring(2, 15),
          rotate: Math.random() * 20 - 10,
        });
      }

      setConfetti(newConfetti);
    }
  }, [isActive, xRange]);

  return confetti.map((c, index) => (
    <motion.img
      key={c.id}
      src={image}
      alt="Confetti"
      style={{
        position: "absolute",
        top: top ? 0 : "50%",
        left: "50%",
        width: "40px",
        height: "40px",
        scale: c.scale,
        rotate: c.rotate,
      }}
      animate={{
        y: [
          0,
          c.y, // Shoot up randomly
          500, // Flutter down
        ],
        x: [
          0,
          c.x, // Flutter left or right
          c.x + Math.random() * 200 - 100, // Flutter left or right
        ],
        opacity: [0, 1, 0],
      }}
      transition={{
        duration: 5,
        ease: "easeInOut",
        x: {
          duration: 4,
          times: [0, 0.1, 1],
          ease: "easeInOut",
        },
        y: {
          duration: 5,
          times: [0, 0.08, 1],
          ease: "easeInOut",
        },
        opacity: {
          duration: 3,
          times: [0, 0.1, 1],
          ease: "easeInOut",
        },
      }}
      // onAnimationComplete={() => {
      //   setConfetti((prevConfetti) =>
      //     prevConfetti.filter((c, i) => i !== index)
      //   );
      // }}
    />
  ));
};

export default CustomConfetti;
