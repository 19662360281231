import React, { useEffect, useState } from "react";
import { AnimateSharedLayout, AnimatePresence } from "framer-motion";
import socketIOClient from "socket.io-client";
import ReactGa from "react-ga";

import { usePlayerStore } from "../store/playerStore";
import JoinRoom from "../themes/2023/components/pages/playerpages/JoinRoom";
import { toast } from "react-toastify";
import Screen from "../themes/2023/components/pages/playerpages/Screen";
import Game from "../themes/2023/components/pages/playerpages/Game";
import GameDecision from "../themes/2023/components/pages/playerpages/GameDecision";
import Thesis from "../themes/2023/components/pages/playerpages/Thesis";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PlayerContainer = () => {
  const {
    roomPin,
    phase,
    setLevel,
    setRoomPin,
    setPhase,
    setUser,
    setQuestion,
    setRound,
    setStartChecking,
    setPlayersAnswered,
    setStopAnswering,
    setDecision,
    setScreenOutro,
    setLastSeconds,
    setWinner,
  } = usePlayerStore();
  const [socket, setSocket] = useState();
  const [triggerPhase, setTriggerPhase] = useState();
  const history = useHistory();
  useEffect(() => {
    document
      .querySelector("meta[name=viewport]")
      .setAttribute(
        "content",
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      );
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    setSocket(
      socketIOClient(process.env.REACT_APP_SOCKET_SERVER, {
        transports: ["websocket"],
      })
    );
  }, []);

  useEffect(() => {
    if (!socket) return;

    socket.on("disconnect", (reason) => {
      toast("Verbinding vebroken :(");
      console.log(`disconnect because: ${reason}`);
      setTriggerPhase(reason);
    });

    socket.on("error", (error) => {
      console.log(`error: ${error}`);
    });

    socket.io.on("reconnect", (error) => {
      console.log(`reconnect: ${error}`);
      if (localStorage.getItem("user")) {
        setRoomPin(localStorage.getItem("roomPin"));
        socket.emit("join room", {
          roomPin: localStorage.getItem("roomPin"),
          reconnect: true,
          user: localStorage.getItem("user"),
        });
        setUser(parseInt(localStorage.getItem("user")));
      }
    });

    socket.on("reconnect_error", (reason) => {
      console.log(`reconnect error: ${reason}`);
    });

    socket.on("player disconnected", ({ attempt }) => {
      console.log(`reconnected!: ${attempt}`);
    });

    socket.on("connect", (error) => {
      if (localStorage.getItem("roomPin")) {
        setRoomPin(localStorage.getItem("roomPin"));
        socket.emit("registerToRoom", localStorage.getItem("roomPin"));
        setUser(parseInt(localStorage.getItem("user")));
      }
    });

    socket.on("validation", ({ validation, msg, roomPin, user }) => {
      if (validation) {
        localStorage.setItem("roomPin", roomPin.toUpperCase());
        setRoomPin(roomPin);
        socket.emit("join room", {
          roomPin,
          reconnect: false,
          user,
        });
        // history.push(`/cashquiz-speler?roompin=${roomPin.toUpperCase()}`);
      } else {
        toast(msg);
      }
    });
    socket.on("setHistory", ({ roomPin }) => {
      history.push(`/cashquiz-speler?roompin=${roomPin.toUpperCase()}`);
    });

    socket.on("full game", () => {
      toast("Deze game zit al vol");
      setPhase("joinroom");
    });

    socket.on("team id send", (groupId) => {
      setUser(parseInt(groupId));
      setPhase("firstscreen");
    });

    socket.on("rejoined", () => {
      toast("Welkom terug");
      toast(`Verbonden met spel: ${localStorage.getItem("roomPin")}`);
      if (localStorage.getItem("user"))
        setUser(parseInt(localStorage.getItem("user")));
      if (localStorage.getItem("roomPin"))
        setRoomPin(localStorage.getItem("roomPin"));
      setPhase("screen");
    });

    socket.on("start over player", () => {
      setPhase("joinroom");
    });

    //get question
    socket.on("question", ({ question, round, roomPin }) => {
      localStorage.setItem("roomPin", roomPin.toUpperCase());
      setRoomPin(roomPin);
      setQuestion(question);
      setRound(round);
      setPhase("game");
    });

    socket.on("question decision", ({ level }) => {
      setDecision(true);
      setPhase("gamedecision");
      setLevel(level);
    });

    socket.on("show decision", ({ level }) => {
      setDecision(true);
      setPhase("screen");
      setLevel(level);
    });

    socket.on("set winner", ({ winner }) => {
      setDecision(false);
      setPhase("screen");
      setWinner(winner);
    });

    socket.on("start checking", ({ playersAnswered }) => {
      setTimeout(() => {
        setStartChecking(true);
        setStopAnswering(true);
      }, 500);
      setPlayersAnswered(playersAnswered);
    });

    socket.on("screen", () => {
      setStartChecking(false);
      setPhase("screen");
    });

    socket.on("last seconds player", () => {
      setLastSeconds(true);
    });

    socket.on("thesisanswering", ({ question, round, roomPin }) => {
      localStorage.setItem("roomPin", roomPin.toUpperCase());
      setRoomPin(roomPin);
      setQuestion(question);
      setRound(round);
      setStartChecking(false);
      setPhase("thesisanswering");
    });

    socket.on("outro", () => {
      setPhase("screen");
      setScreenOutro("outro");
    });

    socket.on("end", () => {
      setPhase("screen");
      setScreenOutro("end");
    });

    socket.on("stop answering", () => {
      setStopAnswering(true);
    });

    socket.on("set gameid player", (gameid) => {
      localStorage.setItem("gameid", gameid);
      setPhase("screen");
    });

    // eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    if (triggerPhase) {
      if (phase === "firstscreen") {
        setPhase("joinroom");
      }
      setTriggerPhase(undefined);
    }
    // eslint-disable-next-line
  }, [triggerPhase, setTriggerPhase, phase, setPhase]);

  const renderScreen = () => {
    switch (phase) {
      case "joinroom":
        return <JoinRoom key="joinroom" socket={socket} />;
      case "firstscreen":
        return <Screen key="screenfirst" first socket={socket} />;
      case "screen":
        return <Screen key="screen" socket={socket} />;
      case "thesisanswering":
        return <Thesis key="thesisanswering" socket={socket} />;
      case "game":
        return <Game key="game" socket={socket} />;
      case "gamedecision":
        return <GameDecision key="gamedecision" socket={socket} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence exitBeforeEnter>{renderScreen()}</AnimatePresence>
      </AnimateSharedLayout>
    </div>
  );
};

export default PlayerContainer;
