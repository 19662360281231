import { toast } from "react-toastify";
import create from "zustand";
import { testQuestions } from "./testQuestions";
import { compare, arrayRemove } from "../hooks/helperfunctions";
import { themes } from "../data/themes";

let zinnen = [
  "Team {0} komt binnen",
  "Team {1} is binnen, laten we beginnen",
  "Team {2} is er klaar voor",
  "Team {3} in the building",
  "Team {4} hier",
  "Team {5} is gearriveerd",
  "Team {6}, klaar voor actie",
  "Team {7} meldt zich voor plicht",
  "Team {8} last but not least",
];

function getRandomSentence(id) {
  // pick a unique random sentence that is not taken
  let sentence = zinnen[id];
  // replace the placeholder with the id
  sentence = sentence.replace(/\{(\d+)\}/g, id);

  return sentence;
}

export const useSocketStore = create((set, get) => ({
  theme: themes["2023"],
  phase: "chooseTypeQuiz",
  roomPin: "1232",
  lessons: [],
  personalCode: undefined,
  lesson: undefined,
  level: {
    id: 43,
    asset_id: 385,
    parent_id: 1,
    lft: 81,
    rgt: 82,
    level: 1,
    path: "ken-je-bankzaken-mbo",
    extension: "com_quiz",
    title: "Ken je bankzaken - mbo",
    alias: "ken-je-bankzaken-mbo",
    note: "",
    description: "",
    published: 1,
    checked_out: 0,
    checked_out_time: "0000-00-00 00:00:00",
    access: 1,
    params:
      '{"category_layout":"","image":"","image_alt":"","videourl":"","videojpg":"","outrovideourl":"","outrovideojpg":"","groups":"8","points":"10","final_question":"Hoeveel procent van de Nederlanders heeft een creditcard?","final_answer":"55","final_answer_unit":"%"}',
    metadesc: "",
    metakey: "",
    metadata: '{"author":"","robots":""}',
    created_user_id: 929,
    created_time: "2023-01-26T08:24:08.000Z",
    modified_user_id: 929,
    modified_time: "2023-03-15T07:33:33.000Z",
    hits: 0,
    language: "*",
    version: 1,
  },
  teams: [],
  gameid: undefined,
  round: 0,
  questions: testQuestions,
  teamsAnswers: undefined,
  showCoins: false,
  decision: undefined,
  scores: [],
  duplicates: [],
  playersAnswered: [],
  answerPhase: false,
  edition: { title: "2023" },
  thesisAnswers: [],
  resetThesisAnswers: () => set({ thesisAnswers: [] }),
  setThesisAnswers: (data) => {
    set({ thesisAnswers: [...get().thesisAnswers, data] });
  },
  addPlayer: (data) => {
    const currentPlayers = get().teams;
    if (data.type === "new") {
      if (currentPlayers.length < 8) {
        let avail_groups = [1, 2, 3, 4, 5, 6, 7, 8];

        const updatedPlayers = currentPlayers.map((player) => {
          return player.group;
        });

        updatedPlayers.forEach((player) => {
          avail_groups = arrayRemove(avail_groups, player);
        });

        let avail_group = 0;
        for (var i = 1; i <= 8; i++) {
          if (avail_groups.indexOf(i) > -1) {
            avail_group = i;
            break;
          }
        }

        data.group = avail_group > 0 ? avail_group : currentPlayers.length + 1;
        data.sentence = getRandomSentence(data.group);
        let new_arr = [...currentPlayers, data];
        new_arr.sort(compare);

        set({ teams: new_arr });
        return data.group;
      } else {
        return "full";
      }
    } else if (data.type === "rejoin") {
      //rejoin only with the same name
      if (
        currentPlayers.some((e) => parseInt(e.group) === parseInt(data.user))
      ) {
        get().updatePlayer(data.user, true, data.socketId);
        return "rejoin";
      } else {
        return "err2";
      }
    }
  },
  updatePlayer: (group, status, newSocket) => {
    const currentPlayers = get().teams;
    const updatedPlayers = currentPlayers.map((player) =>
      player.group === group
        ? {
            ...player,
            onlineStatus: status,
            socketId: newSocket ? newSocket : player.socketId,
          }
        : player
    );
    set({ teams: updatedPlayers });
    if (status) toast(`Team ${group} is weer verbonden :)`);
  },
  removePlayer: (group) => {
    const currentPlayers = get().teams;
    const updatedPlayers = currentPlayers.filter((player) => {
      return player.group !== group;
    });
    set({ teams: updatedPlayers });
  },
  playerAnswering: (data) => {
    const currentplayersAnswered = get().playersAnswered;
    if (
      !currentplayersAnswered.some(
        (e) => parseInt(e.user) === parseInt(data.user)
      )
    ) {
      set({ playersAnswered: [...currentplayersAnswered, data] });
    } else
      set({
        playersAnswered: currentplayersAnswered.map((player) =>
          parseInt(player.user) !== parseInt(data.user) ? player : data
        ),
      });
  },
  resetPlayerAnswering: () => set({ playersAnswered: [] }),
  setDuplicates: (data) => set({ duplicates: data }),
  setRoomPin: (data) => set({ roomPin: data }),
  setQuestions: (data) => set({ questions: data }),
  setShowCoins: (data) => set({ showCoins: data }),
  setDecision: (data) => set({ decision: data }),
  setTheme: (data) => set({ theme: data }),
  setPhase: (data) => set({ phase: data }),
  setGameID: (data) => {
    set({ gameid: data });
    localStorage.setItem("gameid", data);
  },
  setRound: (data) => set({ round: data }),
  setLessons: (data) => set({ lessons: data }),
  setCode: (data) => set({ personalCode: data }),
  setLesson: (data) => set({ lesson: data }),
  setLevel: (data) => set({ level: data }),
  setTeams: (data) => set({ teams: data }),
  setTeamsAnswers: (data) => {
    set({ teamsAnswers: data });
  },
  updateScores: (data) => set({ scores: data }),
  setWinner: (data) => {
    fetch(process.env.REACT_APP_SERVER + "questions/set-winner/mbo", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((data) => console.log(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  setAnswerPhase: (data) => set({ answerPhase: data }),
}));
