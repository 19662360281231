import React from "react";
import { animated } from "react-spring";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import globe from "../../../../static/emojis/globe.png";
import bank from "../../../../static/emojis/bank.png";
import banknote from "../../../../static/emojis/banknote.png";
import beans from "../../../../static/emojis/beans.png";
import hand from "../../../../static/emojis/hand.png";
import moneyBag from "../../../../static/emojis/money-bag.png";
import cheese from "../../../../static/emojis/cheese.png";
import moneyMouth from "../../../../static/emojis/money-mouth.png";
import moneyWings from "../../../../static/emojis/money-wings.png";
import horse from "../../../../static/emojis/horse.png";

import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { transitionSpring } from "../pages/hostpages/SelectLesson";

export const transition = {
  type: "spring",
  stiffness: 100,
};

export const Coins = styled(animated.div)`
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  will-change: transform;
  width: 52px;
  height: 52px;
  z-index: -1;
  ${(props) =>
    props.image &&
    css`
      background-image: url(${props.image});
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
    ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

function CoinsParallax({ mouse, initial }) {
  const trans5 = (x, y) => `translate3d(${x / 50}px,${y / 50 + 50}px,0)`;
  const trans6 = (x, y) => `translate3d(${-x / 40}px,${-y / 40}px,0)`;
  const { small } = useWindowDimensions();
  return (
    <div
      style={{
        position: "absolute",
        width: "100vw",
        height: "100vh",
        zIndex: -1,
        overflow: "hidden",
      }}
    >
      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.4, ...transitionSpring }}
        style={{
          position: "absolute",
          //center
          left: "20%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Coins
          image={moneyBag}
          style={{
            top: 100,
            right: -50,
            transform: mouse.xy && mouse.xy.interpolate(trans5),
          }}
        />
        <Coins
          image={horse}
          style={{
            top: -150,
            right: 50,
            transform: mouse.xy && mouse.xy.interpolate(trans6),
          }}
        />
        <Coins
          image={moneyWings}
          style={{
            top: 0,
            right: 100,
            transform: mouse.xy && mouse.xy.interpolate(trans6),
          }}
        />
        <Coins
          image={bank}
          style={{
            top: -50,
            right: -150,
            transform: mouse.xy && mouse.xy.interpolate(trans5),
          }}
        />
        <Coins
          image={hand}
          style={{
            top: -250,
            right: -150,
            transform: mouse.xy && mouse.xy.interpolate(trans6),
          }}
        />
      </motion.div>
      {!small && (
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.5, ...transitionSpring }}
          style={{
            position: "absolute",
            //center
            left: "80%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Coins
            image={banknote}
            style={{
              transform: mouse.xy && mouse.xy.interpolate(trans5),
              top: -100,
              right: 0,
            }}
          />
          <Coins
            image={globe}
            style={{
              transform: mouse.xy && mouse.xy.interpolate(trans6),
              top: 100,
              right: 100,
            }}
          />
          <Coins
            image={beans}
            style={{
              top: -300,
              right: 150,
              transform: mouse.xy && mouse.xy.interpolate(trans5),
            }}
          />
          <Coins
            image={cheese}
            style={{
              top: -180,
              right: -100,
              transform: mouse.xy && mouse.xy.interpolate(trans6),
            }}
          />

          <Coins
            image={moneyMouth}
            style={{
              top: 150,
              right: -100,
              transform: mouse.xy && mouse.xy.interpolate(trans5),
            }}
          />
        </motion.div>
      )}
    </div>
  );
}

export default CoinsParallax;
