import { motion } from "framer-motion";
export const StartTitle = ({ title }) => {
  return (
    <motion.div
      style={{
        fontSize: "83px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        textAlign: "left",
      }}
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: [0, 10, 5, 0, 10] }}
      transition={{ duration: 0.6, ease: "easeInOut" }}
      exit={{ opacity: 0, y: 50 }}
    >
      <div>{title.first}</div> <div>{title.second}</div>
    </motion.div>
  );
};
