import React, { useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import { Button } from "../../atoms/Buttons";
import { useMainStore } from "../../../../../store";
import { transitionSpring } from "../../../../../default/pages/mainpages/SelectLesson";

const QuestionDecisionExplanation = () => {
  const { setPhase, duplicates, setShowCoins, theme } = useMainStore();

  useEffect(() => {
    setShowCoins(false);
  }, []);

  return (
    <>
      <motion.div>
        <Grid
          style={{
            height: "100vh",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={8} lg={6}>
            <motion.h2
              transition={{
                opacity: { delay: 0.2 },
                ...transitionSpring,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {duplicates.length === 2
                ? `Gelijkspel tussen team ${duplicates[0].group} en team ${duplicates[1].group}`
                : `Gelijkspel tussen teams
            ${duplicates.map(
              (dup) =>
                `${dup.group}${
                  duplicates.length !== parseInt(dup.group) ? "" : ""
                } `
            )}`}
            </motion.h2>
            <motion.p
              transition={{
                opacity: { delay: 0.2 },
                ...transitionSpring,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                fontSize: "2.2em",
                lineHeight: "1.4em",
                color: theme.colors.purple,
              }}
            >
              Wat maken jullie het spannend! Om een winnaar aan te wijzen gaan
              we de beslissingsvraag spelen. Degene die het dichtste bij het
              goede antwoord zit heeft de Cash Quiz gewonnen. Let goed op, want
              de tijd begint direct te lopen.
            </motion.p>
            <motion.p
              transition={{
                opacity: { delay: 0.2 },
                ...transitionSpring,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                fontSize: "2.2em",
                lineHeight: "1.4em",
                color: theme.colors.purple,
              }}
            >
              <strong>Veel succes!</strong>
            </motion.p>
          </Grid>
        </Grid>
        <Grid
          style={{
            position: "absolute",
            bottom: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              primary
              onClick={() => setPhase("quizStart")}
              exit={{ opacity: 0 }}
            >
              Start de beslissingsvraag
            </Button>
          </Grid>
        </Grid>
      </motion.div>
    </>
  );
};

export default QuestionDecisionExplanation;
