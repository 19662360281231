import React, { useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import { NavigationNext } from "../../atoms/Buttons";
import Topbar from "../../molecules/Topbar";
import { PanelQuestion } from "../../molecules/PanelQuestion";

import { transitionSpring } from "./SelectLesson";
import { checkClosestAnswer } from "../../../../../hooks/helperfunctions";
import Counter from "../../atoms/Counter";
import { useSocketStore } from "../../../../../store/socketStore";

const questionVariants = {
  full: {
    width: "100vw",
    x: "100%",
  },
  slideleft: {
    transition: { duration: 0.8 },
    x: "0%",
  },
  hide: {
    opacity: 0,
  },
  exit: {
    x: "-100%",
    transition: { delay: 1.5, duration: 0.8 },
  },
};

const QuestionDecisionReveal = ({ socket }) => {
  const {
    setPhase,
    roomPin,
    updateScores,
    gameid,
    playersAnswered,
    level,
    setDecision,
    theme,
  } = useSocketStore();

  useEffect(() => {
    const estimationAnswers = checkClosestAnswer(
      playersAnswered,
      parseInt(JSON.parse(level.params).final_answer)
    );

    for (let i = 0; i < estimationAnswers.length; i++) {
      if (estimationAnswers[i].result === true) {
        estimationAnswers[i].correct = 1;
        estimationAnswers[i].points = 10;
      }

      fetch(process.env.REACT_APP_SERVER + "score/set-player-answer", {
        method: "POST",
        body: JSON.stringify(estimationAnswers[i]),
        headers: { "Content-Type": "application/json" },
      }).catch((error) => {
        console.error("Error:", error);
      });
    }
  }, [level.params, playersAnswered]);

  useEffect(() => {
    socket.emit("start checking server", { roomPin, playersAnswered });
  }, [playersAnswered, roomPin, socket]);

  useEffect(() => {
    setTimeout(() => {
      fetch(process.env.REACT_APP_SERVER + "score/get-totals/" + gameid)
        .then((res) => res.json())
        .then(
          (result) => {
            updateScores(result);
          },
          (error) => {}
        );
    }, 1000);
  }, [gameid, updateScores]);

  return (
    <>
      <Topbar
        show={false}
        question="Beslissingsvraag"
        questionSoort="Beslissingsvraag"
      />
      <PanelQuestion
        transition={{ delay: 5 }}
        variants={questionVariants}
        initial="full"
        animate="slideleft"
        exit="exit"
        alignItems="center"
        style={{
          backgroundColor: theme.colors.background,
          paddingTop: "60px",
        }}
      >
        <Grid
          container
          style={{ height: "100%" }}
          spacing={4}
          alignItems="center"
          justify="center"
        >
          <Grid component={motion.div} layout item xs={11} lg={8}>
            <motion.h1
              dangerouslySetInnerHTML={{
                __html: JSON.parse(level.params).final_question,
              }}
              style={{
                lineHeight: "1.2em",
              }}
              transition={{ ...transitionSpring, delay: 1.2 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, transition: { delay: 0 } }}
            />
            <>
              <Counter
                unit={JSON.parse(level.params).final_answer_unit}
                unitPrepend={false}
                from={0}
                to={parseInt(JSON.parse(level.params).final_answer)}
              />
            </>
          </Grid>
        </Grid>
      </PanelQuestion>
      <NavigationNext
        text="Volgende"
        bottom={30}
        right={30}
        nextPhase={() => {
          setDecision(false);
          setPhase("end");
        }}
      />
    </>
  );
};

export default QuestionDecisionReveal;
