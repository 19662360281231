export const PhonesNew = () => {
    return <>
   <svg xmlns="http://www.w3.org/2000/svg"  
                width={100}
                height={100}
                viewBox="0 0 1000 1000">
    <defs>
      <style>{".cls-6{fill:#353535}"}</style>
    </defs>
    <g id="Layer_27" data-name="Layer 27">
      <g id="Layer_8" data-name="Layer 8">
        <path
          d="M710.66 169.72s-37.6-4.7-45.44 29.77l-94.01 506.1s-15.67 47.01 47.01 54.84l198.99 23.5s43.87 14.1 57.97-36.04l80.69-508.45s10.97-45.44-34.47-53.27l-210.74-16.45Z"
          className="cls-6"
        />
        <text
          style={{
            fontFamily: "Bungee-Regular,Bungee",
            fontSize: "166.09px",
            letterSpacing: 0,
          }}
          transform="translate(735.85 384.98)"
        >
          <tspan x={0} y={0}>
            {"A"}
          </tspan>
        </text>
      </g>
      <path
        id="Layer_7"
        d="m618.21 740.05 197.42 23.5s31.34 9.4 40.74-26.64l79.91-499.83s10.97-37.6-21.94-40.74l-25.07-3.13s-7.83-1.57-9.4 3.13-3.13 14.1-12.53 14.1l-122.22-10.97s-5.74 1.07-6-6.52c-.26-7.58-.26-6.02-.26-6.02s0-6.27-6.27-6.27-25.07-1.57-25.07-1.57h-7.73s-23.61 1.57-26.74 29.77l-89.31 484.16s-15.67 47.01 34.47 47.01Z"
        data-name="Layer 7"
        style={{
          fill: "#eaeaea",
        }}
      />
      <path
        id="Layer_9"
        d="M714.77 600.46c-.78 4.46-2.09 7.61-3.92 9.45-1.84 1.84-4.43 2.58-7.76 2.23l-38.69-4.05c-3.33-.35-5.56-1.59-6.66-3.71-1.11-2.12-1.23-5.39-.36-9.79l17.96-91.74c1.36-6.95 3.72-14.88 7.09-23.79 3.35-8.92 7.86-19.28 13.51-31.11l36.22-77.9c1.69-3.91 3.71-6.75 6.07-8.51 2.35-1.76 5.12-2.56 8.34-2.39l64.82 3.41c3.05.16 5.44 1.25 7.16 3.26 1.73 2.02 2.78 5.08 3.14 9.18l9.69 82.79c1.6 12.72 2.56 23.82 2.87 33.31.32 9.49 0 17.85-.98 25.05l-12.89 95.14c-.62 4.57-1.81 7.79-3.58 9.68-1.77 1.89-4.32 2.65-7.66 2.3l-39.74-4.16c-3.51-.37-5.94-1.65-7.29-3.84-1.35-2.19-1.67-5.53-.96-10.04l7.81-49.8-45.62-4.24-8.58 49.29Zm43.15-165.9-20.9 52.74 39.59 3.17-3.1-54.84c-.32-2.17-.76-3.75-1.35-4.75-.59-.99-1.37-1.53-2.36-1.59l-6.17-.42c-.99-.07-1.93.35-2.83 1.24-.9.89-1.86 2.38-2.87 4.45Z"
        data-name="Layer 9"
        style={{
          fill: "#a55a00",
        }}
      />
      <path
        id="Layer_4"
        d="m77.17 363.36-16.53 7.04s-36.3 16.85-12.79 66.99l355.68 473.19s34.47 51.71 92.44 28.2l209.96-95.58s25.24-26.89 7.14-58.88L339.29 326.14s-13.12-21.27-53.57-20.03L77.17 363.36Z"
        className="cls-6"
        data-name="Layer 4"
      />
      <path
        id="Layer_2"
        d="m66.68 365.57 200.56-56.41s44.66-16.45 71.29 21.15l361.94 448.12s26.64 28.64 0 43.31l-186.46 86.74s-53.27 29.77-97.15-23.5L66.68 413.27s-25.85-37.51 0-47.7Z"
        data-name="Layer 2"
        style={{
          fill: "#6d6d6d",
        }}
      />
      <path
        id="Layer_3"
        d="M680.11 778.44 326 342.85s-18.8-26.64-43.87-17.24l-20.37 6.27s-3.13 3.96 0 8.25c0 0 6.62 9.58-1.39 12.42l-106.72 31.04s-10.97 6.27-18.8 0l-7.83-6.27s-1.57-4.7-10.97-1.57l-18.8 6.27s-31.34 6.27-12.53 34.47L435.7 883.41s20.37 26.64 50.14 14.1l186.46-86.18s21.94-9.4 7.83-32.9Z"
        data-name="Layer 3"
        style={{
          fill: "#f9f9f9",
        }}
      />
      <path
        id="Layer_5"
        d="m477.63 688.74-85.25 45.62c-2.88 1.54-5.5 1.85-7.87.9-2.37-.94-4.76-3.24-7.17-6.89L253.92 541.83c-2.41-3.64-3.42-6.48-3.02-8.52.4-2.02 2.26-3.48 5.57-4.36l81.03-21.67c17.6-4.71 32.81-4.43 45.57.74 12.67 5.04 22.61 13 29.87 23.96l7.92 11.96c4.18 6.32 6.73 12.18 7.66 17.61.94 5.45-.52 10.37-4.4 14.74 9.44-2.48 19.31-1.52 29.57 2.82 10.2 4.25 19.06 12.05 26.61 23.45l14.89 22.48c8.09 12.22 10.77 23.86 8.1 35.01-2.58 11.3-11.11 20.89-25.66 28.67ZM336.45 561.93l19.42 29.34 18.87-6.83c3.97-1.44 5.84-3.42 5.6-5.95-.24-2.52-1.28-5.19-3.14-8l-4.88-7.38c-1.86-2.81-4.08-5.07-6.65-6.8-2.58-1.73-5.9-1.94-9.95-.62l-19.25 6.24Zm47.11 71.18 21.22 32.06 28.48-13.24c3.77-1.75 5.51-3.93 5.22-6.52-.28-2.59-1.33-5.27-3.17-8.05l-6.72-10.15c-1.84-2.77-3.98-4.92-6.42-6.44-2.45-1.52-5.6-1.48-9.45.13l-29.15 12.21Z"
        data-name="Layer 5"
        style={{
          fill: "#082166",
        }}
      />
      <path
        id="Layer_10"
        d="m948.61 236.89-11.3 17.28-312.82 219.52s-1.49-3.13-3.13-3.13-2.98 7.22-4.62 11.85c-1.64 4.63-3.21-5.59-3.21-5.59l50.14-269.5s4.7-34.47 35.22-37.6l223.31 17.24s34.47 3.13 34.47 40.74l-8.05 9.2Z"
        data-name="Layer 10"
        style={{
          opacity: 0.41,
          fill: "#fff",
        }}
      />
    </g>
    <path
      id="Layer_11"
      d="m145.02 343.64 62.67 20.37 1.58 3.4 166.07 37.33s1.57-4.7 10.97-3.13l18.8 4.7-65.81-79.91s-15.67-25.07-63.96-19.36l-130.33 36.6Z"
      data-name="Layer 11"
      style={{
        fill: "#fff",
        opacity: 0.16,
      }}
    />
  </svg></>
}