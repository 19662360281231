import styled from "styled-components";
import { motion } from "framer-motion";

export const PanelQuestion = styled(motion.div)`
  position: absolute;
  height: calc(100vh - 60px);
  top: 0;
  padding: 0 0 0 0;
  width: 250px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h1 {
    color: ${(props) => props.theme.colors.yellow};
  }
`;
