import { motion } from "framer-motion";
import React from "react";
import { useMainStore } from "../../store";

const IconSchattingsvraag = ({ color, initial }) => {
  const { theme } = useMainStore();

  return (
    <motion.svg
      initial={initial && { fill: theme.colors.purple }}
      animate={initial && { fill: color }}
      style={!initial && { fill: color }}
      id="bold"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1080 1080"
      enable-background="new 0 0 1080 1080"
    >
      <path
        className="st0"
        d="M966.5-2H113.5C50.8-2,0,48.8,0,111.5v852.9c0,62.7,50.8,113.5,113.5,113.5h852.9
		c62.7,0,113.5-50.8,113.5-113.5V111.5C1080,48.8,1029.2-2,966.5-2z M93.8,603.6c0-20.5,17-36.6,36.6-36.6h145.4
		c20.5,0,36.6,17,36.6,36.6c0,20.5-17,36.6-36.6,36.6H130.4C109.8,640.1,93.8,623.2,93.8,603.6z M305.2,782H158.9
		c-20.5,0-36.6-16.1-36.6-36.6s17-36.6,36.6-36.6h146.3c19.6,0,36.6,16.1,36.6,36.6S324.9,782,305.2,782z M305.2,499.2H158.9
		c-20.5,0-36.6-16.1-36.6-36.6s17-36.6,36.6-36.6h146.3c19.6,0,36.6,16.1,36.6,36.6S324.9,499.2,305.2,499.2z M617.5,927.4
		c-83.9,0-163.3-32.1-223.9-90.1c-14.3-13.4-14.3-36.6-0.9-50.9c13.4-14.3,36.6-14.3,50.9-0.9c47.3,44.6,108.8,69.6,174,69.6
		c139.2,0,252.5-113.3,252.5-252.5c0-138.3-113.3-251.6-252.5-251.6c-65.1,0-127.6,25-174,69.6c-14.3,14.3-36.6,13.4-50.9-0.9
		c-14.3-14.3-13.4-36.6,0.9-50.9c50.9-49.1,116.9-79.4,187.3-87.4v-59.8H539c-20.5,0-36.6-16.1-36.6-36.6s17-36.6,36.6-36.6h156.1
		c20.5,0,36.6,16.1,36.6,36.6s-17,36.6-36.6,36.6h-41.9v59.8c161.5,17.8,288.2,155.2,289.1,321.2C942.2,782,796.8,927.4,617.5,927.4
		z M737,567c20.5,0,36.6,17,36.6,36.6c0,20.5-17,36.6-36.6,36.6H616.6c-20.5,0-36.6-17-36.6-36.6v-141c0-20.5,17-36.6,36.6-36.6
		s36.6,16.1,36.6,36.6V567H737z"
      />
    </motion.svg>
  );
};

export default IconSchattingsvraag;
