import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { spring } from "popmotion";
import { useFocused, useOnClickOutside } from "../../../../hooks/useDropdown";
import { useMainStore } from "../../../../store";

const Dropdown = ({ items, title, setTitle, small }) => {
  const { theme } = useMainStore();
  const [clicked, setClicked] = useState(false);
  const [titleHovered, setTitleHovered] = useState(false);
  const [curIndex, setCurIndex] = useState(-1);
  const dropdown = useRef(null);
  const dropdownContainer = useRef(null);
  const dropdownFocused = useFocused(dropdown);
  useOnClickOutside(dropdownContainer, () => {
    setClicked(false);
  });

  useEffect(() => {
    document.addEventListener("keypress", onKeypress);
    document.addEventListener("keydown", onKeydown);
    return () => {
      document.removeEventListener("keypress", onKeypress);
      document.removeEventListener("keydown", onKeydown);
    };

    // eslint-disable-next-line
  }, [curIndex, titleHovered, clicked, dropdownFocused, onkeydown, onkeypress]);

  const onKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (dropdownFocused) {
        setClicked(!clicked);
      }
      if (clicked) {
        setTitle(items[curIndex]);
        setClicked(false);
      } else if (titleHovered) {
        setClicked(!clicked);
      }
    }
  };

  const onKeydown = (e) => {
    // Down arrowkey or tab is pressed
    if (e.keyCode === 40 || e.keyCode === 9) {
      e.preventDefault();
      if (curIndex + 1 < items.length) setCurIndex(curIndex + 1);
      else setCurIndex(0);
      // Up arrowkey is pressed
    } else if (e.keyCode === 38) {
      if (curIndex - 1 > -1) setCurIndex(curIndex - 1);
      else setCurIndex(items.length - 1);
    }
  };

  const options = items.map((item, index) => {
    return (
      <Option
        key={`option-${index + 1}`}
        animate={
          curIndex === index
            ? { backgroundColor: "#fff" }
            : { backgroundColor: theme.colors.white }
        }
        whileTap={{ backgroundColor: "#fff" }}
        onHoverStart={() => {
          setCurIndex(index);
        }}
        onHoverEnd={() => {
          setCurIndex(-1);
        }}
        noBorder={index === items.length - 1}
        onClick={() => {
          setClicked(false);
          setTitle(item);
        }}
      >
        {item}
      </Option>
    );
  });

  return (
    <DropdownContainer ref={dropdownContainer}>
      <TitleContainer
        small={small}
        ref={dropdown}
        type="button"
        onClick={() => {
          setClicked(!clicked);
        }}
        onHoverStart={() => {
          setTitleHovered(true);
        }}
        onHoverEnd={() => {
          setTitleHovered(false);
        }}
      >
        <Title>{title ? title : "Selecteer Bank"}</Title>
        <ArrowSvgContainer aria>
          <ArrowSvg
            animate={clicked ? { rotateZ: 180 } : { rotateZ: 0 }}
            viewBox="0 0 13 7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.86543 0.373845C0.55918 0.680095 0.55918 1.17385 0.86543 1.4801L6.05918 6.67385C6.30293 6.9176 6.69668 6.9176 6.94043 6.67385L12.1342 1.4801C12.4404 1.17385 12.4404 0.680095 12.1342 0.373845C11.8279 0.067595 11.3342 0.067595 11.0279 0.373845L6.49668 4.89885L1.96543 0.367595C1.66543 0.0675955 1.16543 0.067595 0.86543 0.373845Z"
              fill="black"
              fillOpacity="0.54"
            />
          </ArrowSvg>
        </ArrowSvgContainer>
      </TitleContainer>
      {/* <div style={{ position: "relative" }}> */}
      <OptionsContainer
        initial={{ height: 0 }}
        animate={
          clicked
            ? {
                borderWidth: "1px",
                height: "auto",
                boxShadow: "0px 3px 0px #b2a1c7",
              }
            : {
                borderWidth: "0px",
                height: 0,
                boxShadow: "0px 0px 0px #b2a1c7",
                transition: { type: spring },
              }
        }
      >
        {options}
      </OptionsContainer>
      {/* </div> */}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  transform: translateY(0px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 0.4rem;
  color: inherit;
  border: 1px solid #b2a1c7;
  box-shadow: 0px 3px 0px #b2a1c7;
  *::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(255, 255, 255, 1);
    transform: translateX(-10px);
    border-bottom-right-radius: 10020px;
  }

  *::-webkit-scrollbar-track {
    width: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0);
  }

  *::-webkit-scrollbar-thumb {
    width: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${(props) => props.theme.colors.purple};
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.purple};
  font-size: 1.5em;
  font-family: proxima-nova;
  overflow-wrap: break-word;
`;

const TitleContainer = styled(motion.button)`
  width: 100%;
  border: none;
  padding: ${(props) => (props.small ? "7px 10px" : "15px 20px")};
  min-height: 2.2rem;
  height: auto;
  background-color: ${(props) => props.theme.colors.white};

  border-radius: 0.4375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  font-size: 1rem;
  cursor: pointer;
`;

const ArrowSvgContainer = styled(motion.span)`
  padding: 0.5rem;
  border-radius: 5rem;
  width: 0.8125rem;
  height: 0.8125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
`;

const ArrowSvg = styled(motion.svg)`
  width: 0.8125rem;
  height: 0.4375rem;
  fill: none;
  background-color: transparent;
`;

const OptionsContainer = styled(motion.ul)`
  font-size: 1.2em;
  display: flex;
  width: 100%;
  flex-direction: column;
  border-bottom-left-radius: 0.8125em;
  border-bottom-right-radius: 0.8125em;
  justify-content: flex-start;
  overflow-y: scroll;
  padding: 0;
  margin: 0 0 1.2rem 0;
  position: absolute;
  top: 60px;
  z-index: 2;
  max-height: 200px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid #b2a1c7;
  box-shadow: 0px 3px 0px #b2a1c7;
`;

const Option = styled(motion.li)`
  height: auto;
  background-color: ${(props) => props.theme.colors.white};
  padding: 0.5rem 0.5rem 0.5rem 20px;
  border-bottom-width: ${(props) => (props.noBorder ? "0px" : "1px")};
  border-bottom-style: solid;
  border-bottom-color: ${(props) => (props.noBorder ? "none" : "#E7E7E7")};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  overflow-wrap: break-word;
  font-family: proxima-nova;
  user-select: none;
  cursor: pointer;
`;

export default Dropdown;
