import { motion } from "framer-motion";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { useMainStore } from "../../../../store";

const CloudBackgroundSubmitAnswer = () => {
  const { width, small } = useWindowDimensions();
  const { theme } = useMainStore();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      style={{
        y: small ? 500 : 700,
        zIndex: -1,
        position: "absolute",
        x: small ? width * 0.8 : width * 0.5,
      }}
    >
      <motion.div
        style={{
          zIndex: "-1",
          width: small ? "180vw" : "120vw",
          height: small ? "180vw" : "120vw",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -70%)",
          borderRadius: "100%",
          textAlign: "center",
          position: "absolute",
          background: theme.colors.grey,
        }}
      ></motion.div>
      <motion.div
        style={{
          zIndex: "-1",
          width: small ? "180vw" : "100vw",
          height: small ? "180vw" : "100vw",
          left: "50%",
          top: "50%",
          transform: small
            ? "translate(-110%, -65%)"
            : "translate(-130%, -65%)",
          borderRadius: "100%",
          textAlign: "center",
          position: "absolute",
          background: theme.colors.grey,
        }}
      ></motion.div>
      <motion.div
        style={{
          display: small ? "none" : "block",
          zIndex: "-1",
          width: small ? "200vw" : "120vw",
          height: small ? "200vw" : "120vw",
          left: "50%",
          top: "50%",
          transform: "translate(-10%, -70%)",
          borderRadius: "100%",
          textAlign: "center",
          position: "absolute",
          background: theme.colors.grey,
        }}
      ></motion.div>
      <motion.div
        style={{
          display: small ? "none" : "block",
          zIndex: "-1",
          width: small ? "160vw" : "80vw",
          height: small ? "160vw" : "80vw",
          left: "50%",
          top: "50%",
          transform: "translate(10%, -80%)",
          borderRadius: "100%",
          textAlign: "center",
          position: "absolute",
          background: "#fff",
        }}
      ></motion.div>
      <motion.div
        style={{
          display: small ? "none" : "block",
          zIndex: "-1",
          width: small ? "140vw" : "60vw",
          height: small ? "140vw" : "60vw",
          left: "50%",
          top: "50%",
          transform: "translate(-55%, -80%)",
          borderRadius: "100%",
          textAlign: "center",
          position: "absolute",
          background: "#fff",
        }}
      ></motion.div>
      <motion.div
        style={{
          display: small ? "none" : "block",
          zIndex: "-1",
          width: small ? "120vw" : "40vw",
          height: small ? "120vw" : "40vw",
          left: "50%",
          top: "50%",
          transform: "translate(-140%, -60%)",
          borderRadius: "100%",
          textAlign: "center",
          position: "absolute",
          background: "#fff",
        }}
      ></motion.div>
    </motion.div>
  );
};

export default CloudBackgroundSubmitAnswer;
