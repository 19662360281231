import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion, useAnimation } from "framer-motion";

import { PanelQuestion } from "../../molecules/PanelQuestion";
import TimerBar from "../../atoms/TimerBar";
import { useMainStore } from "../../../../../store";
import { NavigationNext } from "../../atoms/Buttons";
import { transitionSpring } from "../../../../../default/pages/mainpages/SelectLesson";
import Topbar from "../../molecules/Topbar";
import useInterval from "../../../../../hooks/useInterval";

const backgroundVariants = {
  peak: {
    x: -100,
    transition: { duration: 1, type: "spring" },
  },
  small: {
    transition: { duration: 1, type: "spring" },
  },
  exit: {
    x: "-100%",
    transition: { ...transitionSpring, delay: 0.5 },
  },
};

const QuestionDecision = () => {
  const { setPhase, level, theme } = useMainStore();
  const answersPanelAnimation = useAnimation();
  const questionPanelAnimation = useAnimation();
  const questionAnimation = useAnimation();
  const backgroundAnimation = useAnimation();
  const CloudBackgroundDescriptionAnimation = useAnimation();
  const [answerPhase, setAnswerPhase] = useState("show");
  let [timer, startTimer] = useState(false);

  const variants = {
    full: {
      transition: { duration: 1, type: "spring" },
      width: "100%",
      paddingLeft: "0",
      x: 0,
    },
    hide: {
      opacity: 0,
    },
  };

  useEffect(() => {
    let timeout;
    if (!answerPhase) {
      questionPanelAnimation.start("peak");
      timeout = setTimeout(() => {
        backgroundAnimation.start("peak");
      }, 2000);
    } else if (answerPhase === "show") {
      questionAnimation.start("show");
      answersPanelAnimation.start("full");
      questionPanelAnimation.start("small");
      startTimer(60);
    } else if (answerPhase === "exit") {
      questionPanelAnimation.start("hide");
      answersPanelAnimation.start("hide");
    }
    return () => clearTimeout(timeout);
  }, [
    answerPhase,
    answersPanelAnimation,
    questionAnimation,
    questionPanelAnimation,
    CloudBackgroundDescriptionAnimation,
    backgroundAnimation,
  ]);

  useInterval(() => {
    if (timer !== 0) {
      startTimer(timer - 1);
    }
  }, 1000);

  return (
    <>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: "0%" }}
        transition={{
          delay: 0.5,
          ...transitionSpring,
        }}
        style={{
          zIndex: -1,
          width: "120vw",
          height: "100vh",
          position: "absolute",
          background: theme.colors.background,
        }}
      />
      <motion.div
        style={{
          zIndex: 0,
          position: "absolute",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0)",
        }}
        animate={CloudBackgroundDescriptionAnimation}
        variants={backgroundVariants}
        exit="exit"
      />
      <Topbar
        question="Beslissingsvraag"
        show={false}
        questionValue={"10"}
        questionSoort="Beslissingsvraag"
      />
      <Grid container alignItems="center" justify="center">
        <Grid item xs={11} lg={8} style={{ position: "relative" }}>
          <PanelQuestion
            variants={variants}
            animate={answersPanelAnimation}
            style={{
              x: 250,
              height: "calc(100vh - 155px)",
            }}
          >
            {answerPhase === "show" && (
              <Grid
                container
                style={{ height: "100%" }}
                alignItems={"center"}
                justify="center"
              >
                <Grid
                  item
                  style={{
                    width: "100%",
                    marginLeft: "30px",
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                    exit={{ opacity: 0, transition: { delay: 0 } }}
                  >
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: JSON.parse(level.params).final_question,
                      }}
                      style={{
                        lineHeight: "1.2em",
                        marginTop: "0",
                      }}
                    />
                  </motion.div>
                </Grid>
              </Grid>
            )}
          </PanelQuestion>
        </Grid>
        {answerPhase === "show" && (
          <Grid
            container
            alignItems="center"
            justify="center"
            style={{
              padding: "30px",
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
            }}
          >
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justify="flex-end"
                spacing={4}
              >
                <Grid item xs={4}>
                  <TimerBar
                    timer={timer}
                    nextPhase={() => setPhase("submitAnswers")}
                  />
                </Grid>
                <Grid item>
                  <NavigationNext
                    transition={{ ...transitionSpring, delay: 5 }}
                    text="Vul antwoorden in"
                    nextPhase={() => setPhase("submitAnswers")}
                    bottom={60}
                    flexPosition
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default QuestionDecision;
