import React, { useEffect, useState } from "react";
import { AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { useSpring } from "react-spring";
import socketIOClient from "socket.io-client";
import ReactGa from "react-ga";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";

import bg from "../static/media/contrast-bg.png";
import bg2 from "../static/media/contrast-bg2.png";
import CoinsParallax from "../themes/2023/components/molecules/CoinsParallax";
import PersonalCode from "../default/pages/hostpages/PersonalCode";
import { useSocketStore } from "../store/socketStore";
import { toast } from "react-toastify";
import NoCodeForm from "../default/pages/hostpages/NoCodeForm";
import { useGAEventsTracker } from "../hooks/useGAEventsTracker";
import { renderScreen2023 } from "../themes/2023/components/pages/hostpages/renderscreens";
import EditionPage from "../default/pages/hostpages/EditionPage";
import { useMainStore } from "../store";
import UnavailableModal from "../default/components/UnavailableModal";

const HostContainer = () => {
  const {
    teams,
    roomPin,
    phase,
    answerPhase,
    showCoins,
    setRoomPin,
    setGameID,
    setPhase,
    addPlayer,
    updatePlayer,
    removePlayer,
    playerAnswering,
    questions,
    round,
    gameid,
    edition,
    thesisAnswers,
    setThesisAnswers,
  } = useSocketStore();
  const [socket, setSocket] = useState();
  const [triggerQuestion, setTriggerQuestion] = useState(false);
  const [updateScore, setUpdateScore] = useState(false);
  const [triggerDisconnect, setTriggerDisconnect] = useState();
  const GAEventsTracker = useGAEventsTracker("cashquiz-plus");
  const { typeHost, typeQuiz, setShowMoney } = useMainStore();
  const [socketUnavailble, setSocketUnavailble] = useState(undefined);
  const history = useHistory();
  useEffect(() => {
    setShowMoney(false);
    if (!typeHost || !typeQuiz) history.push("/");

    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (!socket) {
      setSocket(
        socketIOClient(process.env.REACT_APP_SOCKET_SERVER, {
          transports: ["websocket"],
        })
      );
    }
  }, [socket]);

  useEffect(() => {
    if (socket !== undefined) {
      if (socket.disconnected) {
        setTimeout(() => {
          if (socketUnavailble === undefined) {
            setSocketUnavailble(true);
          }
        }, 1000);
      } else if (socket.connected === true) setSocketUnavailble(false);
    }
  }, [socket, socketUnavailble]);

  useEffect(() => {
    if (!socket) return;
    if (phase === "outro") socket.emit("send-outro", roomPin);
    if (phase === "end") socket.emit("send-end", roomPin);
  }, [phase, socket, roomPin]);

  useEffect(() => {
    if (!socket) return;

    socket.on("disconnect", (reason) => {
      toast("Verbinding vebroken :(");
      console.log(`disconnect because: ${reason}`);
    });

    socket.on("connect", (error) => {
      setSocketUnavailble(false);
      socket.emit("registerToRoom", localStorage.getItem("roomPin"));
    });

    socket.on("error", (error) => {
      console.log(`error: ${error}`);
    });

    socket.on("connect_error", (error) => {
      console.log(`error: ${error}`);
    });

    socket.on("reconnect", (reason) => {
      console.log(`reconnect: ${reason}`);
    });

    socket.on("reconnect_error", (reason) => {
      console.log(`reconnect_error: ${reason}`);
    });

    socket.on("player disconnected", ({ socketId }) => {
      setTriggerDisconnect(socketId);
      GAEventsTracker("speler is gedisconnect");
      console.log(`player disconnected: ${socketId}`);
    });

    socket.on("room pin", (data) => {
      setPhase("lobby");
      setRoomPin(data.roomPin);
      setGameID(data.gameid);
      localStorage.setItem("roomPin", data.roomPin.toUpperCase());
    });

    //player joined
    socket.on("new player", (data) => {
      let added = addPlayer(data);
      if (added === "full" && data.reconnect === false) {
        socket.emit("full", {
          socketId: data.socketId,
          roomPin: data.roomPin,
        });
      }
      if (added === "err2") {
        socket.emit("cant join", {
          socketId: data.socketId,
          roomPin: data.roomPin,
        });
      }
      if (added === "rejoin") {
        GAEventsTracker("speler is gerejoined");
        setTriggerQuestion(data.socketId);
        socket.emit("welcome back", data.socketId);
      }
      if (typeof added === "number") {
        socket.emit("team id receive", {
          socketId: data.socketId,
          groupId: added,
        });
      }
    });

    socket.on("notification", ({ user, chosenAnswer }) => {
      playerAnswering({
        user,
        answer: chosenAnswer,
      });
    });

    socket.on("answer thesis", ({ user, chosenAnswer }) => {
      playerAnswering({
        user,
        answer: chosenAnswer,
      });

      setThesisAnswers({ user, answer: chosenAnswer });
    });

    socket.on(
      "update score",
      ({ user, answer, correct, correctMultiple, decision }) => {
        setUpdateScore({
          decision,
          user,
          answer: answer,
          correct: correct,
          correctMultiple,
        });
      }
    );

    // eslint-disable-next-line
  }, [socket]);

  // trigger disconnect events
  useEffect(() => {
    if (triggerDisconnect) {
      const player = teams.find(
        (player) => player.socketId === triggerDisconnect
      );
      if (player) {
        toast(`Team ${player.group} heeft het spel verlaten :(`);
        if (phase === "lobby") {
          removePlayer(player.group);
          socket.emit("start over", player.socketId);
        } else {
          updatePlayer(player.group, false);
        }
      }
      setTriggerDisconnect(undefined);
    }
  }, [
    triggerDisconnect,
    setTriggerDisconnect,
    teams,
    updatePlayer,
    removePlayer,
    phase,
    socket,
  ]);

  // send question again
  useEffect(() => {
    if (
      (phase === "question" || phase === "thesis") &&
      answerPhase === "show" &&
      triggerQuestion
    ) {
      socket.emit("start answering", {
        question: questions[round],
        round,
        roomPin,
        socketId: triggerQuestion,
        phase: phase,
      });
    }
  }, [triggerQuestion, answerPhase, phase, questions, round, roomPin, socket]);

  useEffect(() => {
    if (updateScore) {
      const info = {
        answer: updateScore.answer,
        group: updateScore.user,
        question: updateScore.decision ? "decision" : questions[round].ordering,
        points: updateScore.correct
          ? updateScore.correctMultiple
            ? updateScore.correctMultiple * questions[round].points2
            : questions[round].points
          : 0,
        gameid: gameid,
        correct: updateScore.correct,
      };

      fetch(process.env.REACT_APP_SERVER + "score/set-player-answer", {
        method: "POST",
        body: JSON.stringify(info),
        headers: { "Content-Type": "application/json" },
      }).catch((error) => {
        console.error("Error:", error);
      });
      setUpdateScore(false);
    }
  }, [updateScore, setUpdateScore, gameid, questions, round]);

  const calc = (x, y) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ];

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  const renderDefaultScreens = () => {
    switch (phase) {
      case "personalCode":
        return <PersonalCode key="personalCode" />;
      case "noCodeForm":
        return <NoCodeForm socket={socket} key="noCodeForm" />;
      case "selectEdition":
        return <EditionPage key="selectEdition" />;
    }
  };

  const renderThemeScreens = () => {
    switch (edition.title) {
      case "2023":
        return renderScreen2023(phase, socket);
    }
  };

  const background =
    phase === "tips" || phase === "explanation" ? `url(${bg2})` : `url(${bg})`;
  return (
    <div onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {socketUnavailble && (
            <UnavailableModal
              socketUnavailble={socketUnavailble}
              setSocketUnavailble={setSocketUnavailble}
            />
          )}
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          {renderDefaultScreens()}
          {renderThemeScreens()}
        </AnimatePresence>
      </AnimateSharedLayout>
    </div>
  );
};

export default HostContainer;
