import { motion } from "framer-motion";
import React from "react";
import { useMainStore } from "../../store";

const IconSchattingsvraag = ({ color, initial }) => {
  const { theme } = useMainStore();

  return (
    <motion.svg
      initial={initial && { fill: theme.colors.purple }}
      animate={initial && { fill: color }}
      style={!initial && { fill: color }}
      id="bold"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1080 1080"
      enable-background="new 0 0 1080 1080"
    >
      <path
        class="st0"
        d="M966.5,0H113.5C50.8,0,0,50.8,0,113.5v852.9c0,62.7,50.8,113.5,113.5,113.5h852.9
		c62.7,0,113.5-50.8,113.5-113.5V113.5C1080,50.8,1029.2,0,966.5,0z M544.4,967.2c-228.5,0-413.3-184.7-413.3-413.2
		s184.7-414.4,413.3-414.4c229.7,0,414.4,185.9,414.4,414.4S774.1,967.2,544.4,967.2z"
      />
      <path
        class="st0"
        d="M544.4,829.8c2.4,0,4.8,0,7.1-0.1C549.1,829.7,546.8,829.8,544.4,829.8L544.4,829.8z"
      />
      <path
        class="st0"
        d="M565.6,829c-4.7,0.4-9.4,0.6-14.1,0.7C556.3,829.6,561,829.3,565.6,829z"
      />
      <path
        class="st0"
        d="M820.3,553.9L820.3,553.9c0,0.3,0,0.7,0,1C820.2,554.6,820.3,554.3,820.3,553.9z"
      />
      <path
        class="st0"
        d="M305.2,690.9c-3.3-5.8-6.5-11.7-9.4-17.7C298.7,679.2,301.9,685.1,305.2,690.9z"
      />
      <path
        class="st0"
        d="M319.6,713.3c-3.9-5.4-7.5-11-11-16.7C312.1,702.3,315.8,707.9,319.6,713.3z"
      />
      <path
        class="st0"
        d="M369.2,766.5c-5.1-4.2-10.1-8.6-14.9-13.2C359.1,757.9,364.1,762.3,369.2,766.5z"
      />
      <path
        class="st0"
        d="M293,667.1c-2.8-6.1-5.3-12.3-7.7-18.7C287.6,654.8,290.2,661,293,667.1z"
      />
      <path
        class="st0"
        d="M277.2,622.6c-1.1-4.4-2.2-8.8-3.1-13.3C275,613.8,276.1,618.2,277.2,622.6z"
      />
      <path
        class="st0"
        d="M283.1,642.1c-2.2-6.4-4.1-12.9-5.8-19.5C278.9,629.2,280.9,635.7,283.1,642.1z"
      />
      <path
        class="st0"
        d="M437.3,808c-4.1-1.8-8.2-3.6-12.2-5.5C429.1,804.4,433.2,806.2,437.3,808z"
      />
      <path
        class="st0"
        d="M449.8,812.9c-4.2-1.6-8.4-3.2-12.5-5C441.4,809.7,445.6,811.4,449.8,812.9z"
      />
      <path
        class="st0"
        d="M456.2,815.2c27.7,9.4,57.4,14.6,88.2,14.6v0C513.6,829.8,483.9,824.6,456.2,815.2z"
      />
      <path
        class="st0"
        d="M323.5,718.6c9.3,12.4,19.6,24,30.8,34.7C343.1,742.6,332.8,731,323.5,718.6z"
      />
      <path
        class="st0"
        d="M407.4,793.1c-11.6-6.7-22.6-14.1-33-22.4C384.8,778.9,395.9,786.4,407.4,793.1z"
      />
      <path
        class="st0"
        d="M268.5,553.9c0,16.6,1.5,32.8,4.3,48.6C270,586.7,268.5,570.5,268.5,553.9L268.5,553.9z"
      />
      <path
        class="st0"
        d="M419.1,799.5c-4-2-7.9-4.2-11.7-6.4C411.3,795.3,415.2,797.4,419.1,799.5z"
      />
      <path
        class="st0"
        d="M565.6,829c142.4-10.9,254.1-130,254.6-274C819.7,698.9,708,818.1,565.6,829z"
      />
      <path
        class="st0"
        d="M820.3,553.9L820.3,553.9c0-150.4-119.3-272-268.7-275.8C700.9,281.9,820.3,403.6,820.3,553.9z"
      />
      <path
        class="st0"
        d="M672.2,553.9L672.2,553.9c0-71.1-56.8-127.9-127.9-127.9C615.4,426,672.2,482.9,672.2,553.9z"
      />
      <path
        class="st0"
        d="M379.7,332.7c3.5-2.6,7.2-5.2,10.8-7.7C386.8,327.5,383.2,330.1,379.7,332.7z"
      />
      <path
        class="st0"
        d="M359.2,349.6c6.5-5.9,13.4-11.6,20.5-16.8C372.6,338,365.7,343.6,359.2,349.6z"
      />
      <path
        class="st0"
        d="M315.8,399.5c7.5-11,15.7-21.4,24.6-31.2C331.5,378.1,323.3,388.5,315.8,399.5z"
      />
      <path
        class="st0"
        d="M268.5,553.9L268.5,553.9c0-57.3,17.5-110.4,47.3-154.4C286,443.5,268.5,496.6,268.5,553.9z"
      />
      <path
        class="st0"
        d="M396,321.4c3.7-2.4,7.5-4.7,11.4-6.9C403.6,316.7,399.8,319,396,321.4z"
      />
      <path
        class="st0"
        d="M425.1,305.2c6-2.9,12.2-5.6,18.4-8.1C437.3,299.6,431.1,302.3,425.1,305.2z"
      />
      <path
        class="st0"
        d="M458.7,648.3c-0.5-0.5-1-0.9-1.5-1.4C457.7,647.4,458.2,647.8,458.7,648.3z"
      />
      <path
        class="st0"
        d="M454.2,644c-0.1-0.1-0.1-0.1-0.2-0.2C454.1,643.9,454.2,644,454.2,644z"
      />
      <path
        class="st0"
        d="M525.1,680.3c-25-3.9-47.8-15.2-66-31.6C477.3,665,500,676.4,525.1,680.3z"
      />
      <path
        class="st0"
        d="M456.4,646.2c-0.3-0.3-0.6-0.6-0.9-0.9C455.8,645.6,456.1,645.9,456.4,646.2z"
      />
      <path
        class="st0"
        d="M354.3,354.1c-3.2,3-6.3,6.2-9.4,9.4C348,360.2,351.1,357.1,354.3,354.1z"
      />
      <path
        class="st0"
        d="M533,681.2c-2.7-0.2-5.3-0.6-7.9-1C527.7,680.7,530.3,681,533,681.2z"
      />
      <path
        class="st0"
        d="M551.5,278.1c-2.4-0.1-4.8-0.1-7.1-0.1c-35.5,0-69.5,6.8-100.8,19.1c-6.3,2.5-12.4,5.2-18.4,8.1
		c-6,2.9-11.9,6-17.7,9.3c-3.9,2.2-7.6,4.5-11.4,6.9c-1.9,1.2-3.7,2.4-5.6,3.6c-3.7,2.5-7.3,5-10.8,7.7
		c-7.1,5.3-13.9,10.9-20.5,16.8c-1.6,1.5-3.3,3-4.9,4.5c-3.2,3-6.3,6.2-9.4,9.4c-1.5,1.6-3,3.2-4.5,4.8
		c-8.9,9.8-17.2,20.2-24.6,31.2c-29.8,44-47.3,97.1-47.3,154.4c0,16.6,1.5,32.8,4.3,48.6c0.4,2.3,0.8,4.5,1.3,6.7
		c0.9,4.5,2,8.9,3.1,13.3c1.7,6.6,3.7,13.1,5.8,19.5c0.7,2.1,1.5,4.3,2.3,6.4c2.3,6.3,4.9,12.5,7.7,18.7c0.9,2,1.9,4.1,2.8,6.1
		c2.9,6,6,11.9,9.4,17.7c1.1,1.9,2.2,3.8,3.4,5.7c3.5,5.7,7.1,11.2,11,16.7c1.3,1.8,2.6,3.6,3.9,5.4c9.3,12.4,19.6,24,30.8,34.7
		c4.8,4.6,9.8,9,14.9,13.2c1.7,1.4,3.4,2.8,5.2,4.2c10.4,8.2,21.5,15.7,33,22.4c3.9,2.2,7.8,4.4,11.7,6.4c2,1,4,2,6,3
		c4,1.9,8.1,3.8,12.2,5.5c4.1,1.8,8.3,3.4,12.5,5c2.1,0.8,4.2,1.5,6.4,2.3c27.7,9.4,57.4,14.6,88.2,14.6c2.4,0,4.8,0,7.1-0.1
		c4.7-0.1,9.5-0.4,14.1-0.7c142.4-10.9,254.1-130,254.6-274c0-0.3,0-0.7,0-1C820.3,403.6,700.9,281.9,551.5,278.1z M672.2,553.9
		c0,69.8-56.8,127.8-127.9,127.8c-3.8,0-7.6-0.2-11.4-0.5c-2.7-0.2-5.3-0.6-7.9-1c0,0,0,0,0,0c-25-3.9-47.8-15.2-66-31.6
		c-0.1-0.1-0.3-0.2-0.4-0.4c-0.5-0.5-1-0.9-1.5-1.4c-0.2-0.2-0.5-0.5-0.8-0.7c-0.3-0.3-0.6-0.6-0.9-0.9c-0.4-0.4-0.9-0.8-1.3-1.3
		c-0.1-0.1-0.1-0.1-0.2-0.2c-23.1-23.2-37.6-55.1-37.6-89.9c0-2.2,0.1-4.4,0.2-6.6c3.5-67.9,60-121.3,127.7-121.3
		C615.4,426,672.2,482.9,672.2,553.9L672.2,553.9z"
      />
      <path d="M672.2,553.9L672.2,553.9c0,69.8-56.8,127.8-127.9,127.8v0C615.4,681.8,672.2,623.8,672.2,553.9z" />
      <path d="M533,681.2c3.8,0.3,7.5,0.5,11.4,0.5v0C540.5,681.8,536.8,681.6,533,681.2z" />
      <path d="M544.4,426c-67.7,0-124.2,53.3-127.7,121.3C420.1,479.4,476.7,426,544.4,426z" />
      <path d="M455.5,645.3c-0.4-0.4-0.9-0.8-1.3-1.3C454.7,644.5,455.1,644.9,455.5,645.3z" />
      <path d="M459.1,648.6c-0.1-0.1-0.3-0.2-0.4-0.4C458.8,648.4,459,648.5,459.1,648.6z" />
      <path d="M454.1,643.9c-23.1-23.2-37.6-55.1-37.6-89.9C416.5,588.8,430.9,620.6,454.1,643.9z" />
      <path d="M525.1,680.3C525.1,680.3,525.1,680.3,525.1,680.3C525.1,680.3,525.1,680.3,525.1,680.3z" />
      <path d="M457.2,646.9c-0.2-0.2-0.5-0.5-0.8-0.7C456.7,646.4,456.9,646.7,457.2,646.9z" />
    </motion.svg>
  );
};

export default IconSchattingsvraag;
