import { motion } from "framer-motion";
import React from "react";
import { useMainStore } from "../../../../store";
import { DottedBorder } from "../../../../static/2023/DottedBorder";
import Money from "../../../../static/2023/Money.png";
import CoinStack from "../../../../static/2023/Coinstack.png";
import { PaperMoney } from "../../../../static/2023/PaperMoney";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";

export const Background = ({
  background,
  big,
  initialBig,
  exit,
  opacity,
  exitOpacity,
}) => {
  const { theme, showMoney } = useMainStore();
  const backgrounds = {
    cloudBackground: [
      {
        size: "120vh",
        transform: "translate(-50%, -60%)",
        background: theme.colors.background,
      },
      {
        size: "100vh",
        transform: "translate(-110%, -65%)",
        background: theme.colors.background,
      },
      {
        size: "120vh",
        transform: "translate(-10%, -70%)",
        background: theme.colors.background,
      },
      {
        size: "60vh",
        transform: "translate(10%, -70%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "90vh",
        transform: "translate(-55%, -55%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "80vh",
        transform: "translate(-120%, -65%)",
        background: theme.colors.backgroundLight,
      },
    ],
    cloudBackgroundBig: [
      {
        size: "120vh",
        transform: "translate(-50%, -60%)",
        background: theme.colors.background,
      },
      {
        size: "100vh",
        transform: "translate(-110%, -65%)",
        background: theme.colors.background,
      },
      {
        size: "120vh",
        transform: "translate(-10%, -70%)",
        background: theme.colors.background,
      },
      {
        size: "60vh",
        transform: "translate(10%, -70%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "90vh",
        transform: "translate(-55%, -55%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "80vh",
        transform: "translate(-120%, -65%)",
        background: theme.colors.backgroundLight,
      },
    ],
    cloudBackgroundBigWhite: [
      {
        size: "200vh",
        transform: "translate(-50%, -70%)",
        background: "#fff",
      },
      {
        size: "80vh",
        transform: "translate(-130%, -65%)",
        background: "#fff",
      },
      {
        size: "200vh",
        transform: "translate(-10%, -80%)",
        background: "#fff",
      },
      {
        size: "75vh",
        transform: "translate(10%, -80%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "90vh",
        transform: "translate(-55%, -65%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "70vh",
        transform: "translate(-120%, -75%)",
        background: theme.colors.backgroundLight,
      },
    ],
  };

  const getAnimationInitial = () => {
    if (big) return { y: 0, scale: 1 };
    else if (opacity) return { opacity: 0 };
  };

  const getAnimation = () => {
    if (big) return { scale: 1.2 };
    else if (opacity) return { opacity: 1 };
  };

  const getExitAnimation = () => {
    if (exit)
      return {
        y: -1000,
        transition: {
          delay: 0.8,
          ease: [0.58, 0.02, 0.45, 1],
          duration: 0.6,
        },
      };
    else if (exitOpacity) {
      return {
        // opacity: 0,
        transition: {
          delay: 0.8,
          ease: [0.58, 0.02, 0.45, 1],
          duration: 0.6,
        },
      };
    }
  };

  return (
    <>
      <motion.div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -2,
          scale: initialBig ? 1.1 : 1,
        }}
        initial={getAnimationInitial()}
        animate={getAnimation()}
        transition={{ duration: opacity ? 1 : 0.5 }}
        exit={getExitAnimation()}
      >
        <DottedBorder />
      </motion.div>
      {showMoney && (
        <>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
              transition: { ...transitionSpring, delay: 0 },
            }}
            transition={{
              ...transitionSpring,
              delay: 1.5,
            }}
            style={{
              position: "absolute",
              top: "50vh",
              right: "15vw",
            }}
            width={250}
            src={Money}
            height={126}
          />
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
              transition: { ...transitionSpring, delay: 0 },
            }}
            transition={{
              ...transitionSpring,
              delay: 1.5,
            }}
            style={{
              position: "absolute",
              top: "46vh",
              right: "19vw",
            }}
            width={185}
            height={240}
            src={CoinStack}
          />
        </>
      )}
    </>
  );
};
