import { useState } from "react";
import Confetti from "react-dom-confetti";
import { useMainStore } from "../../../../../store";
import { Input, TextArea } from "../../atoms/Input";
import { transitionSpring } from "../../pages/hostpages/SelectLesson";

const AnswerOpen = ({
  chosenAnswer,
  setChosenAnswer,
  disable,
  answered,
  confetti,
  config,
}) => {
  const { theme } = useMainStore();
  return (
    <>
      <Confetti active={confetti} config={config} />
      <TextArea
        maxLength="120"
        style={{
          marginTop: "12px",
          border: `1px solid ${theme.colors.purple}`,
        }}
        disabled={disable || answered}
        placeholder="Jouw antwoord"
        layout
        transition={{ ...transitionSpring }}
        initial={{ opacity: 0, transition: { delay: 0.2 } }}
        animate={{ opacity: 1 }}
        name="antwoord"
        value={chosenAnswer}
        onChange={(e) => setChosenAnswer(e.target.value)}
      />
      {chosenAnswer?.length > 0 && (
        <span
          style={{
            color: chosenAnswer?.length == 120 ? "red" : theme.colors.purple,
            fontSize: "0.8rem",
            fontFamily: "proxima-nova",
            fontWeight: 400,
            textAlign: "right",
            width: "100%",
          }}
        >
          {chosenAnswer?.length}/120
        </span>
      )}
    </>
  );
};

export default AnswerOpen;
