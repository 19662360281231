import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { useMainStore } from "../../../../store";

const Modal = styled(motion.div)`
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 300px;
  background-color: #fff;
  max-width: 600px;
  padding: 60px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  opacity: 1;
  @media only screen and (max-width: 600px) {
    padding: 30px 15px;
    max-width: calc(100% - 60px);
  }
  z-index: 2;
`;

const LevelExplanation = () => {
  const { theme } = useMainStore();

  return (
    <>
      <Modal
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.h1
          style={{
            color: theme.colors.purple,
            fontFamily: "proxima-nova",
            marginTop: 0,
          }}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          Uitleg niveau's
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          style={{
            fontSize: "1.5em",
          }}
        >
          <span style={{ fontWeight: "bold", color: theme.colors.yellowTile }}>
            Gele vragen:
          </span>{" "}
          voor groep 6 of groep 7
        </motion.p>
        <motion.p
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          style={{
            fontSize: "1.5em",
          }}
        >
          <span style={{ fontWeight: "bold", color: theme.colors.green }}>
            Groene vragen:
          </span>{" "}
          voor groep 7 of groep 8
        </motion.p>
        <motion.p
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          style={{
            fontSize: "1.5em",
          }}
        >
          <span style={{ fontWeight: "bold", color: theme.colors.blue }}>
            Blauwe vragen:
          </span>{" "}
          voor groep 8 of de onderbouw van het praktijkonderwijs
        </motion.p>
        <motion.p
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          style={{
            fontSize: "1.5em",
          }}
        >
          <span style={{ fontWeight: "bold", color: theme.colors.red }}>
            Rode vragen:
          </span>{" "}
          voor een Plusgroep 8 of een klas uit de onderbouw van het voortgezet
          onderwijs
        </motion.p>
      </Modal>
    </>
  );
};

export default LevelExplanation;
