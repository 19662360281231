import { motion } from "framer-motion";
import logocashquiz from "../../../../static/media/logo-cashquiz.png";

const Logo = ({ initial, exit, small }) => {
  return (
    <>
      <motion.div
        style={{
          marginLeft: "30px",
          width: "100%",
          textAlign: "center",
          position: "absolute",
        }}
      >
        <motion.img
          initial={{ y: initial ? -150 : 0 }}
          animate={{ y: 0 }}
          exit={{ y: exit ? -150 : 0 }}
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 35,
            delay: 1,
          }}
          src={logocashquiz}
          style={{
            maxWidth: small ? "300px" : "400px",
            width: "calc(100% - 22px)",
            marginTop: small ? "30px" : "40px",
          }}
        />
      </motion.div>
    </>
  );
};
export default Logo;
