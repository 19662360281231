import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { AnimatePresence, motion } from "framer-motion";

import CashQuizLogo from "../../../themes/2023/components/atoms/CashQuizLogo";
import { Background } from "../../../themes/2023/components/atoms/Backgrounds";
import { useMainStore } from "../../../store";
import { useGAEventsTracker } from "../../../hooks/useGAEventsTracker";
import { Button } from "../../../themes/2023/components/atoms/Buttons";
import Modal from "../../../themes/2023/components/atoms/Modal";

import logoText1 from "../../../static/media/title.png";
import logoText2 from "../../../static/2023/logotext2.png";
import boards from "../../../static/2023/bordjes.png";
import phones from "../../../static/media/phones.png";
import text1 from "../../../static/2023/Groep 9.png";
import text2 from "../../../static/2023/Groep 10.png";
import arrows1 from "../../../static/2023/arrows1.png";
import arrows2 from "../../../static/2023/arrows2.png";
import goldenArrow from "../../../static/2023/goldenArrow.png";
import whiteArrow from "../../../static/2023/arrowWhite.png";
import BvdkFooter from "../../../themes/2023/components/molecules/BvdkFooter";
import { useHistory } from "react-router-dom";
import { useSocketStore } from "../../../store/socketStore";
import CustomConfetti from "../../../themes/2023/components/molecules/CustomConfetti";
import { MainArrow } from "../../../static/2023/StartArrow";
import { PhonesNew } from "../../../static/2023/PhonesNew";
import { StartTitle } from "./StartTitle";
import Money from "../../../static/2023/Money.png";
import { transitionSpring } from "../mainpages/SelectLesson";

const StartTitles = [
  {
    first: "BANK",
    second: "ZAKEN",
  },
  {
    first: "MONEY",
    second: "SKILLS",
  },
  {
    first: "GELD",
    second: "KENNIS",
  },
];

const ChooseTypeQuiz = () => {
  const { setTypeQuiz, setTypeHost, round, setShowMoney } = useMainStore();

  const socketStore = useSocketStore();

  const GAEventsTracker = useGAEventsTracker("cashquiz");
  const [activegame, setActiveGame] = useState(null);
  const [hover, setHover] = useState(false);
  const history = useHistory();
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const title = StartTitles[time % StartTitles.length];

  const handleClick = () => {
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 3000);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(time + 1);
    }, 1200);
    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  useEffect(() => {
    setShowMoney(true);
  }, []);

  return (
    <>
      <CashQuizLogo initial />
      <BvdkFooter initial />
      <motion.div>
        <Grid
          style={{
            gap: 32,
            height: "100vh",
            textAlign: "center",
          }}
          container
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid
            exit={{ opacity: 0, transition: { delay: 0 } }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 2 }}
            component={motion.div}
            style={{ gap: 128, marginTop: 0 }}
            alignItems="center"
            justify="center"
            container
            direction="column"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                alignItems: "center",
                paddingLeft: "25%",
              }}
            >
              <div
                style={{
                  color: "#183854",
                  weight: 400,
                  fontSize: "32px",
                  fontFamily: "Bungee",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: 200,
                    textAlign: "right",
                    alignSelf: "flex-start",
                  }}
                >
                  <div>TEST</div>
                  <div>JE</div>
                </div>
                <AnimatePresence>
                  {time % 2 && <StartTitle title={title} key={title} />}{" "}
                </AnimatePresence>
              </div>
            </div>

            <Button
              style={{
                width: 400,
                height: 80,
                display: "flex",
                justifyContent: "flex-end",
                fontSize: "1.1rem",
                textAlign: "left",
                position: "relative",
                borderRadius: "71px",

                scale: hover === "plus" ? 1.1 : 1,
              }}
              onClick={() => {
                setTypeQuiz("cashquiz-plus");
                setTypeHost("gastdocent");
                socketStore.setPhase("personalCode");
                history.push("/cashquiz-plus");
              }}
              onMouseEnter={() => setHover("plus")}
              onMouseLeave={() => setHover(false)}
              primary
            >
              <div
                style={{
                  top: 0,
                  left: 0,
                  position: "absolute",
                  fontSize: "44px",
                  fontWeight: 800,
                  fontFamily: "Nunito Sans",
                  display: "flex",
                  alignItems: "center",
                  maxHeight: "100%",
                  height: "100%",
                  width: "100%",
                  justifyContent: "space-around",
                }}
              >
                <div
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                    transform:
                      hover === "plus"
                        ? "translateY(-10px)"
                        : "translateY(0px)",
                  }}
                >
                  <PhonesNew />
                </div>
                Start! <MainArrow />
              </div>
            </Button>
          </Grid>
        </Grid>
      </motion.div>
      {activegame && (
        <Modal
          round={round}
          setActiveGame={setActiveGame}
          activegame={activegame}
        />
      )}
      <Background background={"cloudBackground"} />
    </>
  );
};

export default ChooseTypeQuiz;
