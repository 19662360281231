import { motion } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";
import { useSocketStore } from "../../../../store/socketStore";

const ThesisAnswerBox = ({ answer, number, left, isHighest }) => {
  const [done, setDone] = useState(false);
  const { theme } = useSocketStore();

  return (
    <Wrapper
      initial={{
        opacity: 0,
      }}
      exit={{
        opacity: 0,
        transition: { delay: 0 },
      }}
      animate={{
        opacity: 1,
        backgroundColor: done
          ? isHighest
            ? theme.colors.purple
            : theme.colors.white
          : theme.colors.white,
      }}
      transition={{
        opacity: {
          delay: 0.5,
        },
      }}
      left={left}
    >
      <NumberDial
        initial={false}
        animate={{
          backgroundColor: done
            ? isHighest
              ? theme.colors.purple
              : theme.colors.white
            : theme.colors.white,
        }}
        key={number}
      >
        <motion.div
          onAnimationComplete={() => setDone(true)}
          initial={{ y: 0, background: theme.colors.purple }}
          animate={{
            y: number === 0 ? 0 : -number * 65,
            background: done
              ? !isHighest
                ? theme.colors.white
                : theme.colors.purple
              : theme.colors.purple,
          }}
          transition={{
            duration: 1,
            delay: 1.5,
            ease: "easeOut",
            background: {
              duration: 0.2,
              delay: 0,
            },
          }}
          className="numbers"
        >
          {number === 0 ? (
            <motion.div
              initial={false}
              animate={{
                color: done
                  ? isHighest
                    ? theme.colors.white
                    : theme.colors.purple
                  : theme.colors.white,
              }}
              className="number"
              key={number}
            >
              {number}
            </motion.div>
          ) : (
            //start at 0 and go to number - 1
            [...Array(number + 1).keys()].map((n) => (
              <motion.div
                initial={false}
                animate={{
                  color: done
                    ? isHighest
                      ? theme.colors.white
                      : theme.colors.purple
                    : theme.colors.white,
                }}
                className="number"
                key={n}
              >
                {n}
              </motion.div>
            ))
          )}
        </motion.div>
      </NumberDial>
      <motion.h3
        initial={false}
        animate={{
          color: done
            ? isHighest
              ? theme.colors.white
              : theme.colors.purple
            : theme.colors.purple,
        }}
      >
        {answer}
      </motion.h3>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  background: #fff;
  border-radius: ${(props) => (props.left ? "10px 0 0 10px" : "0 10px 10px 0")};
  padding: 10px;
  border: ${(props) => `1px solid #B2A1C7`};
  border-right: ${(props) => (props.left ? "none" : `1px solid #B2A1C7`)};
  box-shadow: ${(props) => `0 3px 0 #B2A1C7`};
  h3 {
    font-size: 1.5rem;
    margin: 0;
    margin-top: 1rem;
    color: ${(props) => props.theme.colors.purple};
    text-align: center;
  }
`;

const NumberDial = styled(motion.div)`
  width: 75px;
  height: 65px;
  background: ${(props) => props.theme.colors.purple};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 2.5rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.white};
  overflow: hidden;
  position: relative;

  .numbers {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .number {
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      /* margin-top: 10px; */
    }
  }
`;

export default ThesisAnswerBox;
