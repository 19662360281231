import React, { useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import IconRekenvraag from "../../../../../static/media/iconRekenvraag.js";
import IconVerdiepingsvraag from "../../../../../static/media/iconVerdiepingsvraag";
import IconSchattingsvraag from "../../../../../static/media/iconSchattingsvraag";
import IconSnellevraag from "../../../../../static/media/iconSnellevraag";
import IconBeslissingsvraag from "../../../../../static/media/iconBeslissingsvraag";
import IconBeginscore from "../../../../../static/media/iconBeginscore";

import { useMainStore } from "../../../../../store";
import { transitionSpring } from "../../../../../default/pages/mainpages/SelectLesson";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import { useGAEventsTracker } from "../../../../../hooks/useGAEventsTracker.js";

const QuestionIntro = () => {
  const { setPhase, questions, round, decision, setShowCoins, theme } =
    useMainStore();
  const { small, height } = useWindowDimensions();
  const GAEventsTracker = useGAEventsTracker("cashquiz");

  useEffect(() => {
    setShowCoins(false);
  }, [setShowCoins]);

  useEffect(() => {
    GAEventsTracker(`ronde ${round + 1} begonnen`);
    setTimeout(() => {
      if (!decision) {
        if (!questions[round].video) setPhase("question");
        else setPhase("questionVideo");
      } else {
        setPhase("questionDecision");
      }
    }, 3000);
  }, [setPhase, questions, round]);

  const renderIcon = () => {
    switch (questions[round].soort) {
      case "Verdiepingsvraag":
        return (
          <IconVerdiepingsvraag
            initial={false}
            color={theme.colors.white}
            width="100%"
          />
        );
      case "Schattingsvraag":
        return (
          <IconSchattingsvraag
            initial={false}
            color={theme.colors.white}
            width="100%"
          />
        );
      case "Rekenvraag":
        return (
          <IconRekenvraag
            initial={false}
            color={theme.colors.white}
            width="100%"
          />
        );
      case "Snelle vraag":
        return (
          <IconSnellevraag
            initial={false}
            color={theme.colors.white}
            width="100%"
          />
        );
      case "Beslissing":
        return (
          <IconBeslissingsvraag
            initial={false}
            color={theme.colors.white}
            width="100%"
          />
        );
      case "Open vraag":
        return (
          <IconBeginscore
            initial={false}
            color={theme.colors.white}
            width="100%"
          />
        );
      case "Score":
        return (
          <IconBeginscore
            initial={false}
            color={theme.colors.white}
            width="100%"
          />
        );
      default:
        break;
    }
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        layoutId={questions[round].video && "bar"}
        style={{
          zIndex: -1,
          height: height,
          width: "100vw",
          position: "absolute",
          backgroundColor: theme.colors.purple,
        }}
        transition={{ duration: 0.8 }}
      />
      <Grid
        style={{
          height: height,
          width: "100vw",
          position: "absolute",
        }}
        component={motion.div}
        justify="center"
        alignItems="center"
        container
      >
        <motion.div
          transition={{ ...transitionSpring }}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          style={{
            position: "absolute",
            width: small ? "95px" : "65px",
            height: small ? "95px" : "65px",
            backgroundColor: theme.colors.purple,
          }}
        >
          {renderIcon()}
        </motion.div>
      </Grid>
      <Grid
        style={{
          height: height,
          flexDirection: small ? "row" : "row",
        }}
        container
      >
        <Grid item xs={12} sm={6}>
          <Grid
            container
            style={{
              height: small ? height / 2 : height,
              backgroundColor: theme.colors.purpleShadow,
            }}
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "stretch",
              }}
            >
              <motion.h2
                style={{
                  color: "#fff",
                  fontSize: "1.8em",
                  fontFamily: "Brevia",
                }}
                transition={{
                  ...transitionSpring,
                  color: { delay: 0 },
                }}
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
              >
                {decision ? "Beslissingsvraag" : `Vraag ${round + 1}`}
              </motion.h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          {questions && (
            <Grid
              container
              style={{
                height: small ? height / 2 : height,
                backgroundColor: theme.colors.purple,
              }}
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <motion.h2
                  layoutId="type"
                  style={{
                    color: "#fff",
                    fontSize: "1.8em",
                    fontFamily: "Brevia",
                  }}
                  transition={{ ...transitionSpring }}
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  {decision ? "Schattingsvraag" : questions[round].soort}
                </motion.h2>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionIntro;
