export const checkCorrect = (answer, index, correctAnswer, type) => {
  if (type === "waarnietwaar") {
    return answer === correctAnswer;
  } else if (type === "meerkeuze") {
    const string = (index + 1).toString();
    return JSON.parse(correctAnswer).includes(string);
  } else if (type === "eenkeuze") {
    return index + 1 === parseInt(correctAnswer);
  }
};

export const checkClosestAnswer = (playersAnswered, answer) => {
  const playersDistances = playersAnswered.map((player) => {
    return {
      ...player,
      distance: Math.abs(answer - parseInt(player.answer)),
    };
  });

  const distances = playersAnswered.map((player) =>
    Math.abs(answer - parseInt(player.answer))
  );
  const closest = Math.min(...distances);

  function hasDuplicates(arr) {
    return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x));
  }
  let results;

  if (hasDuplicates(distances)) {
    results = playersDistances.map((player, index) =>
      player.distance === closest && index === 0
        ? { ...player, result: true }
        : { ...player, result: false }
    );
  } else {
    results = playersDistances.map((player) =>
      player.distance === closest
        ? { ...player, result: true }
        : { ...player, result: false }
    );
  }

  return results;
};

export const checkAnswerSocket = (
  type,
  chosenAnswer,
  question,
  playersAnswered,
  user
) => {
  if (type === "eenkeuze") {
    return chosenAnswer === parseInt(question.answer - 1) ? true : false;
  } else if (type === "waarnietwaar") {
    return chosenAnswer === question.answer ? true : false;
  } else if (type === "meerkeuze") {
    let correctMultiple = 0;
    chosenAnswer.map((answer) => {
      JSON.parse(question.answer).includes((answer + 1).toString()) &&
        correctMultiple++;
    });
    return correctMultiple;
  } else if (type === "schatting") {
    const estimationAnswers = checkClosestAnswer(
      playersAnswered,
      parseInt(question.answer)
    );

    const you = estimationAnswers.find((answer) => answer.user === user);

    return you ? you.result : false;
  }
};

export function compare(a, b) {
  if (a.group < b.group) {
    return -1;
  }
  if (a.group > b.group) {
    return 1;
  }
  return 0;
}

export function arrayRemove(arr, value) {
  return arr.filter(function (ele) {
    return ele != value;
  });
}
