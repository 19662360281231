import create from "zustand";
import { themes } from "../data/themes";

export const usePlayerStore = create((set) => ({
  theme: themes["2023"],
  phase: "joinroom",
  round: 0,
  roomPin: undefined,
  user: undefined,
  question: undefined,
  startChecking: false,
  stopAnswering: false,
  screenOutro: false,
  lastSeconds: false,
  playersAnswered: [],
  decision: false,
  level: false,
  winner: false,
  setTheme: (data) => set({ theme: data }),
  setUser: (data) => {
    set({
      user: data,
    });
    localStorage.setItem("user", data);
  },
  setScreenOutro: (data) => set({ screenOutro: data }),
  setLastSeconds: (data) => set({ lastSeconds: data }),
  setPlayersAnswered: (data) => set({ playersAnswered: data }),
  setRoomPin: (data) => set({ roomPin: data }),
  setPhase: (data) => set({ phase: data }),
  setRound: (data) => set({ round: data }),
  setQuestion: (data) => set({ question: data }),
  setStartChecking: (data) => set({ startChecking: data }),
  setStopAnswering: (data) => set({ stopAnswering: data }),
  setDecision: (data) => set({ decision: data }),
  setLevel: (data) => set({ level: data }),
  setWinner: (data) => set({ winner: data }),
}));
