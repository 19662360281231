import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { useMainStore } from "../../../../store";

const TeamsHolder = styled(motion.div)`
  background: ${(props) => props.theme.colors.white};
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justifycontent: space-between;
  border-radius: 8px 0 0 8px;
  padding: 15px 15px;
  border: 1px solid #b2a1c7;
  box-shadow: 0 3px 0px #b2a1c7;
  @media only screen and (max-width: 1024px) {
    right: 0;
    padding: 5px;
  }
`;
const Circle = styled(motion.div)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 1.2em;
  @media only screen and (max-width: 1024px) {
    width: 20px;
    height: 20px;
    font-size: 0.7em;
  }
`;

const ProgressTeams = ({ playersAnswered, teams, md }) => {
  const { theme } = useMainStore();

  return (
    <TeamsHolder>
      <Grid
        md={md}
        spacing={md ? 1 : 2}
        container
        alignItems="center"
        justidy="center"
        direction="column"
      >
        {teams.map((team) => {
          const answered = playersAnswered.some((playerA) => {
            return parseInt(playerA.user) === parseInt(team.group);
          });
          return (
            <Grid key={team.group} item>
              <Circle
                animate={{
                  border: `1px solid #B2A1C7`,
                  backgroundColor: !answered ? "#fff" : "#DCF7C5",
                  color: theme.colors.purple,
                  y: !answered ? 0 : -3,
                  boxShadow: !answered
                    ? "0 0px 0px #B2A1C7"
                    : "0 3px 0px #B2A1C7",
                }}
              >
                {team.group}
              </Circle>
            </Grid>
          );
        })}
      </Grid>
    </TeamsHolder>
  );
};

export default ProgressTeams;
