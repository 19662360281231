import { motion } from "framer-motion";
import React from "react";
import { theme } from "../../../../App";
import { useMainStore } from "../../../../store";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";

export default function Switch({ isOn, setIsOn }) {
  const { theme } = useMainStore();

  return (
    <motion.div
      layout
      transition={{ ...transitionSpring }}
      style={{
        width: 70,
        height: 30,
        display: "flex",
        justifyContent: isOn ? "flex-end" : "flex-start",
        borderRadius: 10,
        padding: 5,
        cursor: "pointer",
      }}
      animate={{
        backgroundColor: isOn ? theme.colors.green : theme.colors.greyDarker,
      }}
      onClick={() => setIsOn(!isOn)}
    >
      <motion.div
        style={{
          width: 35,
          height: 30,
          backgroundColor: "#fff",
          borderRadius: 5,
        }}
        layout
        transition={{ ...transitionSpring }}
      />
    </motion.div>
  );
}
