import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import Confetti from "react-dom-confetti";
import { toast } from "react-toastify";

import CashQuizLogo from "../../atoms/CashQuizLogo";
import BackgroundMobile from "../../atoms/BackgroundMobile";
import { usePlayerStore } from "../../../../../store/playerStore";
import { Button } from "../../atoms/Buttons";
import { transitionSpring } from "../../../../../default/pages/mainpages/SelectLesson";
import { checkAnswerSocket } from "../../../../../hooks/helperfunctions";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import { useGAEventsTracker } from "../../../../../hooks/useGAEventsTracker";

const Thesis = ({ socket }) => {
  const {
    question,
    round,
    user,
    roomPin,
    playersAnswered,
    stopAnswering,
    theme,
  } = usePlayerStore();
  const [disable, setDisable] = useState(false);
  let [chosenAnswer, setChosenAnswer] = useState("");
  const [answered, setAnswered] = useState(false);
  const [confetti, setConfetti] = useState(undefined);
  const { height, small } = useWindowDimensions();
  const config = {
    angle: 90,
    spread: "21",
    startVelocity: "23",
    elementCount: "37",
    dragFriction: "0.07",
    duration: "1830",
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: [theme.colors.purple, theme.colors.lightBlue],
  };
  const GAEventsTracker = useGAEventsTracker("cashquiz-plus");
  let answer1, answer2;
  if (
    question.stelling_answers &&
    Array.isArray(JSON.parse(question.stelling_answers))
  ) {
    answer2 = JSON.parse(question.stelling_answers)[1];
    answer1 = JSON.parse(question.stelling_answers)[0];
  }

  useEffect(() => {
    setChosenAnswer();
    setAnswered(false);
    setConfetti(undefined);
  }, [question]);

  const renderAnswerInput = (type) => {
    switch (type) {
      case "thesis":
        return (
          <Grid
            style={{ marginTop: "1em" }}
            container
            spacing={2}
            justify="center"
          >
            <Grid item xs={6}>
              <Button
                noHover
                fullWidth
                transition={{
                  // ...transitionSpring,
                  duration: 0.1,
                  delay: 0.6,
                  y: { delay: 0, duration: 0.1 },
                }}
                initial={{ opacity: 0 }}
                animate={{
                  transition: { delay: 0, duration: 0.1 },
                  opacity: 1,
                  backgroundColor:
                    chosenAnswer === answer1 ? "#E6EAFF" : theme.colors.white,
                  boxShadow:
                    chosenAnswer === answer1
                      ? `0px 0px 0px ${theme.colors.purple}`
                      : `0px 3px 0px ${theme.colors.purple}`,
                  color:
                    chosenAnswer === answer1
                      ? theme.colors.purple
                      : theme.colors.purple,
                  y: chosenAnswer !== answer1 ? -5 : 0,
                  border: `1px solid ${theme.colors.purple}`,
                }}
                exit={{ opacity: 0 }}
                style={{
                  fontSize: "1rem",
                  height: "100%",
                  background: "none",
                  pointerEvents: disable ? "none" : "auto",
                }}
                onClick={() => {
                  if (!answered) {
                    setChosenAnswer(answer1);
                  }
                }}
              >
                {answer1}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                noHover
                fullWidth
                transition={{ ...transitionSpring, delay: 0.7 }}
                initial={{ opacity: 0 }}
                animate={{
                  transition: { delay: 0 },
                  opacity: 1,
                  backgroundColor:
                    chosenAnswer === answer2 ? "#E6EAFF" : theme.colors.white,
                  boxShadow:
                    chosenAnswer === answer2
                      ? `0px 0px 0px ${theme.colors.purple}`
                      : `0px 3px 0px ${theme.colors.purple}`,
                  color:
                    chosenAnswer === answer2
                      ? theme.colors.purple
                      : theme.colors.purple,
                  y: chosenAnswer !== answer2 ? -5 : 0,
                  border: `1px solid ${theme.colors.purple}`,
                }}
                exit={{ opacity: 0 }}
                style={{
                  fontSize: "1rem",
                  height: "100%",
                  background: "none",
                  pointerEvents: disable ? "none" : "auto",
                }}
                disable={chosenAnswer === answer2}
                onClick={() => {
                  if (!answered) {
                    setChosenAnswer(answer2);
                  }
                }}
                primary
              >
                {answer2}
              </Button>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <motion.div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          style={{
            backgroundColor: theme.colors.purpleShadow,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Grid container justify="center">
            <Grid item xs={11} md={6} lg={4}>
              <h3
                style={{
                  fontSize: small ? "1.2rem" : "1.6rem",
                  color: theme.colors.white,
                  textAlign: "center",
                  margin: small ? "0.4em" : "1em",
                }}
              >
                Team {user}
              </h3>
            </Grid>
          </Grid>
        </Grid>
      </motion.div>
      <motion.div exit={{ opacity: 0 }}>
        <Grid
          xs={12}
          style={{
            margin: "0 auto",
            height: height,
            textAlign: "start",
            backgroundColor: theme.colors.background,
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid container justify="center" item xs={11} md={5} lg={4}>
            <Grid>
              <p
                style={{
                  fontFamily: "Brevia",
                  margin: 0,
                  marginBottom: small ? 0 : 20,
                  fontSize: small ? "1.4rem" : "2rem",
                  fontWeight: 400,
                  lineHeight: 1.3,
                  color: theme.colors.purple,
                  textAlign: "center",
                }}
              >
                Antwoord op de stelling
              </p>
              {disable && !answered && (
                <motion.p
                  animate={{
                    fontSize: "1.4rem",
                    lineHeight: "1.4em",
                    color: theme.colors.purple,
                    alignItems: "center",
                    display: "flex",
                  }}
                  transition={{ ...transitionSpring }}
                >
                  {stopAnswering
                    ? "Je hebt geen antwoord verstuurd binnen de tijd! Klopt dit niet? Vernieuw dan deze pagina."
                    : chosenAnswer !== undefined
                    ? "Je hebt al een antwoord verstuurd."
                    : "Je hebt geen antwoord verstuurd, let hier op! Klopt dit niet? Vernieuw dan deze pagina."}
                </motion.p>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                {renderAnswerInput("thesis")}
              </Grid>
            </Grid>
            <Grid item style={{ marginTop: "1em" }} xs={12} md={"auto"}>
              {!disable && (
                <Button
                  disableGreen={answered || chosenAnswer === undefined}
                  onClick={() => {
                    if (!answered) {
                      if (chosenAnswer === "" || chosenAnswer === undefined) {
                        toast("Geen antwoord ingegeven");
                      } else {
                        setAnswered(true);
                        socket.emit("send answer thesis", {
                          user: parseInt(user),
                          roomPin,
                          chosenAnswer,
                        });
                      }
                    }
                  }}
                  primary
                  fullWidth
                >
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 900,
                      color:
                        answered || chosenAnswer === undefined
                          ? "rgba(42, 57, 148, 0.5)"
                          : theme.colors.white,
                    }}
                  >
                    {answered ? "Antwoord verstuurd" : "Bevestig antwoord"}
                  </p>
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </motion.div>
      <CashQuizLogo />
      <BackgroundMobile white />
    </>
  );
};

export default Thesis;
