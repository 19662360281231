import { motion } from "framer-motion";
import React from "react";
import { theme } from "../../../../App";
import styled from "styled-components";
import Grid from "@bit/mui-org.material-ui.grid";
import { Button } from "./Buttons";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";
import { useSocketStore } from "../../../../store/socketStore";
import { useMainStore } from "../../../../store";

const Modal = styled(motion.div)`
  background-color: #fff;
  max-width: calc(100% - 180px);
  padding: 60px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 3px 0px #b2a1c7;
  border: 1px solid #b2a1c7;
  opacity: 1;
  @media only screen and (max-width: 600px) {
    padding: 30px 15px;
    max-width: calc(100% - 60px);
  }
`;

const NotAllPlayersModal = ({
  notAllPlayers,
  setNotAllPlayers,
  currentPhase,
  thesis,
}) => {
  const { setPhase } = useSocketStore();
  const { theme } = useMainStore();

  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
          display: "none",
        }}
        animate={{
          display:
            currentPhase === "answerPhase" && notAllPlayers ? "block" : "none",
          y: currentPhase === "answerPhase" && notAllPlayers ? 0 : "100%",
          opacity: currentPhase === "answerPhase" && notAllPlayers ? "1" : "0",
        }}
        transition={{ duration: 0.5 }}
        // exit={{ opacity: 0 }}
        style={{
          backgroundColor: theme.colors.background,
          height: "100vh",
          width: "100vw",
          position: "absolute",
          zIndex: 100,
          bottom: 0,
          left: 0,
        }}
      >
        <Grid
          style={{ height: "100vh" }}
          container
          alignItems="center"
          justify="center"
        >
          <Modal
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ ...transitionSpring, delay: 0.5 }}
            exit={{ opacity: 0, y: 30 }}
          >
            <motion.h1
              style={{
                color: theme.colors.purple,
                fontSize: "3em",
                fontFamily: "proxima-nova",
                marginTop: 0,
              }}
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7 }}
            >
              Let op!
            </motion.h1>
            <p>Nog niet alle spelers hebben antwoord gegeven!</p>
            <Button
              primary
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              whileHover={{
                scale: 1.05,
                cursor: "pointer",
              }}
              transition={{
                scale: { delay: 0 },
                default: 0.5,
              }}
              onClick={() => {
                thesis ? setPhase("thesisAnswers") : setPhase("revealAnswer");
              }}
            >
              Toch verder gaan
            </Button>
            <Button
              light
              style={{ marginLeft: "10px" }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              whileHover={{
                scale: 1.05,
                cursor: "pointer",
              }}
              transition={{
                scale: { delay: 0 },
                default: 0.5,
              }}
              onClick={() => {
                setNotAllPlayers(false);
              }}
            >
              Terug
            </Button>
          </Modal>
        </Grid>
      </motion.div>
    </>
  );
};

export default NotAllPlayersModal;
