import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import Confetti from "react-dom-confetti";
import { toast } from "react-toastify";

import CashQuizLogo from "../../atoms/CashQuizLogo";
import BackgroundMobile from "../../atoms/BackgroundMobile";
import { usePlayerStore } from "../../../../../store/playerStore";
import AnswerBox from "../../molecules/AnswerBox";
import { Button } from "../../atoms/Buttons";
import { transitionSpring } from "../../../../../default/pages/mainpages/SelectLesson";
import { checkAnswerSocket } from "../../../../../hooks/helperfunctions";
import AnswerEstimation from "../../molecules/answerssocket/AnswerEstimation";
import AnswerMultiple from "../../molecules/answerssocket/AnswerMultiple";
import AnswerOpen from "../../molecules/answerssocket/AnswerOpen";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import bulbyellow from "../../../../../static/media/bulbyellow.svg";
import sad from "../../../../../static/emojis/sad.png";
import anxious from "../../../../../static/emojis/anxious.png";
import { useGAEventsTracker } from "../../../../../hooks/useGAEventsTracker";
import CustomConfetti from "../../molecules/CustomConfetti";

const Game = ({ socket }) => {
  const {
    question,
    round,
    user,
    roomPin,
    startChecking,
    setStartChecking,
    playersAnswered,
    stopAnswering,
    theme,
    setStopAnswering,
    lastSeconds,
    setLastSeconds,
  } = usePlayerStore();
  const [disable, setDisable] = useState(false);
  let [chosenAnswer, setChosenAnswer] = useState(
    question.type === "meerkeuze" ? [] : ""
  );
  const [answered, setAnswered] = useState(false);
  const [confetti, setConfetti] = useState(undefined);
  const { height, small } = useWindowDimensions();
  const config = {
    angle: 90,
    spread: "21",
    startVelocity: "23",
    elementCount: "37",
    dragFriction: "0.07",
    duration: "1830",
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: [theme.colors.purple, theme.colors.lightBlue],
  };
  const GAEventsTracker = useGAEventsTracker("cashquiz-plus");

  useEffect(() => {
    setStopAnswering(false);
  }, []);

  useEffect(() => {
    if (question.type === "meerkeuze") {
      setChosenAnswer([]);
    } else {
      setChosenAnswer();
    }
    setAnswered(false);
    setConfetti(undefined);
  }, [question]);

  useEffect(() => {
    fetch(process.env.REACT_APP_SERVER + "score/get-player-answer", {
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        group: user,
        gameid: localStorage.getItem("gameid"),
        question: question.ordering,
      }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.length > 0) {
          if (question.type === "schatting" || question.type === "eenkeuze") {
            setChosenAnswer(parseInt(result[0].answer));
            setAnswered(true);
          } else {
            let answerarr = result[0].answer.split(",");
            let newarray = answerarr.map((answer) => {
              return parseInt(answer);
            });
            setChosenAnswer(newarray);
            setAnswered(true);
          }
        } else {
          setChosenAnswer(undefined);
          setAnswered(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [question]);

  const renderAnswerInput = () => {
    switch (question.type) {
      case "eenkeuze":
        return JSON.parse(question.answers).map((answerOption, index) => (
          <Grid key={answerOption} style={{ marginbottom: "1em" }} item>
            <AnswerBox
              disable={disable}
              setAnswered={setAnswered}
              config={config}
              confetti={confetti}
              answered={answered}
              chosenAnswer={chosenAnswer}
              setChosenAnswer={setChosenAnswer}
              index={index}
              answerOption={answerOption}
              question={question}
            />
          </Grid>
        ));
      case "meerkeuze":
        return (
          <AnswerMultiple
            disable={disable}
            setAnswered={setAnswered}
            question={question}
            config={config}
            confetti={confetti}
            answered={answered}
            chosenAnswer={chosenAnswer}
            setChosenAnswer={setChosenAnswer}
          />
        );
      case "schatting":
        return (
          <Grid
            style={{ marginTop: "1em" }}
            container
            spacing={2}
            justify="center"
          >
            <Grid item xs={11} style={{ maxWidth: "380px" }}>
              <AnswerEstimation
                disable={answered}
                confetti={confetti}
                setChosenAnswer={setChosenAnswer}
                chosenAnswer={chosenAnswer}
                question={question}
                round={round}
                small={small}
              />
            </Grid>
          </Grid>
        );
      case "open":
        return (
          <AnswerOpen
            disable={disable}
            setAnswered={setAnswered}
            question={question}
            config={config}
            confetti={confetti}
            answered={answered}
            chosenAnswer={chosenAnswer}
            setChosenAnswer={setChosenAnswer}
          />
        );
      case "waarnietwaar":
        return (
          <Grid container spacing={2} justify="center">
            <Grid item xs={6}>
              <CustomConfetti isActive={confetti} />
              <Button
                noHover
                fullWidth
                transition={{
                  ...transitionSpring,
                  delay: 0.6,
                  y: { delay: 0 },
                }}
                initial={{ opacity: 0 }}
                animate={{
                  transition: { delay: 0 },
                  opacity: 1,
                  backgroundColor:
                    chosenAnswer === "Waar" ? "#E6EAFF" : theme.colors.white,
                  boxShadow:
                    chosenAnswer === "Waar"
                      ? `0px 0px 0px ${theme.colors.purple}`
                      : `0px 3px 0px ${theme.colors.purple}`,
                  color:
                    chosenAnswer === "Waar"
                      ? theme.colors.purple
                      : theme.colors.purple,
                  y: chosenAnswer !== "Waar" ? -3 : 0,
                  border: `1px solid ${theme.colors.purple}`,
                }}
                exit={{ opacity: 0 }}
                style={{
                  background: "none",
                  pointerEvents: disable ? "none" : "auto",
                }}
                wrong={confetti === false && chosenAnswer === "Waar"}
                right={confetti && chosenAnswer === "Waar"}
                onClick={() => {
                  !answered && setChosenAnswer("Waar");
                }}
              >
                Waar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Confetti
                active={confetti && chosenAnswer === "Niet waar"}
                config={config}
              />
              <Button
                noHover
                fullWidth
                transition={{ ...transitionSpring, delay: 0.7 }}
                initial={{ opacity: 0 }}
                animate={{
                  transition: { delay: 0 },
                  opacity: 1,
                  backgroundColor:
                    chosenAnswer === "Niet waar"
                      ? "#E6EAFF"
                      : theme.colors.white,
                  boxShadow:
                    chosenAnswer === "Niet waar"
                      ? `0px 0px 0px ${theme.colors.purple}`
                      : `0px 3px 0px ${theme.colors.purple}`,
                  color:
                    chosenAnswer === "Niet waar"
                      ? theme.colors.purple
                      : theme.colors.purple,
                  border: `1px solid ${theme.colors.purple}`,
                  y: chosenAnswer !== "Niet waar" ? -3 : 0,
                }}
                exit={{ opacity: 0 }}
                style={{
                  background: "none",
                  pointerEvents: disable ? "none" : "auto",
                }}
                disable={chosenAnswer === "Niet waar"}
                wrong={confetti === false && chosenAnswer === "Niet waar"}
                right={confetti && chosenAnswer === "Niet waar"}
                onClick={() => {
                  !answered && setChosenAnswer("Niet waar");
                }}
                primary
              >
                Niet waar
              </Button>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (startChecking || stopAnswering) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [startChecking, stopAnswering, setDisable, chosenAnswer, disable]);

  useEffect(() => {
    if (startChecking) {
      if (!answered) {
        setConfetti(false);
        socket.emit("wrong answer", {
          roomPin,
          user,
          answer: "",
        });
      } else if (
        checkAnswerSocket(
          question.type,
          chosenAnswer,
          question,
          playersAnswered,
          user
        )
      ) {
        setConfetti(true);
        if (question.type === "schatting") {
          socket.emit("correct answer", {
            roomPin,
            user,
            answer: chosenAnswer,
          });
        }
      } else if (
        !checkAnswerSocket(
          question.type,
          chosenAnswer,
          question,
          playersAnswered,
          user
        )
      ) {
        setConfetti(false);
        if (question.type === "schatting") {
          socket.emit("wrong answer", {
            roomPin,
            user,
            answer: chosenAnswer,
          });
        }
      }
      setStartChecking(false);
    }
  }, [
    startChecking,
    playersAnswered,
    roomPin,
    setStartChecking,
    answered,
    chosenAnswer,
    question,
    socket,
    user,
  ]);

  return (
    <>
      <motion.div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          style={{
            backgroundColor: theme.colors.purpleShadow,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Grid container justify="center">
            <Grid item xs={11} md={6} lg={4}>
              <h3
                style={{
                  fontSize: small ? "1.2rem" : "1.6rem",
                  color: theme.colors.white,
                  textAlign: "center",
                  margin: small ? "0.4em" : "1em",
                }}
              >
                Team {user}
              </h3>
            </Grid>
          </Grid>
        </Grid>
      </motion.div>
      <motion.div exit={{ opacity: 0 }}>
        <Grid
          xs={12}
          style={{
            margin: "0 auto",
            height: height,
            textAlign: "start",
            backgroundColor: theme.colors.background,
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid
            component={motion.div}
            layout
            container
            justify="center"
            item
            xs={11}
            md={5}
            lg={4}
          >
            <Grid component={motion.div} layout>
              {stopAnswering && !answered ? null : (
                <>
                  <motion.p
                    layout
                    style={{
                      fontFamily: "Brevia",
                      margin: 0,
                      marginBottom: small ? 0 : 20,
                      fontSize: small ? "1.4rem" : "2rem",
                      fontWeight: 400,
                      lineHeight: 1.3,
                      color: theme.colors.purple,
                      textAlign: "center",
                    }}
                  >
                    Antwoord op <b>vraag {round + 1}</b>
                  </motion.p>
                  {question.possible_answers && (
                    <motion.p
                      animate={{
                        fontSize: small ? "1rem" : "1.4rem",
                        lineHeight: "1em",
                        color: theme.colors.purple,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      transition={{ ...transitionSpring }}
                    >
                      <div
                        style={{
                          borderRadius: "50%",
                          backgroundColor:
                            question.type === "schatting"
                              ? theme.colors.white
                              : theme.colors.purple,
                          width: 25,
                          height: 25,
                          marginRight: 7,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            color:
                              question.type === "schatting"
                                ? theme.colors.brown
                                : theme.colors.white,
                            fontSize: "0.8em",
                            fontWeight: 900,
                          }}
                        >
                          !
                        </span>
                      </div>{" "}
                      {parseInt(question.possible_answers) ===
                      JSON.parse(question.answers).length
                        ? "Meerdere"
                        : question.possible_answers}{" "}
                      antwoorden mogelijk
                    </motion.p>
                  )}
                  {question.tip && (
                    <motion.p
                      animate={{
                        fontSize: small ? "1rem" : "1.4rem",
                        lineHeight: "1.4em",
                        margin: small ? "0" : "inherit",
                        color: theme.colors.purple,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        textAlign: "center",
                      }}
                      transition={{ ...transitionSpring }}
                    >
                      <img
                        width={40}
                        height={40}
                        style={{ marginRight: 10 }}
                        src={bulbyellow}
                        alt="tipsicon"
                      />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: question.tip,
                        }}
                      />
                    </motion.p>
                  )}
                </>
              )}
              {disable && !answered && (
                <motion.p
                  style={{
                    fontSize: "1.4rem",
                    lineHeight: "1.4em",
                    color: theme.colors.purple,
                    textAlign: "center",
                    margin: "0",
                    marginTop: "16px",
                  }}
                  transition={{ ...transitionSpring }}
                >
                  {stopAnswering
                    ? "Je hebt geen antwoord verstuurd binnen de tijd! Klopt dit niet, vernieuw dan dit venster."
                    : chosenAnswer !== undefined
                    ? "Je hebt al een antwoord verstuurd. Klopt dit niet, vernieuw dan dit venster."
                    : "Je hebt geen antwoord verstuurd, let hier op! Klopt dit niet, vernieuw dan dit venster."}
                  <motion.img
                    width={32}
                    src={sad}
                    style={{ display: "inline-block", marginLeft: 8 }}
                  />
                </motion.p>
              )}
            </Grid>
            {stopAnswering && !answered ? null : (
              <>
                <Grid component={motion.div} layout item xs={12}>
                  <Grid
                    component={motion.div}
                    style={{
                      marginTop: "1em",
                    }}
                    container
                    justify="center"
                  >
                    {renderAnswerInput()}
                  </Grid>
                </Grid>
                <Grid item style={{ marginTop: "1em" }} xs={12} md={"auto"}>
                  {!disable && (
                    <Grid container justify="center">
                      <Button
                        animate={{
                          rotate:
                            chosenAnswer !== undefined && !answered
                              ? lastSeconds
                                ? [0, 20, -20, 0]
                                : [0, 5, -5, 0, 0, 0]
                              : 0,
                        }}
                        transition={{
                          duration: 0.2,
                          rotate: {
                            repeat:
                              chosenAnswer !== undefined && !answered
                                ? Infinity
                                : 0,
                            repeatType: "reverse",
                            duration: lastSeconds ? 0.3 : 0.6,
                          },
                        }}
                        disableGreen={answered || chosenAnswer === undefined}
                        questionType={question.type}
                        onClick={() => {
                          if (!answered) {
                            if (
                              chosenAnswer === "" ||
                              chosenAnswer === undefined
                            ) {
                              toast("Geen antwoord ingegeven");
                            } else {
                              GAEventsTracker(
                                "speler heeft antwoord verstuurd"
                              );
                              setAnswered(true);
                              if (
                                checkAnswerSocket(
                                  question.type,
                                  chosenAnswer,
                                  question,
                                  playersAnswered,
                                  user
                                )
                              ) {
                                socket.emit("correct answer", {
                                  roomPin,
                                  user,
                                  answer: chosenAnswer,
                                  correctMultiple:
                                    question.type === "meerkeuze" &&
                                    checkAnswerSocket(
                                      question.type,
                                      chosenAnswer,
                                      question,
                                      user
                                    ),
                                });
                              } else if (
                                !checkAnswerSocket(
                                  question.type,
                                  chosenAnswer,
                                  question,
                                  playersAnswered,
                                  user
                                )
                              ) {
                                socket.emit("wrong answer", {
                                  roomPin,
                                  user,
                                  answer: chosenAnswer,
                                });
                              }
                              setLastSeconds(false);
                              socket.emit("send answer", {
                                user: parseInt(user),
                                roomPin,
                                chosenAnswer,
                              });
                            }
                          }
                        }}
                        primary
                      >
                        {lastSeconds &&
                          chosenAnswer !== undefined &&
                          !answered && (
                            <motion.img
                              src={anxious}
                              width={32}
                              animate={{
                                scale:
                                  lastSeconds &&
                                  chosenAnswer !== undefined &&
                                  !answered
                                    ? [1, 1.2, 1, 1.2, 1, 1.2, 1]
                                    : 0,
                              }}
                              transition={{
                                scale: {
                                  repeat:
                                    lastSeconds &&
                                    chosenAnswer !== undefined &&
                                    !answered
                                      ? Infinity
                                      : 0,
                                  repeatType: "reverse",
                                  duration: 1.5,
                                },
                              }}
                              style={{
                                position: "absolute",
                                left: -10,
                                top: -15,
                                rotate: -15,
                              }}
                            />
                          )}
                        <p
                          style={{
                            margin: 0,
                            fontWeight: 900,
                            color:
                              answered || chosenAnswer === undefined
                                ? "rgba(42, 57, 148, 0.5)"
                                : theme.colors.white,
                          }}
                        >
                          {answered
                            ? "Antwoord verstuurd"
                            : "Bevestig antwoord"}
                        </p>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </motion.div>
      <CashQuizLogo />
      <BackgroundMobile white />
    </>
  );
};

export default Game;
