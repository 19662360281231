import React, { useState } from "react";
import { usePlayerStore } from "../../../../../store/playerStore";
import EstimationInput from "./EstimationInput";

const AnswerEstimationDecision = ({ setChosenAnswer, confetti }) => {
  const [input, setInput] = useState();
  const { decision, level } = usePlayerStore();
  return (
    <div>
      <EstimationInput
        confetti={confetti}
        setChosenAnswer={setChosenAnswer}
        unit={JSON.parse(level.params).final_answer_unit}
        input={input}
        setInput={setInput}
      />
    </div>
  );
};

export default AnswerEstimationDecision;
