import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import AnswerBox from "../AnswerBox";

const AnswerMultiple = ({
  question,
  config,
  confetti,
  setChosenAnswer,
  chosenAnswer,
  answered,
  setAnswered,
  disable,
}) => {
  return JSON.parse(question.answers).map((answerOption, index) => (
    <Grid key={answerOption} item>
      <AnswerBox
        disable={disable}
        question={question}
        config={config}
        setAnswered={setAnswered}
        confetti={confetti}
        answered={answered}
        chosenAnswer={chosenAnswer}
        setChosenAnswer={setChosenAnswer}
        index={index}
        answerOption={answerOption}
      />
    </Grid>
  ));
};

export default AnswerMultiple;
