import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion, useAnimation } from "framer-motion";

import { PanelQuestion } from "../../molecules/PanelQuestion";
import TimerBar from "../../atoms/TimerBar";
import { NavigationNext } from "../../atoms/Buttons";
import { transitionSpring } from "./SelectLesson";
import Topbar from "../../molecules/Topbar";
import useInterval from "../../../../../hooks/useInterval";
import { useSocketStore } from "../../../../../store/socketStore";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import ProgressTeams from "../../molecules/ProgressTeams";

const backgroundVariants = {
  peak: {
    x: -100,
    transition: { duration: 1, type: "spring" },
  },
  small: {
    transition: { duration: 1, type: "spring" },
  },
  exit: {
    x: "-100%",
    transition: { ...transitionSpring, delay: 0.5 },
  },
};

const QuestionDecision = ({ socket }) => {
  const {
    setPhase,
    level,
    roomPin,
    duplicates,
    teams,
    theme,
    playersAnswered,
  } = useSocketStore();
  const { small } = useWindowDimensions();
  const answersPanelAnimation = useAnimation();
  const questionPanelAnimation = useAnimation();
  const questionAnimation = useAnimation();
  const backgroundAnimation = useAnimation();
  const CloudBackgroundDescriptionAnimation = useAnimation();
  const [answerPhase, setAnswerPhase] = useState("show");
  const [timerIsSet, setTimerIsSet] = useState(false);
  let [timer, startTimer] = useState(false);

  const variants = {
    full: {
      transition: { duration: 1, type: "spring" },
      width: "100%",
      paddingLeft: "0",
      x: 0,
    },
    hide: {
      opacity: 0,
    },
  };

  useEffect(() => {
    let timeout;
    if (!answerPhase) {
      questionPanelAnimation.start("peak");
      timeout = setTimeout(() => {
        backgroundAnimation.start("peak");
      }, 2000);
    } else if (answerPhase === "show") {
      questionAnimation.start("show");
      answersPanelAnimation.start("full");
      questionPanelAnimation.start("small");
      //send to devices
      //map through duplicates
      const socketIds = duplicates.map((dup, i) => {
        return teams.filter((team) =>
          team.group == dup.group
            ? (duplicates[i].socketId = team.socketId)
            : ""
        );
      });

      socket.emit("start decision", {
        socketIds,
        level,
      });
      if (!timerIsSet) {
        startTimer(60);
        setTimerIsSet(true);
      }
    } else if (answerPhase === "exit") {
      questionPanelAnimation.start("hide");
      answersPanelAnimation.start("hide");
    }
    return () => clearTimeout(timeout);
  }, [
    answerPhase,
    answersPanelAnimation,
    questionAnimation,
    questionPanelAnimation,
    CloudBackgroundDescriptionAnimation,
    backgroundAnimation,
    level,
    roomPin,
    socket,
    duplicates,
    teams,
  ]);

  useInterval(() => {
    if (timer !== 0) {
      startTimer(timer - 1);
    }
  }, 1000);

  return (
    <>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: "0%" }}
        transition={{
          delay: 0.5,
          ...transitionSpring,
        }}
        style={{
          zIndex: -1,
          width: "120vw",
          height: "100vh",
          position: "absolute",
          backgroundColor: theme.colors.background,
        }}
      />
      <motion.div
        style={{
          zIndex: 0,
          position: "absolute",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0)",
        }}
        animate={CloudBackgroundDescriptionAnimation}
        variants={backgroundVariants}
        exit="exit"
      />
      <Topbar
        question="Beslissingsvraag"
        show={false}
        questionValue={"10"}
        questionSoort="Beslissingsvraag"
      />
      <Grid container alignItems="center" justify="center">
        <Grid item xs={11} lg={8} style={{ position: "relative" }}>
          <PanelQuestion
            variants={variants}
            animate={answersPanelAnimation}
            style={{
              x: 250,
              height: "calc(100vh - 155px)",
            }}
          >
            {answerPhase === "show" && (
              <Grid
                container
                style={{ height: "100%" }}
                alignItems={"center"}
                justify="center"
              >
                <Grid
                  item
                  style={{
                    width: "100%",
                    marginLeft: "30px",
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                    exit={{ opacity: 0, transition: { delay: 0 } }}
                  >
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: JSON.parse(level.params).final_question,
                      }}
                      style={{
                        lineHeight: "1.2em",
                        marginTop: "0",
                      }}
                    />
                  </motion.div>
                </Grid>
              </Grid>
            )}
          </PanelQuestion>
        </Grid>
        {answerPhase === "show" && (
          <>
            <Grid
              container
              alignItems="center"
              justify="center"
              style={{
                padding: "30px",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
              }}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  spacing={4}
                >
                  <Grid item xs={4}>
                    <TimerBar
                      timer={timer}
                      nextPhase={() => setPhase("submitAnswers")}
                    />
                  </Grid>
                  <Grid item>
                    <NavigationNext
                      initial={{ opacity: 0, display: "none" }}
                      animate={{ opacity: 1, display: "block" }}
                      whileHover={{
                        scale: 1.1,
                        cursor: "pointer",
                      }}
                      transition={{
                        scale: { delay: 0 },
                        opacity: { delay: 2 },
                      }}
                      text="Bekijk het antwoord"
                      nextPhase={() => setPhase("questionDecisionReveal")}
                      bottom={60}
                      flexPosition
                      small={small}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <motion.div
        initial={{ x: 75, opacity: 1 }}
        animate={{ x: 0 }}
        transition={{ delay: 2 }}
        exit={{ x: 75, transition: { delay: 0 } }}
        style={{
          height: "calc(100vh - 60px)",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ProgressTeams teams={duplicates} playersAnswered={playersAnswered} />
      </motion.div>
    </>
  );
};

export default QuestionDecision;
