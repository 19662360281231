import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import Confetti from "react-confetti";

import { backgrounds } from "../../../../../data/backgrounds";
import { useMainStore } from "../../../../../store";
import { Background } from "../../atoms/Backgrounds";
import { TextLink } from "../../atoms/TextLink";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import { transitionSlowSpring } from "../../atoms/ScoreBar";
import { transitionSpring } from "../../../../../default/pages/mainpages/SelectLesson";
import { useGAEventsTracker } from "../../../../../hooks/useGAEventsTracker";

const FinalPage = () => {
  const { setPhase, setShowCoins, theme } = useMainStore();
  const { width, height } = useWindowDimensions();
  const GAEventsTracker = useGAEventsTracker("cashquiz");

  useEffect(() => {
    GAEventsTracker("finale pagina bezocht");
    setShowCoins(true);
  }, []);

  return (
    <>
      <Confetti
        style={{ zIndex: -1 }}
        gravity={0.04}
        width={width}
        height={height}
        colors={[
          theme.colors.purple,
          theme.colors.yellow,
          theme.colors.yellowShadow,
          theme.colors.yellowLighter,
          theme.colors.purpleShadow,
          theme.colors.purpleDarker,
        ]}
      />
      <Grid
        container
        style={{ height: "100vh", textAlign: "start" }}
        alignItems="center"
        justify="center"
        exit={{ opacity: 0 }}
        component={motion.div}
      >
        <Grid item>
          <motion.div
            transition={transitionSlowSpring}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            style={{
              backgroundColor: theme.colors.white,
              padding: "2em",
              borderRadius: 15,
            }}
          >
            <motion.h1
              transition={{ transitionSpring, delay: 0.5 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              style={{
                fontFamily: "Brevia",
                color: theme.colors.purple,
                fontSize: "4em",
                marginBottom: 0,
              }}
            >
              Gefeliciteerd geldhelden!
            </motion.h1>
            <motion.h2
              transition={{ transitionSpring, delay: 0.7 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              style={{ color: theme.colors.purple }}
            >
              Doet jouw klas mee aan de Sing-a-Song <br /> challenge met Yes-R?
            </motion.h2>
            <motion.p
              transition={{ transitionSpring, delay: 0.9 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              style={{ color: theme.colors.purple, fontSize: "1.5em" }}
            >
              - Kijk op bankvoordeklas.nl
            </motion.p>
            <motion.p
              transition={{ transitionSpring, delay: 0.9 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              style={{ color: theme.colors.purple, fontSize: "1.5em" }}
            >
              - Stuur je filmpje in
            </motion.p>
            <motion.p
              transition={{ transitionSpring, delay: 0.9 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              style={{ color: theme.colors.purple, fontSize: "1.5em" }}
            >
              - Maak kans op een meet & greet met Yes-R!
            </motion.p>
          </motion.div>
        </Grid>
      </Grid>
      <TextLink
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        whileHover={{
          scale: 1.1,
          cursor: "pointer",
        }}
        transition={{
          scale: { delay: 0 },
          default: 0.5,
        }}
        onClick={() => setPhase("end")}
        navigationPrev
        bottom={"50px"}
        left={"50px"}
        style={{
          position: "absolute",
        }}
      >
        terug
      </TextLink>
      <Background initialBig background={"cloudBackgroundBig"} />
    </>
  );
};

export default FinalPage;
