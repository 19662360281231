import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Grid from "@bit/mui-org.material-ui.grid";
import styled from "styled-components";

import { Button, TinyButton } from "../atoms/Buttons";
import arrow from "../../../../static/media/arrow.svg";
import { useMainStore } from "../../../../store";
import { useSocketStore } from "../../../../store/socketStore";
import { useGAEventsTracker } from "../../../../hooks/useGAEventsTracker";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";

const Lesson = ({
  index,
  lecture,
  homework,
  setLessonSocket,
  setPhaseSocket,
}) => {
  const { setPhase, setLesson, setTeams, theme } = useMainStore();
  const GAEventsTracker = useGAEventsTracker("cashquiz");

  const selectLesson = (gameid) => {
    GAEventsTracker("les selecteren");
    setLesson(lecture);
    setLessonSocket(lecture);
    if (homework) {
      let filledArray = new Array(1).fill(null).map(() => ({ score: 10 }));
      setTeams(filledArray);
    }

    setPhase(homework ? "explanation" : "selectTeams");
    setPhaseSocket("lobby");

    // setLesson(lecture);
    // setPhase("selectLevel");
  };

  var char_count = lecture.studierichting.length + lecture.teacher.length;
  var group_length = 50 - lecture.teacher.length;

  return (
    <motion.div
      style={{
        backgroundColor: "#fff",
        padding: "0.5em",
        borderRadius: "5px",
        marginTop: "1em",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, y: 0, transition: { delay: index * 0.07 + 0.2 } }}
      exit={{ opacity: 0 }}
      transition={{ delay: index * 0.07 }}
    >
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <motion.span
            style={{
              fontSize: "1.2em",
              marginRight: "2em",
              color: theme.colors.purple,
            }}
          >
            <>
              <strong>
                <span style={{ opacity: 1 }}>{`${lecture.jaar}`}</span>
              </strong>
            </>
            {lecture.studierichting && (
              <>
                <span
                  style={{
                    fontSize: "0.8em",
                    color: theme.colors.orange,
                  }}
                >
                  {"  "}•{"  "}
                </span>
                <span style={{ opacity: 1 }}>
                  {char_count > 55
                    ? lecture.studierichting.substr(0, group_length) + "..."
                    : `${lecture.studierichting}`}
                </span>
              </>
            )}
            {
              <>
                <span
                  style={{
                    fontSize: "0.8em",
                    color: theme.colors.orange,
                  }}
                >
                  {"  "}•{"  "}
                </span>
                <span style={{ opacity: 1 }}>{`${lecture.teacher}`}</span>
              </>
            }
          </motion.span>
        </Grid>
        <Grid item>
          <Button
            onClick={() => selectLesson(lecture.id)}
            fontSize="1em"
            padding="10px 15px"
            primary
          >
            Selecteer
          </Button>
        </Grid>
      </Grid>
    </motion.div>
  );
};

const Accordion = ({
  i,
  expanded,
  setExpanded,
  school,
  homework,
  setPhaseSocket,
  setLessonSocket,
  schools,
}) => {
  const { setPhase, setLesson, setTeams } = useMainStore();
  const isOpen = i === expanded;
  const { theme } = useMainStore();
  const GAEventsTracker = useGAEventsTracker("cashquiz");

  const selectLesson = (lectid) => {
    GAEventsTracker("les selecteren");
    setLesson(school.lecture[0]);
    setLessonSocket(school.lecture[0]);
    if (homework) {
      let filledArray = new Array(1).fill(null).map(() => ({ score: 10 }));
      setTeams(filledArray);
    }

    setPhase(homework ? "explanation" : "selectTeams");
    setPhaseSocket("lobby");
  };

  var char_count =
    school.lecture[0].studierichting.length + school.lecture[0].teacher.length;
  var group_length = 55 - school.lecture[0].teacher.length;

  return (
    <>
      <motion.div
        style={{
          padding: "1em",
          borderRadius: "8px",

          height: "100%",
          marginBottom: i + 1 === schools.length ? 0 : 10,
          backgroundColor:
            school.lecture[0].school_name === "Testschool"
              ? "rgba(255,255,255,0.1)"
              : theme.colors.white,
          border:
            school.lecture[0].school_name === "Testschool"
              ? "1px solid #B2A1C7"
              : "0 none",
        }}
        layout
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <motion.h2
              transition={{
                opacity: { delay: 0.2 },
                ...transitionSpring,
              }}
              style={{
                textAlign: "left",
                margin:
                  school.lecture[0].school_name === "Testschool"
                    ? "10px 0px 10px 0px"
                    : "0 0px 10px 0px",
                color:
                  school.lecture[0].school_name === "Testschool"
                    ? theme.colors.white
                    : theme.colors.purple,
                fontSize: "1.5em",
              }}
              layout
            >
              {school.lecture[0].school_name === "Testschool"
                ? "Oefen de Cash Quiz"
                : school.school_name}
            </motion.h2>
            {school.lecture.length > 1 ? (
              ""
            ) : (
              <motion.span
                style={{
                  float: "left",
                  fontSize: "1.2em",
                  marginRight: "2em",
                  color: theme.colors.purple,
                }}
              >
                {school.lecture[0].school_name !== "Testschool" && (
                  <>
                    <>
                      <span style={{ opacity: 0.4 }}>
                        {`${school.lecture[0].jaar}`}
                      </span>
                    </>
                    {school.lecture[0].studierichting && (
                      <>
                        <span
                          style={{
                            fontSize: "0.8em",
                            color: theme.colors.orange,
                          }}
                        >
                          {"  "}•{"  "}
                        </span>
                        <span style={{ opacity: 0.4 }}>
                          {char_count > 55
                            ? school.lecture[0].studierichting.substr(
                                0,
                                group_length
                              ) + "..."
                            : `${school.lecture[0].studierichting}`}
                        </span>
                      </>
                    )}
                    {
                      <>
                        <span
                          style={{
                            fontSize: "0.8em",
                            color: theme.colors.orange,
                          }}
                        >
                          {"  "}•{"  "}
                        </span>
                        <span style={{ opacity: 0.4 }}>
                          {`${school.lecture[0].teacher}`}
                        </span>
                      </>
                    }
                  </>
                )}
              </motion.span>
            )}
          </Grid>
          {school.lecture.length > 1 ? (
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TinyButton
                transition={{
                  opacity: { delay: 0.2 },
                  ...transitionSpring,
                }}
                onClick={() => setExpanded(isOpen ? false : i)}
                layout
              >
                <p>Bekijk groepen</p>
                <motion.img
                  animate={{ rotateZ: isOpen ? 180 : 0 }}
                  width={15}
                  height={15}
                  style={{
                    marginRight: "5px",
                  }}
                  src={arrow}
                />
              </TinyButton>
            </Grid>
          ) : (
            <Grid item>
              <Button
                onClick={() => selectLesson(school.lecture[0].id)}
                fontSize="1em"
                padding="10px 15px"
                primary
              >
                {school.lecture[0].school_name === "Testschool"
                  ? "Start oefensessie"
                  : "Selecteer"}
              </Button>
            </Grid>
          )}
        </Grid>
        {school.lecture.length > 1 && (
          <AnimatePresence>
            {isOpen &&
              school.lecture.map((lecture, index) => (
                <Lesson
                  key={index}
                  isOpen={isOpen}
                  lecture={lecture}
                  index={index}
                  homework={homework}
                  setLessonSocket={setLessonSocket}
                  setPhaseSocket={setPhaseSocket}
                />
              ))}
          </AnimatePresence>
        )}
      </motion.div>
    </>
  );
};

const DropdownStyled = styled(motion.div)`
  max-height: 60vh;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid #b2a1c7;
  box-shadow: 0px 3px 0px #b2a1c7;
  ::-webkit-scrollbar {
    width: 15px;
    background-color: rgba(255, 255, 255, 1);
    transform: translateX(-10px);
    border-bottom-right-radius: 10020px;
  }

  ::-webkit-scrollbar-track {
    width: 15px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb {
    width: 15px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${(props) => props.theme.colors.yellow};
  }
`;

export const LessonDropdown = ({ schools, homework }) => {
  const [expanded, setExpanded] = useState(-1);
  const { setPhase, setLesson } = useSocketStore();
  return (
    <DropdownStyled
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        opacity: { delay: 0.2 },
        ...transitionSpring,
      }}
    >
      {schools.map((school, i) => (
        <Accordion
          setLessonSocket={setLesson}
          setPhaseSocket={setPhase}
          homework={homework}
          key={i}
          i={i}
          school={school}
          expanded={expanded}
          schools={schools}
          setExpanded={setExpanded}
        />
      ))}
    </DropdownStyled>
  );
};
