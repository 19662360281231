import { motion } from "framer-motion";
import React from "react";
import { useMainStore } from "../../store";

const IconVerdiepingsvraag = ({ color, initial }) => {
  const { theme } = useMainStore();

  return (
    <motion.svg
      initial={initial && { fill: theme.colors.purple }}
      animate={initial && { fill: color }}
      style={!initial && { fill: color }}
      id="bold"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1080 1080"
      enable-background="new 0 0 1080 1080"
    >
      <path d="M463.1,496.5" />

      <path
        class="st0"
        d="M966.5,0H113.5C50.8,0,0,50.8,0,113.5v852.9c0,62.7,50.8,113.5,113.5,113.5h852.9
		c62.7,0,113.5-50.8,113.5-113.5V113.5C1080,50.8,1029.2,0,966.5,0z M656,831.3c8.7,0,15.5,7,15.5,15.5c0,8.5-6.8,15.5-15.5,15.5
		h-23.2v6.8c0,31.7-25.8,57.5-57.5,57.5h-70.8c-31.7,0-57.4-25.8-57.4-57.5v-6.8h-23.3c-8.5,0-15.5-7-15.5-15.5
		c0-8.5,7-15.5,15.5-15.5h23.3v-16.4h-23.3c-8.5,0-15.5-7-15.5-15.5c0-8.5,7-15.5,15.5-15.5h23.3v-16.4h185.7V784H656
		c8.7,0,15.5,7,15.5,15.5c0,8.5-6.8,15.5-15.5,15.5h-23.2v16.4H656z M716.5,609.1c-31.4,28.6-49.5,69.4-49.5,112.1
		c0,8.5-6.8,15.5-15.5,15.5h-55.4l-112.4,0h-55.5c-4,0-8-1.7-11-4.6c-2.8-2.8-4.5-6.8-4.5-10.8c0-43-17.6-83.3-48.4-111.2
		c-57.5-51.8-89.1-126-86.4-203.5c4.5-135.6,114.1-246.6,249.6-252.8c72.4-3.4,141.2,22.3,193.4,72.2
		c52.4,49.9,81.2,117.4,81.2,189.6C802.2,489.1,770.9,559.6,716.5,609.1z"
      />

      <path d="M616.9,496.5" />
    </motion.svg>
  );
};

export default IconVerdiepingsvraag;
