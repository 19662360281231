import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import CashQuizLogo from "../../../themes/2023/components/atoms/CashQuizLogo";
import { Background } from "../../../themes/2023/components/atoms/Backgrounds";
import { useMainStore } from "../../../store";
import { useGAEventsTracker } from "../../../hooks/useGAEventsTracker";
import { Button } from "../../../themes/2023/components/atoms/Buttons";
import Modal from "../../../themes/2023/components/atoms/Modal";

import typo from "../../../static/2023/typo.png";
import logoText2 from "../../../static/2023/logotext2.png";
import boards from "../../../static/2023/bordjes.png";
import phones from "../../../static/2023/phones.png";
import text1 from "../../../static/2023/Groep 9.png";
import text2 from "../../../static/2023/Groep 10.png";
import arrows1 from "../../../static/2023/arrows1.png";
import arrows2 from "../../../static/2023/arrows2.png";
import goldenArrow from "../../../static/2023/goldenArrow.png";
import whiteArrow from "../../../static/2023/arrowWhite.png";
import BvdkFooter from "../../../themes/2023/components/molecules/BvdkFooter";

const DisabledQuiz = () => {
  const {
    setShowCoins,
    setTypeQuiz,
    setTypeHost,
    setCode,
    setLessons,
    setPhase,
    setRound,
    setLevel,
    setQuestions,
    setTeams,
    setGameID,
    updateScores,
    round,
    typeHost,
    typeQuiz,
  } = useMainStore();
  const GAEventsTracker = useGAEventsTracker("cashquiz");
  const [activegame, setActiveGame] = useState(null);
  const [hover, setHover] = useState(false);

  return (
    <>
      <CashQuizLogo initial />
      <BvdkFooter initial noTestlesson />
      <motion.div>
        <Grid
          style={{
            gap: 32,
            height: "100vh",
            textAlign: "center",
          }}
          container
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{ transform: "translateY(-50%)" }}
          >
            <motion.img
              exit={{ opacity: 0, transition: { delay: 0 } }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2 }}
              style={{ x: 0, y: 15, maxWidth: "80%", width: "640px" }}
              src={typo}
            />
          </Grid>

        </Grid>
      </motion.div>
      {activegame && (
        <Modal
          round={round}
          setActiveGame={setActiveGame}
          activegame={activegame}
        />
      )}
      <Background background={"cloudBackground"} />
    </>
  );
};

export default DisabledQuiz;
