import React from "react";
import { motion } from "framer-motion";
import Grid from "@bit/mui-org.material-ui.grid";
import styled from "styled-components";

import IconClose from "../../static/media/iconClose";
import { Link } from "react-router-dom";
import { useMainStore } from "../../store";

const ModalStyled = styled(motion.div)`
  padding: 60px;
  width: 500px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  opacity: 0;
  position: relative;
  @media only screen and (max-width: 600px) {
    padding: 30px 15px;
    max-width: calc(100% - 60px);
  }
`;

const UnavailableModal = ({ socketUnavailble, setSocketUnavailble }) => {
  const { theme, setTypeQuiz, setPhase } = useMainStore();
  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{
          opacity: socketUnavailble === true ? 1 : 0,
        }}
        exit={{ opacity: 0, scale: 0.1 }}
        style={{
          backgroundColor: theme.colors.background,
          height: "100vh",
          width: "100vw",
          position: "absolute",
          zIndex: 100,
          bottom: 0,
          left: 0,
        }}
      >
        <Grid
          style={{ height: "100vh" }}
          container
          alignItems="center"
          justify="center"
        >
          <ModalStyled
            style={{ opacity: 0 }}
            layout
            initial={{ opacity: 0, scale: 0.1 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.1 }}
          >
            <div
              onClick={() => setSocketUnavailble(false)}
              style={{
                background: theme.colors.backgroundLight,
                position: "absolute",
                top: 0,
                right: 0,
                padding: 18,
                width: 20,
                height: 20,
                cursor: "pointer",
              }}
            >
              <IconClose
                color={theme.colors.purpleDarker}
                height={"18px"}
                width={"18px;"}
              />
            </div>
            <motion.h1
              style={{
                fontFamily: "Brevia",
                fontSize: "1.8rem",
                fontWeight: 800,
                color: theme.colors.purpleDarker,
                marginTop: 0,
              }}
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              Digi versie niet beschikbaar
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              style={{
                fontFamily: "Brevia",
                color: theme.colors.purple,
                fontSize: "1.4em",
              }}
            >
              Helaas is de digi versie van de Cash Quiz niet beschikbaar op dit
              moment. <br /> <br /> Je kunt{" "}
              <Link
                onClick={() => {
                  setTypeQuiz("cashquiz");
                  setPhase("personalCode");
                }}
                style={{
                  textDecoration: "underline",
                  textUnderlineOffset: 3,
                  color: theme.colors.purpleDarker,
                  fontWeight: 600,
                }}
                to="/cashquiz"
              >
                hier
              </Link>{" "}
              de gewone Cash Quiz spelen!
            </motion.p>
          </ModalStyled>
        </Grid>
      </motion.div>
    </>
  );
};

export default UnavailableModal;
