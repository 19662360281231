import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { transitionSpring } from "../pages/hostpages/SelectLesson";

const TextReply = ({ children }) => {
  //hh:mm
  const currentTime = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <Wrapper
      transition={{ ...transitionSpring, delay: 1 }}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, transition: { delay: 0 } }}
    >
      {children}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="37"
        fill="none"
      >
        <g filter="url(#a)">
          <path
            fill="#fff"
            fill-rule="evenodd"
            d="M20.6 0c0 11.33-5.77 19.24-17.31 23.76l-.78.3A2.323 2.323 0 0 0 2 28.15c4.08 2.79 9.51 4.41 18.59 4.85V0h.01Z"
            clip-rule="evenodd"
          />
        </g>
        <defs>
          <filter
            id="a"
            width="19.603"
            height="37"
            x=".997"
            y="0"
            color-interpolation-filters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0.698039 0 0 0 0 0.631373 0 0 0 0 0.780392 0 0 0 1 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_47_12"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_47_12"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
      <span className="time">{currentTime}</span>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  width: fit-content;
  max-width: 500px;
  box-shadow: 0px 4px 0px #b2a1c7;
  border-radius: 10px;
  border: 0.5px solid #dcf7c5;
  padding: 24px;
  background: ${(props) => props.theme.colors.white};
  margin: 24px auto;
  margin-bottom: 32px;
  position: relative;

  .stelling {
    font-size: 22px;
  }

  p {
    font-size: 32px;
    font-weight: 700;
    margin: 0px;
    color: ${(props) => props.theme.colors.purple};
    line-height: 1.1;
    position: relative;
  }

  .time {
    position: absolute;
    bottom: 0px;
    right: 24px;
    font-weight: 400;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.25);
  }

  svg {
    position: absolute;
    bottom: 10px;
    left: -20px;
    width: 40px;
    height: 40px;
    z-index: 1;
  }
`;

export default TextReply;
