import { motion } from "framer-motion";
import React from "react";
import { useMainStore } from "../../store";

const IconBeginscore = ({ color, initial }) => {
  const { theme } = useMainStore();

  return (
    <motion.svg
      initial={initial && { fill: theme.colors.purple }}
      animate={initial && { fill: color }}
      style={!initial && { fill: color }}
      id="bold"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      enable-background="new 0 0 30 30"
      viewBox="0 0 31 31"
    >
      <path
        d="M24.791,4.451c0.02-0.948-0.016-1.547-0.016-1.547l-9.264-0.007l0,0h-0.047h-0.047l0,0L6.152,2.904
		c0,0-0.035,0.599-0.015,1.547H0v1.012c0,0.231,0.039,5.68,3.402,8.665C4.805,15.373,6.555,15.999,8.618,16
		c0.312,0,0.633-0.021,0.958-0.049c1.172,1.605,2.526,2.729,4.049,3.289v4.445H9.154v2.784H7.677v1.561h7.74h0.094h7.74V26.47
		h-1.478v-2.784h-4.471v-4.445c1.522-0.56,2.877-1.684,4.049-3.289C21.678,15.98,21.999,16,22.311,16
		c2.062-0.002,3.812-0.627,5.215-1.873c3.363-2.985,3.402-8.434,3.402-8.665V4.451H24.791z M4.752,12.619
		c-1.921-1.7-2.489-4.61-2.657-6.144h4.158c0.176,1.911,0.59,4.292,1.545,6.385c0.175,0.384,0.359,0.748,0.547,1.104
		C6.912,13.909,5.706,13.462,4.752,12.619z M26.176,12.619c-0.953,0.844-2.16,1.29-3.592,1.345c0.188-0.355,0.372-0.72,0.547-1.104
		c0.955-2.093,1.369-4.474,1.544-6.385h4.158C28.665,8.008,28.098,10.918,26.176,12.619z"
      />
    </motion.svg>
  );
};

export default IconBeginscore;
