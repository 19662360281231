import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import Confetti from "react-confetti";

import { backgrounds } from "../../../../../data/backgrounds";
import { Background } from "../../atoms/Backgrounds";
import { TextLink } from "../../atoms/TextLink";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import { transitionSlowSpring } from "../../atoms/ScoreBar";
import { transitionSpring } from "./SelectLesson";
import { useSocketStore } from "../../../../../store/socketStore";
import TextReply from "../../molecules/TextReply";
import grinning from "../../../../../static/emojis/grinning.png";

const FinalPage = () => {
  const { setPhase, setShowCoins, theme } = useSocketStore();
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    setShowCoins(true);
  }, []);

  return (
    <>
      <Confetti
        style={{ zIndex: -1 }}
        gravity={0.04}
        width={width}
        height={height}
        colors={[
          theme.colors.purple,
          theme.colors.yellow,
          theme.colors.yellowShadow,
          theme.colors.yellowLighter,
          theme.colors.purpleShadow,
          theme.colors.purpleDarker,
        ]}
      />
      <Grid
        container
        style={{ height: "100vh", textAlign: "start" }}
        alignItems="center"
        justify="center"
        exit={{ opacity: 0 }}
        component={motion.div}
      >
        <Grid item>
          <TextReply>
            <motion.h2
              transition={{ transitionSpring, delay: 0.5 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              style={{
                fontFamily: "Brevia",
                color: theme.colors.purple,
                fontSize: "3em",
                margin: 0,
              }}
            >
              Gefeliciteerd geldhelden!{" "}
              <img
                src={grinning}
                width={40}
                style={{
                  marginLeft: 0,
                  display: "inline-block",
                }}
              />
            </motion.h2>
          </TextReply>
        </Grid>
      </Grid>
      <TextLink
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        whileHover={{
          scale: 1.1,
          cursor: "pointer",
        }}
        transition={{
          scale: { delay: 0 },
          default: 0.5,
        }}
        onClick={() => setPhase("end")}
        navigationPrevPurple
        bottom={"50px"}
        left={"50px"}
        style={{
          position: "absolute",
        }}
      >
        terug
      </TextLink>
      <Background initialBig background={"cloudBackgroundBig"} />
    </>
  );
};

export default FinalPage;
