import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import IconVink from "../../../../static/media/iconVink.js";
import IconClose from "../../../../static/media/iconClose.js";

export const LetterBox = styled(motion.div)`
  background-color: ${(props) => props.color};
  font-family: "proxima-nova", sans-serif;
  box-shadow: 0 2px 0 ${(props) => props.boxShadowColor};
  border-radius: 100%;
  font-size: 1.2rem;
  line-height: 1.2rem;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding 8px;
  font-weight: 700;
  color: ${(props) => (props.result ? props.theme.colors.green : "#fff")};
  svg {
    path {
       fill: ${(props) => props.theme.colors.white};
    }
  }
  ${(props) =>
    props.revealAnswer &&
    css`
      svg {
        path {
          fill: ${(props) =>
            !props.correct
              ? props.theme.colors.lightBlue
              : props.theme.colors.white};
        }
      }
    `};
`;

export const numberToLetter = (number) => {
  switch (number) {
    case 0:
      return "A";
    case 1:
      return "B";
    case 2:
      return "C";
    case 3:
      return "D";
    case 4:
      return "E";
    case "Waar":
      return <IconVink height={"12px"} width={"12px;"} />;
    case "Niet waar":
      return <IconClose height={"12px"} width={"12px;"} />;
    case "x":
      return "x";
    default:
      break;
  }
};
