import React, { useEffect, useState } from "react";
import Grid from "@bit/mui-org.material-ui.grid";

import { motion } from "framer-motion";
import { backgrounds } from "../../../../../data/backgrounds";
import { Background } from "../../atoms/Backgrounds";
import ScoreBar from "../../atoms/ScoreBar";
import { useMainStore } from "../../../../../store";
import { Button } from "../../atoms/Buttons";
import { transitionSpring } from "../../../../../default/pages/mainpages/SelectLesson";
import Topbar from "../../molecules/Topbar";
import { useGAEventsTracker } from "../../../../../hooks/useGAEventsTracker";

const Scores = ({ begin }) => {
  const {
    setPhase,
    questions,
    round,
    setGameID,
    personalCode,
    level,
    teams,
    lesson,
    gameid,
    updateScores,
    scores,
    setRound,
    teamsAnswers,
    typeHost,
    typeQuiz,
    theme,
  } = useMainStore();
  const GAEventsTracker = useGAEventsTracker("cashquiz");
  const [showTotalScore, setShowTotalScore] = useState(false);

  useEffect(() => {
    if (!begin) {
      if (round === 0 || round === 3 || round === 6) {
        setShowTotalScore(true);
      }
    }
  }, [setShowTotalScore, round]);

  useEffect(() => {
    if (begin) {
      if (!!personalCode) {
        GAEventsTracker("quiz gestart");
        fetch(process.env.REACT_APP_SERVER + "score/set-game", {
          method: "POST",
          body: JSON.stringify({
            level,
            code: personalCode,
            lesson,
            teams,
            host: typeHost,
            quiztype: typeQuiz,
          }),
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
          .then((result) => {
            setGameID(result.gameid);
            fetch(
              process.env.REACT_APP_SERVER + "score/get-totals/" + result.gameid
            )
              .then((res) => res.json())
              .then(
                (result) => {
                  updateScores(result);
                },
                (error) => {}
              );
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        fetch(process.env.REACT_APP_SERVER + "score/update-game", {
          method: "POST",
          body: JSON.stringify({ level, teams, gameid }),
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
          .then((result) => {
            fetch(process.env.REACT_APP_SERVER + "score/get-totals/" + gameid)
              .then((res) => res.json())
              .then(
                (result) => {
                  updateScores(result);
                },
                (error) => {}
              );
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
      // eslint-disable-next-line
    } else {
      //update groups en niveau als deze nog niet gezet zijn
      fetch(process.env.REACT_APP_SERVER + "score/get-game/" + gameid)
        .then((res) => res.json())
        .then(
          (game) => {
            if (game.groups == 0) {
              fetch(process.env.REACT_APP_SERVER + "score/update-game", {
                method: "POST",
                body: JSON.stringify({ level, teams, gameid }),
                headers: { "Content-Type": "application/json" },
              })
                .then((res) => res.json())
                .then((result) => {
                  console.log(result);
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            }
          },
          (error) => console.log(error)
        );
    }
  }, [begin, lesson, personalCode, setGameID, teams, updateScores, level]);

  return (
    <>
      {begin && (
        <Topbar
          exit
          begin
          questionSoort={"Score"}
          pageTitle=""
          question={"Beginscore"}
        />
      )}
      {!begin && (
        <Topbar
          exit
          questionSoort={questions[round].soort}
          pageTitle={questions[round].soort}
          question={round + 1}
          showTotalScore={showTotalScore}
        />
      )}
      <motion.div exit={{ opacity: 0, transition: { delay: 1.5 } }}>
        <Grid
          style={{
            height: "100vh",
            textAlign: "center",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid
            item
            style={{
              height: "",
              position: "absolute",
              bottom: 0,
              width: "90%",
              left: "5%",
            }}
          >
            <Grid
              container
              spacing={2}
              justify="center"
              style={{
                position: "relative",
                bottom: 0,
                flexWrap: "nowrap",
              }}
            >
              {scores.map((total, group) => (
                <Grid key={group} item xs={2}>
                  <ScoreBar
                    allScores={scores}
                    teamsAnswers={teamsAnswers}
                    questions={questions}
                    round={round}
                    score={total}
                    index={group}
                    begin={begin}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            position: "absolute",
            bottom: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <motion.div
              transition={{
                transitionSpring,
                delay: round === 0 || round === 3 || round === 6 ? 9 : 5,
              }}
              initial={{ y: 300 }}
              animate={{ y: 0 }}
              exit={{ y: 300, transition: { ...transitionSpring, delay: 0 } }}
            >
              <Button
                onAnimationComplete={(definition) => {
                  if (definition?.opacity === 0 && !begin && round !== 11)
                    setRound(round + 1);
                }}
                whileHover={{
                  scale: 1.1,
                  delay: 0,
                }}
                primary
                onClick={() => {
                  if (round === 11) {
                    if (!!JSON.parse(level.params).outrovideourl)
                      setPhase("outro");
                    else setPhase("end");
                  } else {
                    setPhase("quizStart");
                  }
                }}
                exit={{ opacity: 0 }}
              >
                Verder
              </Button>
            </motion.div>
          </Grid>
        </Grid>
      </motion.div>
      <Background
        opacity
        initialBig
        exitOpacity
        background={"cloudBackgroundBigWhite"}
      />
    </>
  );
};

export default Scores;
