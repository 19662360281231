import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { useMainStore } from "../../../../store";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";
import AnswerNormal from "./AnswerNormal";
import AnswerMultiple from "./AnswerMultiple";
import AnswerTrueFalse from "./AnswerTrueFalse";
import AnswerEstimation from "./AnswerEstimation";

const Modal = styled(motion.div)`
  width: ${(props) => (!props.estimation ? "" : "750px")};
  max-width: 80%;
  padding: ${(props) => (!props.estimation ? "1rem 3rem 3rem 3rem" : "0rem")};
  text-align: center;
  border-radius: 10px;
  @media only screen and (max-width: 600px) {
    padding: 15px;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
`;

export const AnswerBoxHw = styled(motion.button)`
  width: 55px;
  height: 55px;
  @media only screen and (max-width: 600px) {
    width: 45px;
    height: 45px;
  }
  border: none;
  border-radius: 0.4rem;
  background-color: ${(props) => props.theme.colors.primary};
  box-shadow: 0 5px 0 ${(props) => props.theme.colors.yellowShadow};
  font-size: 2rem;
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: ${(props) => props.theme.colors.yellowLighter};
  }

  &:active {
    box-shadow: none;
    transform: translateY(5px);
  }

  &:focus {
    outline: 0;
  }

  ${(props) =>
    props.disable &&
    css`
      background-color: ${props.theme.colors.purple};
      box-shadow: 0 5px 0 ${(props) => props.theme.colors.purpleShadow};
      span {
        color: ${props.theme.colors.white};
      }

      &:hover {
        background: ${(props) => props.theme.colors.purpleShadow};
      }
    `}
`;

const AnswerModalHw = ({
  selectedPlayer,
  players,
  setPlayerAnswer,
  setSelectedPlayer,
  confirm,
  setConfirm,
  type,
  homework,
  small,
  hidetitle,
}) => {
  const { questions, round } = useMainStore();

  const renderAnswerInput = () => {
    switch (type) {
      case "eenkeuze":
        return (
          <AnswerNormal
            setConfirm={setConfirm}
            setSelectedPlayer={setSelectedPlayer}
            setPlayerAnswer={setPlayerAnswer}
            selectedPlayer={selectedPlayer}
            questions={questions}
            round={round}
            players={players}
            homework={homework}
            small={small}
            hidetitle={hidetitle}
          />
        );
      case "meerkeuze":
        return (
          <AnswerMultiple
            setConfirm={setConfirm}
            setSelectedPlayer={setSelectedPlayer}
            setPlayerAnswer={setPlayerAnswer}
            selectedPlayer={selectedPlayer}
            questions={questions}
            round={round}
            players={players}
            homework={homework}
            small={small}
          />
        );
      case "schatting":
        return (
          <AnswerEstimation
            setConfirm={setConfirm}
            setSelectedPlayer={setSelectedPlayer}
            setPlayerAnswer={setPlayerAnswer}
            selectedPlayer={selectedPlayer}
            questions={questions}
            round={round}
            players={players}
            homework={homework}
            small={small}
          />
        );
      case "waarnietwaar":
        return (
          <AnswerTrueFalse
            setConfirm={setConfirm}
            setSelectedPlayer={setSelectedPlayer}
            setPlayerAnswer={setPlayerAnswer}
            selectedPlayer={selectedPlayer}
            questions={questions}
            round={round}
            players={players}
            homework={homework}
            small={small}
            hidetitle={hidetitle}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid container alignItems="center" justify="center">
      <Modal
        style={{ y: 0 }}
        estimation={type === "schatting" && !confirm}
        layout
        initial={{ opacity: 0, scale: 0.1 }}
        exit={{ opacity: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ ...transitionSpring }}
      >
        {renderAnswerInput()}
      </Modal>
    </Grid>
  );
};

export default AnswerModalHw;
