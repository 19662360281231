import { motion } from "framer-motion";
import React, { useState } from "react";
import { useMainStore } from "../../../../../store";
import AnswerPossibilitiesModal from "../../atoms/AnswerPossibilitiesModal";
import CloudBackgroundSubmitAnswer from "../../atoms/CloudBackgroundSubmitAnswer";
import { TextLink } from "../../atoms/TextLink";
import AnswerModal from "../../molecules/AnswerModal";
import AnswerTabs from "../../molecules/AnswerTabs";
import Topbar from "../../molecules/Topbar";

const SubmitAnswers = () => {
  const {
    teams,
    setTeamsAnswers,
    gameid,
    questions,
    round,
    setPhase,
    duplicates,
    decision,
  } = useMainStore();
  const [confirm, setConfirm] = useState(false);
  const [players, setPlayers] = useState(decision ? duplicates : teams);
  const [selectedPlayer, setSelectedPlayer] = useState(0);
  const [possibleAnswers, setPossibleAnswers] = useState(false);
  let type = decision ? "schatting" : questions[round].type;

  const setPlayerAnswer = (answer, reset) => {
    let newArr = [...players];
    let correct;
    if (type === "waarnietwaar") {
      correct = answer === questions[round].answer ? 1 : 0;
    } else if (type === "meerkeuze") {
      correct = JSON.parse(questions[round].answer).includes(
        (answer + 1).toString()
      );
    } else {
      correct = answer === parseInt(questions[round].answer) - 1 ? 1 : 0;
    }

    newArr[selectedPlayer] = {
      ...newArr[selectedPlayer],
      answer:
        reset !== "hard"
          ? type === "meerkeuze"
            ? !reset
              ? newArr[selectedPlayer].answer
                ? [...newArr[selectedPlayer].answer, answer]
                : [answer]
              : [answer]
            : answer
          : answer,
      group: newArr[selectedPlayer].group
        ? newArr[selectedPlayer].group
        : selectedPlayer + 1,
      question: decision ? "decision" : questions[round].ordering,
      points: correct ? questions[round].points : 0,
      gameid: gameid,
      correct: correct,
    };

    setPlayers(newArr);
    setTeamsAnswers(newArr);

    fetch(process.env.REACT_APP_SERVER + "score/set-player-answer", {
      method: "POST",
      body: JSON.stringify(newArr[selectedPlayer]),
      headers: { "Content-Type": "application/json" },
    }).catch((error) => {
      console.error("Error:", error);
    });
  };
  return (
    <>
      <Topbar
        pageTitle={decision ? "Beslissing" : questions[round].soort}
        question={decision ? "Beslissing" : round + 1}
        show={!decision}
        questionSoort={questions[round].soort}
      />
      <CloudBackgroundSubmitAnswer />
      <motion.div
        layout
        style={{
          position: "absolute",
          top: "0",
          height: "calc(100vh - 60px)",
          padding: "60px 0 0 0",
          display: "flex",
          flexDirection: "column-reverse",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <AnswerTabs
          type={type}
          setConfirm={setConfirm}
          players={players}
          selectedPlayer={selectedPlayer}
          setSelectedPlayer={setSelectedPlayer}
        />
        <AnswerModal
          type={type}
          selectedPlayer={selectedPlayer}
          setPlayerAnswer={setPlayerAnswer}
          setSelectedPlayer={setSelectedPlayer}
          players={players}
          setConfirm={setConfirm}
          confirm={confirm}
          homework={false}
        />
      </motion.div>
      <TextLink
        onClick={() => setPhase("question")}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        whileHover={{
          scale: 1.1,
          cursor: "pointer",
        }}
        transition={{
          scale: { delay: 0 },
          default: 0.5,
        }}
        exit={{ opacity: 0 }}
        navigationPrevPurple
        bottom={"50px"}
        left={"30px"}
        style={{
          position: "absolute",
        }}
      >
        terug
      </TextLink>
      {/* <TextLink
        onClick={() => setPossibleAnswers(true)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        whileHover={{
          scale: 1.1,
          cursor: "pointer",
        }}
        transition={{
          scale: { delay: 0 },
          default: 0.5,
        }}
        exit={{ opacity: 0 }}
        navigationPrevPurple
        bottom={"50px"}
        left={"30px"}
        style={{
          position: "absolute",
        }}
      >
        Bekijk mogelijke antwoorden
      </TextLink> */}
      {/* {possibleAnswers && <AnswerPossibilitiesModal questions={questions} />} */}
    </>
  );
};

export default SubmitAnswers;
