import { createGlobalStyle, ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { normalize } from "styled-normalize";
import ReactGA from "react-ga";

import MainContainer from "./containers/MainContainer";
import HostContainer from "./containers/HostContainer";
import HomeworkContainer from "./containers/HomeworkContainer";

import PlayerContainer from "./containers/PlayerContainer";
import { useMainStore } from "./store";
import StartContainer from "./containers/StartContainer";
import TestBanner from "./default/components/TestBanner";
import { useSocketStore } from "./store/socketStore";

const GlobalStyle = createGlobalStyle`
${normalize}
* {
  text-decoration: none;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  height: 100%;
  overflow: hidden;
}

body {
  font-size: 16px;
  font-family: 'proxima-nova';
  overscroll-behavior: none;
  position: relative;
  overflow: hidden;
  line-height: 1.8em;
  height: 100%;
}

h1 {
  font-size: 3em;
  font-family: 'proxima-nova';
  line-height: 1.1em;
  color: ${(props) => props.theme.colors.purple};
}

@media only screen and (max-width: 600px) {
   h1 {
    font-size: 2.2em;
  }
}

h2 {
  font-size: 2em;
  font-family: 'proxima-nova';
  line-height: 1.1em;
  color: ${(props) => props.theme.colors.purple};
}

@media only screen and (max-width: 600px) {
   h2 {
    font-size: 1.8em;
  }
}

strong {
  font-family: 'proxima-nova';
  font-weight: normal;
}

.no-wrap {
  white-space: nowrap;
}

.Toastify__toast--default {
  background-color: ${(props) => props.theme.colors.purple};
  color: ${(props) => props.theme.colors.white};
  font-family: "proxima-nova";
  font-size: 1rem;
}

.Toastify__toast {
  text-align: center;
}
`;

ReactGA.initialize("UA-192197929-1");

function App() {
  const { theme, testSession } = useMainStore();
  const { phase } = useSocketStore();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {testSession && <TestBanner />}

      <Router>
        <Switch>
          <Route path="/cashquiz-plus">
            <HostContainer />
          </Route>
          <Route path="/cashquiz-speler">
            <PlayerContainer />
          </Route>
          {/* <Route path="/huiswerkles">
            <HomeworkContainer />
          </Route> */}
          <Route path="/cashquiz">
            <MainContainer />
          </Route>
          <Route path="/">
            <StartContainer />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
