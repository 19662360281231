import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion, useAnimation } from "framer-motion";

import { PanelQuestion } from "../../molecules/PanelQuestion";
import { PanelIntro } from "../../molecules/PanelIntro";
import TimerBar from "../../atoms/TimerBar";
import TimesUpModal from "../../atoms/TimesUpModal";
import { useMainStore } from "../../../../../store";
import { LetterBox, numberToLetter } from "../../atoms/LetterBox";
import { NavigationNext } from "../../atoms/Buttons";
import { transitionSpring } from "../../../../../default/pages/mainpages/SelectLesson";
import Topbar from "../../molecules/Topbar";
import { TextLink } from "../../atoms/TextLink";
import { AnswersHolder } from "../../atoms/AnswersHolder";
import useInterval from "../../../../../hooks/useInterval";
import { transitionSlowSpring } from "../../atoms/ScoreBar";
import bulb from "../../../../../static/media/bulb.svg";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import CountDown from "../../atoms/Countdown";

const variants3 = {
  show: (custom) => ({
    opacity: 1,
    y: 0,
    transition: { delay: custom * 0.2 + 1 },
  }),
};

const backgroundVariants = {
  peak: {
    x: -100,
    transition: { duration: 1, type: "spring" },
  },
  small: {
    transition: { duration: 1, type: "spring" },
  },
  exit: {
    x: "-100%",
    transition: { ...transitionSpring, delay: 0.5 },
  },
};

const Question = ({ homework }) => {
  const { setPhase, questions, round, setRound, theme } = useMainStore();
  const answersPanelAnimation = useAnimation();
  const questionPanelAnimation = useAnimation();
  const questionAnimation = useAnimation();
  const backgroundAnimation = useAnimation();
  const CloudBackgroundDescriptionAnimation = useAnimation();
  const [answerPhase, setAnswerPhase] = useState(
    questions[round].intro ? false : "show"
  );
  const { small, height } = useWindowDimensions();
  let [timer, startTimer] = useState(false);
  let [startCountdown, setStartCountdown] = useState(false);
  const type = questions[round].type;

  const variants = {
    full: {
      justifyContent: "center",
      transition: { duration: 1, type: "spring" },
      width: questions[round].intro
        ? questions[round].question_layout === "introbig"
          ? "50%"
          : "70%"
        : "100%",
      left: questions[round].intro
        ? questions[round].question_layout === "introbig"
          ? "50%"
          : "30"
        : "0%",
      paddingLeft: "0",
      x: 0,
    },
    hide: {
      opacity: 0,
    },
  };

  const variants2 = {
    peak: {
      transition: { ...transitionSpring },
      width: "100%",
    },
    small: {
      transition: { ...transitionSpring },
      width: questions[round].question_layout === "introbig" ? "50%" : "30%",
      justifyContent: "center",
    },
    hide: {
      opacity: 0,
    },
  };

  // useEffect(() => {
  //   for (let i = 0; i < players.length; i++) {
  //     fetch(process.env.REACT_APP_SERVER + "score/get-player-answer", {
  //       headers: {"Content-Type": "application/json"},
  //       body: JSON.stringify({group: (i + 1), gameid: gameid, question: questions[round].ordering}),
  //       method: "POST",
  //     })
  //         .then((res) => res.json())
  //         .then((result) => {
  //           if (result.length > 0) {
  //             if (type === 'schatting' || type === 'eenkeuze') {
  //               players[i].answer = parseInt(result[0].answer);
  //             } else {
  //               players[i].answer = result[0].answer;
  //             }
  //           } else {
  //             players[i].answer = '';
  //           }
  //           setPlayers(players);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //   }
  // }, [setPlayers]);

  useEffect(() => {
    let timeout;
    if (!answerPhase) {
      questionPanelAnimation.start("peak");
      timeout = setTimeout(() => {
        backgroundAnimation.start("peak");
      }, 2000);
    } else if (answerPhase === "show") {
      questionAnimation.start("show");
      answersPanelAnimation.start("full");
      questionPanelAnimation.start("small");
      startTimer(questions[round].time);
    } else if (answerPhase === "exit") {
      questionPanelAnimation.start("hide");
      answersPanelAnimation.start("hide");
    }
    return () => clearTimeout(timeout);
  }, [
    answerPhase,
    answersPanelAnimation,
    questionAnimation,
    questionPanelAnimation,
    CloudBackgroundDescriptionAnimation,
    backgroundAnimation,
    questions,
    round,
  ]);

  useInterval(() => {
    if (timer !== 0 && answerPhase === "show") {
      startTimer(timer - 1);
    }
  }, 1000);

  useEffect(() => {
    if (!startCountdown && timer < 59 && timer !== false) {
      setStartCountdown(true);
    }
  }, [timer, startCountdown]);

  return (
    <>
      <motion.div
        style={{
          zIndex: 0,
          position: "absolute",
          width: "100vw",
          height: height,
          backgroundColor: theme.colors.purple,
        }}
        animate={CloudBackgroundDescriptionAnimation}
        variants={backgroundVariants}
        exit="exit"
      />
      {/* <CountDown
        startCountdown={startCountdown}
        setStartCountdown={setStartCountdown}
      /> */}
      <Topbar
        question={round + 1}
        noVideo={!questions[round].video}
        questionValue={questions[round].points}
        questionSoort={questions[round].soort}
      />
      <Grid container alignItems="center" justify="center">
        <Grid
          item
          xs={answerPhase ? 11 : 11}
          lg={answerPhase ? 8 : 8}
          style={{ position: answerPhase ? "relative" : "inherit" }}
        >
          {questions[round].intro !== "" && (
            <PanelIntro
              custom={answerPhase}
              animate={questionPanelAnimation}
              variants={variants2}
              layoutId="panel"
              layout
              exit="hide"
              style={{
                flexDirection: answerPhase ? "column" : "row",
                position: answerPhase ? "absolute" : "relative",
                left: answerPhase ? "0" : "inherit",
              }}
            >
              <Grid
                alignItems={answerPhase ? "center" : "center"}
                container
                justify={answerPhase ? "center" : "center"}
              >
                <Grid
                  item
                  lg={answerPhase ? 12 : ""}
                  style={{
                    width: "100%",
                    border: answerPhase ? "1px solid transparent" : "",
                    padding: answerPhase ? "0 15px" : "",
                    background: answerPhase ? theme.colors.white : "",
                    borderRadius: answerPhase ? " 10px 0 10px 10px" : "",
                    marginTop: "0",
                    boxShadow: answerPhase
                      ? "inset 0px -2px 0px -1px rgba(255,255,255,0.075)"
                      : "none",
                  }}
                >
                  <motion.div
                    layout
                    initial={{ opacity: 0, x: 200 }}
                    animate={{ opacity: 1, x: 0, transition: { delay: 1 } }}
                    transition={{
                      ...transitionSlowSpring,
                    }}
                  >
                    <motion.h2
                      initial={{ fontSize: "2rem" }}
                      animate={{
                        color: theme.colors.yellow,
                        fontSize: answerPhase ? "1.6rem" : "2rem",
                        display: answerPhase ? "none" : "block",
                        opacity: answerPhase ? "0" : "1",
                      }}
                      transition={{ ...transitionSpring }}
                      style={{
                        textShadow: "0 1px 1px rgba(0,0,0,0.4)",
                        fontFamily: "Brevia",
                      }}
                      exit={{ opacity: 0 }}
                    >
                      inleiding
                    </motion.h2>
                    <motion.p
                      initial={{ fontSize: "2rem" }}
                      animate={{
                        fontSize: answerPhase ? "1.4rem" : "2.2rem",
                        lineHeight: "1.6em",
                        color: answerPhase
                          ? theme.colors.purple
                          : theme.colors.white,
                      }}
                      transition={{ ...transitionSpring }}
                      dangerouslySetInnerHTML={{
                        __html: questions[round].intro,
                      }}
                    />
                  </motion.div>
                </Grid>
              </Grid>
            </PanelIntro>
          )}

          {!answerPhase && (
            <>
              <TextLink
                navigationPrev
                onClick={() => {
                  if (questions[round].video) setPhase("questionVideo");
                  else {
                    setRound(round - 1);
                  }
                }}
                initial={{ opacity: 0, display: "none" }}
                animate={{ opacity: 1, display: "block" }}
                whileHover={{
                  scale: 1.1,
                  cursor: "pointer",
                }}
                transition={{
                  scale: { delay: 0 },
                  default: { delay: 2 },
                }}
                bottom={"50px"}
                left={"30px"}
                style={{
                  position: "absolute",
                }}
              >
                terug
              </TextLink>
              <NavigationNext
                bottom={30}
                right={30}
                initial={{ opacity: 0, display: "none" }}
                animate={{ opacity: 1, display: "block" }}
                transition={{
                  scale: { delay: 0 },
                  default: { delay: 2 },
                }}
                text="Ga naar vraag"
                nextPhase={() => setAnswerPhase("show")}
              />
            </>
          )}
          <PanelQuestion
            variants={variants}
            animate={answersPanelAnimation}
            style={{
              x: 250,
              left: "30%",
              height: height,
              marginTop: "-100px",
              padding:
                questions[round].question_layout === "questiononly" ? 0 : "",
              justifyContent:
                questions[round].question_layout === "questiononly"
                  ? "center"
                  : "inherit",
            }}
          >
            {answerPhase === "show" && (
              <Grid
                container
                alignItems={questions[round].intro ? "center" : "center"}
                justify="center"
                spacing={small ? 2 : 4}
              >
                {questions[round].image && (
                  <Grid
                    item
                    component={motion.Grid}
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                      transition: { delay: 1.5 },
                    }}
                    transition={{ delay: 1 }}
                    exit={{ opacity: 0, scale: 0, transition: { delay: 0 } }}
                    xs={5}
                    sm={4}
                    style={{
                      background: theme.colors.white,
                      marginLeft: questions[round].intro ? "30px" : "0",
                      borderRadius: small ? "10px" : "0 10px 10px 10px",
                      overflow: "hidden",
                      boxShadow: "0 3px 0px #B2A1C7",
                    }}
                  >
                    <motion.img
                      style={{
                        width: "100%",
                        float: "left",
                      }}
                      src={`questionimages/${questions[round].image}`}
                    />
                  </Grid>
                )}

                <Grid
                  item
                  xs={questions[round].image ? 11 : 11}
                  sm={questions[round].image ? 7 : 11}
                  style={{
                    marginLeft: questions[round].intro ? "30px" : "0",
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                    exit={{ opacity: 0, transition: { delay: 0 } }}
                  >
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: questions[round].name,
                      }}
                      style={{
                        fontSize: small
                          ? questions[round].question_layout === "questiononly"
                            ? "2.8rem"
                            : "1.2rem"
                          : questions[round].question_layout === "questiononly"
                          ? "2.8rem"
                          : "1.6rem",
                        lineHeight: small
                          ? questions[round].question_layout === "questiononly"
                            ? "2.8rem"
                            : "1.6rem"
                          : questions[round].question_layout === "questiononly"
                          ? "3.6rem"
                          : "1.8rem",
                        fontFamily: "Brevia",
                        marginTop: questions[round].image ? "" : "0",
                        textShadow: "0 1px 1px rgba(0,0,0,0.4)",
                      }}
                    />
                    {questions[round].possible_answers && (
                      <motion.p
                        animate={{
                          fontSize: small ? "1rem" : "1.4rem",
                          lineHeight: "1em",
                          color: theme.colors.white,
                          display: "flex",
                          alignItems: "center",
                        }}
                        transition={{ ...transitionSpring }}
                      >
                        <div
                          style={{
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            width: 25,
                            height: 25,
                            marginRight: 20,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              color: theme.colors.purple,
                              fontSize: "0.8em",
                              fontWeight: 900,
                            }}
                          >
                            !
                          </span>
                        </div>{" "}
                        {parseInt(questions[round].possible_answers) ===
                        JSON.parse(questions[round].answers).length
                          ? "Meerdere"
                          : questions[round].possible_answers}{" "}
                        antwoorden mogelijk
                      </motion.p>
                    )}
                    {questions[round].tip && (
                      <motion.p
                        animate={{
                          fontSize: "1.4rem",
                          lineHeight: "1.4em",
                          color: theme.colors.white,
                          alignItems: "center",
                          display: "flex",
                        }}
                        transition={{ ...transitionSpring }}
                      >
                        <img
                          width={40}
                          height={40}
                          style={{ marginRight: 10 }}
                          src={bulb}
                          alt="tipsicon"
                        />
                        <p
                          dangerouslySetInnerHTML={{
                            __html: questions[round].tip,
                          }}
                        />
                      </motion.p>
                    )}
                    {questions[round].type !== "schatting" && (
                      <AnswersHolder>
                        {JSON.parse(questions[round].answers).map(
                          (answer, index) =>
                            answer && (
                              <motion.div
                                style={{
                                  opacity: 0,
                                  y: -30,
                                }}
                                animate={questionAnimation}
                                variants={variants3}
                                custom={index}
                                key={answer}
                              >
                                <Grid container alignItems="center">
                                  <Grid item xs={2} sm={1}>
                                    {questions[round].type !==
                                    "waarnietwaar" ? (
                                      <LetterBox
                                        style={{
                                          opacity: 1,
                                          y: 0,
                                          backgroundColor: theme.colors.green,
                                        }}
                                        boxShadowColor={
                                          theme.colors.greenDarker
                                        }
                                      >
                                        {numberToLetter(index)}
                                      </LetterBox>
                                    ) : (
                                      <LetterBox
                                        style={{
                                          opacity: 1,
                                          y: 0,
                                          backgroundColor: theme.colors.green,
                                        }}
                                        boxShadowColor={
                                          theme.colors.greenDarker
                                        }
                                      >
                                        {numberToLetter(answer)}
                                      </LetterBox>
                                    )}
                                  </Grid>
                                  <Grid item xs={10} sm={11}>
                                    <motion.h3
                                      style={{
                                        fontFamily: "proxima-nova",
                                        fontWeight: "normal",
                                        opacity: 1,
                                        y: 0,
                                      }}
                                    >
                                      {answer}
                                    </motion.h3>
                                  </Grid>
                                </Grid>
                              </motion.div>
                            )
                        )}
                      </AnswersHolder>
                    )}
                  </motion.div>
                </Grid>
              </Grid>
            )}
          </PanelQuestion>
        </Grid>
      </Grid>
      {answerPhase && (
        <>
          {!homework && (
            <TextLink
              whileHover={{
                borderBottom: "1px solid #fff",
                cursor: "pointer",
                opacity: 1,
                scale: 1.1,
              }}
              onClick={() => {
                if (questions[round].intro) {
                  setAnswerPhase(false);
                } else {
                  if (questions[round].video) setPhase("questionVideo");
                  else {
                    setAnswerPhase(false);
                    setRound(round - 1);
                  }
                }
              }}
              initial={{ opacity: 0, display: "none" }}
              animate={{ opacity: 1, display: "block" }}
              transition={{
                scale: { delay: 0 },
                default: 2.5,
              }}
              exit={{ opacity: 0 }}
              navigationPrev
              bottom={"50px"}
              left={"30px"}
              style={{
                position: "absolute",
              }}
            >
              terug
            </TextLink>
          )}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 1 } }}
            exit={{ opacity: 0, transition: { delay: 0 } }}
            style={{
              width: small ? "calc(100% - 30px)" : "initial",
              position: "absolute",
              bottom: "30px",
              right: small ? "0" : "30px",
              display: "flex",
              alignItems: "center",
              padding: small ? "0 15px" : "0",
              justifyContent: "space-between",
            }}
          >
            <TimerBar
              timer={timer}
              totalTime={questions[round].time}
              nextPhase={() => setPhase("submitAnswers")}
            />
            <NavigationNext
              initial={{ opacity: 0, display: "none" }}
              animate={{ opacity: 1, display: "block" }}
              whileHover={{
                scale: 1.03,
                cursor: "pointer",
              }}
              transition={{
                scale: { delay: 0 },
                opacity: { delay: 2 },
              }}
              text="Vul antwoorden in"
              nextPhase={() => setPhase("submitAnswers")}
              bottom={60}
              flexPosition
              small={small}
            />
          </motion.div>
        </>
      )}
      <TimesUpModal
        timer={timer}
        currentPhase={answerPhase ? "answerPhase" : ""}
        nextPhase={() => setPhase("submitAnswers")}
      />
    </>
  );
};

export default Question;
