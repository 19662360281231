import { motion } from "framer-motion";
import React from "react";
import Confetti from "react-dom-confetti";
import styled, { css } from "styled-components";
import { useMainStore } from "../../../../store";
import { numberToLetter } from "../atoms/LetterBox";
import CustomConfetti from "./CustomConfetti";

export const AnswerBoxStyled = styled(motion.button)`
  width: 25vw;
  height: 25vw;
  max-width: 120px;
  max-height: 120px;
  border: none;
  border-radius: 0.4rem;
  background-color: ${(props) => props.theme.colors.goldGradient};
  box-shadow: 0 5px 0 ${(props) => props.theme.colors.background};
  font-size: 4rem;
  @media only screen and (max-width: 600px) {
    font-size: 2.5rem;
  }
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
  transition: background 0.2s ease;
  margin: 5px;
  position: relative;
  &:hover {
    background: ${(props) => props.theme.colors.yellowLighter};
  }

  &:active {
    box-shadow: none;
    transform: translateY(5px);
  }

  &:focus {
    outline: 0;
  }

  ${(props) =>
    props.disable &&
    css`
      background-color: ${props.theme.colors.purple};
      box-shadow: 0 5px 0 ${(props) => props.theme.colors.purpleShadow};
      pointer-events: none;
      span {
        color: ${props.theme.colors.white};
      }

      &:hover {
        background: ${(props) => props.theme.colors.purpleShadow};
      }
    `}
`;

const AnswerBox = ({
  index,
  chosenAnswer,
  setChosenAnswer,
  answered,
  confetti,
  config,
  question,
  disable,
}) => {
  const { theme } = useMainStore();

  const getFeedbackColor = () => {
    if (confetti && chosenAnswer === index) return "#43ba69";
    else if (confetti === false && chosenAnswer === index) return "#ba4343";
    else if (chosenAnswer !== index) return theme.colors.white;
    else return "#E7EAFF";
  };

  const getFeedbackColorMultiple = () => {
    if (Array.isArray(JSON.parse(question.answer))) {
      if (
        confetti &&
        chosenAnswer.includes(index) &&
        JSON.parse(question.answer).includes((index + 1).toString())
      )
        return "#43ba69";
      else if (
        chosenAnswer.includes(index) &&
        !JSON.parse(question.answer).includes((index + 1).toString()) &&
        confetti !== undefined
      )
        return "#ba4343";
      else if (!chosenAnswer.includes(index)) return theme.colors.white;
      else return theme.colors.purple;
    }
  };

  const getStyle = () => {
    if (Array.isArray(chosenAnswer)) {
      return {
        border: `1px solid ${theme.colors.purple}`,
        y: !chosenAnswer.includes(index) ? 0 : 3,
        boxShadow: !chosenAnswer.includes(index)
          ? `0px 3px 0px ${theme.colors.purple}`
          : `0px 0px 0px ${theme.colors.purple}`,
        color: !chosenAnswer.includes(index)
          ? theme.colors.purple
          : theme.colors.background,
      };
    } else {
      return {
        border: `1px solid ${theme.colors.purple}`,
        y: chosenAnswer !== index ? 0 : 3,
        boxShadow:
          chosenAnswer !== index
            ? `0px 3px 0px ${theme.colors.purple}`
            : `0px 0px 0px ${theme.colors.purple}`,
        color:
          chosenAnswer !== index ? theme.colors.purple : theme.colors.purple,
      };
    }
  };

  return (
    <AnswerBoxStyled
      disable={disable}
      transition={{ duration: 0.2 }}
      initial={{
        backgroundColor: theme.colors.white,
        color: theme.colors.purple,
        boxShadow: `0px 3px 0px #B2A1C7`,
        border: `1px solid #B2A1C7`,
        y: 0,
      }}
      animate={{
        backgroundColor: Array.isArray(chosenAnswer)
          ? getFeedbackColorMultiple()
          : getFeedbackColor(),
        color: getStyle().color,
        boxShadow: getStyle().boxShadow,
        border: getStyle().border,
        y: getStyle().y,
      }}
      onClick={() => {
        if (!answered) {
          if (question.type === "meerkeuze") {
            if (chosenAnswer === undefined) {
              setChosenAnswer([index]);
            } else if (
              Array.isArray(chosenAnswer) &&
              chosenAnswer.includes(index)
            ) {
              const chosen = chosenAnswer.filter((answer) => answer !== index);
              setChosenAnswer(chosen);
            } else if (
              chosenAnswer.length === parseInt(question.possible_answers)
            ) {
              setChosenAnswer([index]);
            } else if (Array.isArray(chosenAnswer)) {
              setChosenAnswer([...chosenAnswer, index]);
            } else {
              setChosenAnswer([chosenAnswer, index]);
            }
          } else {
            !answered && setChosenAnswer(index);
          }
        }
      }}
    >
      {chosenAnswer === index && confetti && (
        <CustomConfetti isActive={confetti} />
      )}
      {numberToLetter(index)}
    </AnswerBoxStyled>
  );
};

export default AnswerBox;
