import React, { useEffect, useState } from "react";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import styled from "styled-components";
import Grid from "@bit/mui-org.material-ui.grid";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";
import Confetti from "react-dom-confetti";
import { useMainStore } from "../../../../store";
import like from "../../../../static/media/like.png";
import crown from "../../../../static/emojis/crown.png";
import { numberToLetter } from "./LetterBox";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import CustomConfetti from "../molecules/CustomConfetti";

export const transitionSlowSpring = {
  stiffness: 600,
  damping: 100,
  mass: 8.5,
  type: "spring",
};

const ScoreBarSocket = ({
  index,
  score,
  begin,
  allScores,
  questions,
  round,
}) => {
  const [confetti, setConfetti] = useState(false);
  const [scoreWin, setScoreWin] = useState(false);
  const { theme } = useMainStore();
  const [rankedScores, setRankedScores] = useState(null);
  if (begin) score.last_score = 10;

  function calculateHierarchy(teamRankings, chartHeight) {
    const maxRank = Math.max(...teamRankings);
    const translations = [];
    for (let i = 0; i < teamRankings.length; i++) {
      const translation =
        (maxRank - teamRankings[i]) * (chartHeight / (maxRank + 1));
      translations.push(translation);
    }
    return translations;
  }

  const chartHeight = 150;

  useEffect(() => {
    //set rankedScores the scores with ranking
    const scores = allScores.map((score) => {
      const newScore = { ...score };
      newScore.ranking = allScores.filter(
        (score2) => score2.total > score.total
      ).length;
      return newScore;
    });
    const rankings = scores.map((team) => team.ranking);
    const translations = calculateHierarchy(rankings, chartHeight);
    setRankedScores((prevScores) =>
      scores.map((team, index) => ({
        ...team,
        yTranslation: chartHeight - translations[index],
      }))
    );
  }, [allScores]);

  const addVariants = {
    initial: {
      y: 50,
      opacity: 0,
    },
    initialLike: {
      y: 50,
      opacity: 0,
      scale: 0.1,
    },
    up: {
      scale: 1,
      opacity: 1,
      y: -200,
      transition: {
        duration: 1,
        // drastic ease inOut
        ease: [0.16, 1, 0.1, 1],
        scale: {
          ...transitionSpring,
        },
      },
    },
    down: {
      y: 50,
      opacity: 0,
      transition: {
        duration: 0.4,
        ease: [0.16, 1, 0.3, 1],
        opacity: {
          duration: 0.2,
        },
      },
    },
    downLike: {
      y: 50,
      opacity: 0,
      transition: {
        duration: 0.4,
        ease: [0.16, 1, 0.3, 1],
        delay: 0.05,
        opacity: {
          duration: 0.2,
        },
      },
    },
  };

  const barVariants = {
    initial: {
      y: "100%",
    },
    ranking: (custom) => ({
      backgroundColor: theme.colors.purple,
      y: custom.yTranslation,
      transition: {
        ...transitionSpring,
      },
    }),
    exit: (custom) => ({
      y: "140%",
    }),
  };

  const getText = () => {
    if (begin) {
      return `${score.last_score ? score.last_score : score.total}`;
    } else {
      if (!score.last_answer) {
        return "x";
      } else if (questions[round].type === "waarnietwaar")
        return score.last_answer;
      else if (questions[round].type === "open") {
        return score.last_score >= 2 ? "Goed" : "Fout";
      } else if (questions[round].type === "schatting") {
        return questions[round].unit_prepend
          ? `${questions[round].unit.trim()}${score.last_answer}`
          : `${score.last_answer}${questions[round].unit}`;
      } else if (questions[round].type === "meerkeuze" && score.last_answer) {
        let split_answer = score.last_answer.split(",");
        const parsed = split_answer.map((letter) => parseInt(letter));
        if (split_answer.length === 1) {
          return `${numberToLetter(parsed[0])}`;
        }
        if (parsed.length === 2) {
          return `${numberToLetter(parsed[0])}${numberToLetter(parsed[1])}`;
        }
        if (parsed.length === 3) {
          return `${numberToLetter(parsed[0])}${numberToLetter(
            parsed[1]
          )}${numberToLetter(parsed[2])}`;
        }
        if (parsed.length === 4) {
          return `${numberToLetter(parsed[0])}${numberToLetter(
            parsed[1]
          )}${numberToLetter(parsed[2])}${numberToLetter(parsed[3])}`;
        }
        if (parsed.length === 5) {
          return `${numberToLetter(parseInt(parsed[0]))}${numberToLetter(
            parsed[1]
          )}${numberToLetter(parsed[2])}${numberToLetter(
            parsed[3]
          )}${numberToLetter(parsed[4])}`;
        }

        return parsed.length === 2
          ? `${numberToLetter(parseInt(parsed[0]))}${numberToLetter(
              parseInt(parsed[1])
            )}`
          : parsed.length === 3
          ? `${numberToLetter(parseInt(parsed[0]))}${numberToLetter(
              parseInt(parsed[1])
            )}${numberToLetter(parseInt(parsed[2]))}`
          : `${numberToLetter(parseInt(parsed[0]))}`;
      } else if (score.last_answer) {
        return `${numberToLetter(parseInt(score.last_answer))}`;
      }
    }
  };

  const textVariants = {
    initialNew: {
      opacity: 0,
      scale: 0.6,
      y: 30,
    },
    initialOld: {
      opacity: 1,
      scale: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      scale: 0.6,
      y: 30,
    },
    enter: {
      opacity: 1,
      scale: 1,
      y: 0,
    },
  };

  const configConfetti = {
    angle: 90,
    spread: "21",
    startVelocity: "23",
    elementCount: "37",
    dragFriction: "0.07",
    duration: "1830",
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: [theme.colors.purple, theme.colors.lightblue],
  };

  const controlsBar = useAnimation();
  const controlsAddScore = useAnimation();
  const controlsLike = useAnimation();
  const controlsTextOld = useAnimation();
  const controlsTextNew = useAnimation();

  useEffect(() => {
    if (begin) {
      setScoreWin(true);
    } else {
      if (score.last_score > 0) {
        setScoreWin(true);
      } else {
        setScoreWin(false);
      }
    }
  }, [score.last_score, begin, setScoreWin]);

  useEffect(() => {
    if (rankedScores) {
      controlsBar.start("ranking");
    }
    setTimeout(() => {
      controlsLike.start("up");
      controlsAddScore.start("up");
    }, 400);

    setTimeout(() => {
      controlsAddScore.start("down");
      controlsLike.start("downLike");
      if (scoreWin) {
        controlsTextOld.start("exit");
        controlsTextNew.start("enter");
      }
    }, 1500);

    setTimeout(() => {
      scoreWin && setConfetti(true);
    }, 2500);
  }, [controlsBar, scoreWin, rankedScores]);

  return (
    <>
      <Bar
        variants={barVariants}
        animate={controlsBar}
        initial="initial"
        exit="exit"
        custom={
          rankedScores &&
          rankedScores.find((item) => item.group === score.group)
        }
        transition={{ ...transitionSpring, delay: index * 0.1 }}
      >
        {!begin && <CustomConfetti top={true} isActive={confetti} />}
        {
          // the highest score gets a crown
          rankedScores &&
            Math.max(...rankedScores.map((item) => item.total)) ===
              score.total &&
            !begin && (
              <motion.img
                className="crown"
                src={crown}
                initial={{ opacity: 0, scale: 0.6, rotate: 0 }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  y: [20, -80, 0],
                  rotate: 30,
                }}
                transition={{
                  duration: 1.2,
                  delay: 0.5,
                  times: [0, 0.9, 1],
                  ease: [0.16, 1, 0.1, 1],
                }}
              />
            )
        }
        {score.last_score > 0 && (
          <>
            <AddScore
              initial="initial"
              variants={addVariants}
              animate={controlsAddScore}
            >
              {score.last_score > 0 && score.last_score}
            </AddScore>
            <motion.img
              className="like"
              initial="initialLike"
              variants={addVariants}
              animate={controlsLike}
              src={like}
            />
          </>
        )}
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={2}
          justify="center"
        >
          <Grid item>
            <AnswerPlace>
              <Score
                transition={{ duration: 0.2 }}
                initial="initialNew"
                variants={textVariants}
                animate={controlsTextNew}
              >
                {score.total}
              </Score>
              <Score
                transition={{ duration: 0.2 }}
                initial="initialOld"
                variants={textVariants}
                animate={controlsTextOld}
              >
                {begin
                  ? 0
                  : scoreWin
                  ? score.total - score.last_score
                  : score.total}
              </Score>
            </AnswerPlace>
          </Grid>
          <Grid item>
            <Text margin bold>
              Team {index + 1}
            </Text>
          </Grid>
          {!begin && (
            <Grid
              style={{
                marginTop:
                  rankedScores &&
                  -rankedScores.find((item) => item.group === score.group)
                    .yTranslation + 150,
              }}
              item
            >
              <Text margin bold>
                <span
                  style={{
                    fontSize: "1rem",
                    opacity: 0.6,
                  }}
                >
                  antwoord:
                </span>{" "}
                <br />
                {getText()}
              </Text>
            </Grid>
          )}
        </Grid>
      </Bar>
    </>
  );
};

const AddScore = styled(motion.div)`
  position: absolute;
  top: -0px;
  //center horizontally
  left: calc(50% - 25px);
  width: 50px;
  font-size: 40px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.purple};
  background: transparent;
  z-index: -8;
  isolation: isolate;
`;

const Score = styled(motion.p)`
  color: ${(props) => props.theme.colors.white};
  font-weight: 700;
  font-size: 40px;
  position: absolute;
`;

export const Bar = styled(motion.div)`
  z-index: 1;
  background-color: ${(props) => props.theme.colors.purple};
  border-radius: 8px;
  max-width: 120px;
  height: 600px;
  padding: 32px 16px;
  margin: 0 auto;
  position: relative;
  top: 150px;
  @media (max-height: 800px) {
    height: 500px;
  }

  .crown {
    position: absolute;
    width: 70px;
    right: -30px;
    top: -40px;
  }

  .like {
    width: 40px;
    position: absolute;
    top: -30px;
    right: 30px;
  }
`;

const AnswerPlace = styled(motion.span)`
  overflow: hidden;
  display: inline-block;
  background-color: ${(props) => props.theme.colors.purpleShadow};
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  font-weight: 700;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => `0 -4px 0 ${props.theme.colors.purpleShadow}`};
`;

const Text = styled(motion.div)`
  font-family: ${(props) => (props.bold ? "proxima-nova" : "proxima-nova")};
  font-size: 1.4em;
  margin-bottom: ${(props) => props.margin && "10px"};
  color: ${(props) => (props.black ? "#000" : "#fff")};
`;

export default ScoreBarSocket;
