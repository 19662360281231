import { motion } from "framer-motion";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { useMainStore } from "../../../../store";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";

const CloudBackgroundVideo = () => {
  const { width } = useWindowDimensions();
  const { theme } = useMainStore();

  return (
    <>
      <motion.div
        initial={{ width: "50vh", height: "50vh", right: -500 }}
        animate={{ width: "50vh", height: "50vh", right: -400 }}
        exit={{ right: width, transition: { duration: 1, delay: 0 } }}
        transition={{
          delay: 2,
          ...transitionSpring,
        }}
        style={{
          zIndex: 0,
          borderRadius: "100%",
          textAlign: "center",
          position: "absolute",
          top: "-8vw",
          background: theme.colors.background,
        }}
      ></motion.div>
      <motion.div
        initial={{ width: "70vh", height: "70vh", right: -700 }}
        animate={{ width: "70vh", height: "70vh", right: -560 }}
        exit={{
          right: width,
          transition: {
            delay: 0,
            duration: 1,
          },
        }}
        transition={{
          delay: 2.1,
          ...transitionSpring,
        }}
        style={{
          zIndex: 0,
          borderRadius: "100%",
          textAlign: "center",
          position: "absolute",
          top: "15vw",
          background: theme.colors.background,
        }}
      ></motion.div>
      <motion.div
        initial={{ width: "50vh", height: "50vh", right: -500 }}
        animate={{ width: "50vh", height: "50vh", right: -430 }}
        exit={{
          right: width,
          transition: { delay: 0, duration: 1 },
        }}
        transition={{
          delay: 2.2,
          ...transitionSpring,
        }}
        style={{
          zIndex: 0,
          borderRadius: "100%",
          textAlign: "center",
          position: "absolute",
          top: "65vh",
          background: theme.colors.background,
        }}
      ></motion.div>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: "90%" }}
        exit={{
          right: width,
          transition: {
            delay: 0,
            duration: 1,
          },
        }}
        transition={{
          delay: 2.2,
          ...transitionSpring,
        }}
        style={{
          zIndex: 0,
          width: "120vw",
          height: "100vh",
          position: "absolute",
          background: theme.colors.background,
        }}
      ></motion.div>
    </>
  );
};

export default CloudBackgroundVideo;
