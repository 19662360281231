import styled from "styled-components";
import { motion } from "framer-motion";

export const PanelIntro = styled(motion.div)`
  height: calc(100vh - 155px);
  top: 0;
  padding: 0 0 90px 0;
  display: flex;
  flex-align: center;
`;
